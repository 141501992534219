.md {
  .actions-button {
    transition-duration: 300ms;
  }
  .actions-button-media {
    min-width: 40px;
    + .actions-button-text {
      margin-left: 16px;
    }
  }
}
