/* === Icon === */
.f7-icons,
.material-icons {
  width: 1em;
  height: 1em;
}
i.icon {
  display: inline-block;
  vertical-align: middle;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  font-style: normal;
  position: relative;
}
.icon-back,
.icon-prev,
.icon-forward,
.icon-next {
  &:after {
    .core-icons-font();
  }
}
.icon[class*='color-'] {
  color: var(--f7-theme-color);
}

.if-ios-theme({
  @import './icon-ios.less';
});
.if-md-theme({
  @import './icon-md.less';
});
.if-aurora-theme({
  @import './icon-aurora.less';
});
