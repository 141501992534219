/* === Badge === */
@import './badge-vars.less';

.badge {
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: var(--f7-badge-text-color);
  background: var(--f7-badge-bg-color);
  position: relative;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  font-weight: var(--f7-badge-font-weight);
  font-size: var(--f7-badge-font-size);
  border-radius: var(--f7-badge-size);
  padding: var(--f7-badge-padding);
  height: var(--f7-badge-size);
  min-width: var(--f7-badge-size);

  .icon &,
  .f7-icons &,
  .framework7-icons &,
  .material-icons & {
    position: absolute;
    left: 100%;
    margin-left: -10px;
    top: -2px;
    font-family: var(--f7-font-family);
    --f7-badge-font-size: var(--f7-badge-in-icon-font-size);
    --f7-badge-size: var(--f7-badge-in-icon-size);
  }
}
.badge[class*='color-'] {
  --f7-badge-bg-color: var(--f7-theme-color);
}

.if-ios-theme({
  @import './badge-ios.less';
});
.if-md-theme({
  @import './badge-md.less';
});
.if-aurora-theme({
  @import './badge-aurora.less';
});
