/* === Smart Select === */
.smart-select {
  @import './smart-select-vars.less';
  select {
    display: none;
  }
  .item-after {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: block;
  }
}
.smart-select-sheet .page,
.smart-select-sheet .sheet-modal-inner,
.smart-select-sheet .list ul {
  background: var(--f7-smart-select-sheet-bg, var(--f7-list-bg-color));
}
.smart-select-sheet {
  .toolbar {
    .hairline(bottom, var(--f7-smart-select-sheet-toolbar-border-color, var(--f7-bars-border-color)));
    &:after {
      display: block;
    }
  }
  .list {
    margin: 0;
    ul {
      .hairline-remove-top-bottom();
    }
  }
}
.smart-select-popover .popover-inner {
  max-height: 40vh;
}
.if-ios-theme({
  @import './smart-select-ios.less';
});
.if-md-theme({
  @import './smart-select-md.less';
});
.if-aurora-theme({
  @import './smart-select-aurora.less';
});
