:root {
  --f7-messagebar-shadow-image: none;
  --f7-messagebar-textarea-bg-color: transparent;
  --f7-messagebar-attachments-height: 155px;
  --f7-messagebar-attachment-height: 155px;
  --f7-messagebar-attachment-landscape-height: 120px;
  --f7-messagebar-sheet-height: 252px;
  --f7-messagebar-sheet-landscape-height: 192px;
  /*
  --f7-messagebar-inner-padding-left: var(--f7-toolbar-inner-padding-left);
  --f7-messagebar-inner-padding-right: var(--f7-toolbar-inner-padding-right);
  */
  .light-vars({
    --f7-messagebar-bg-color: #fff;
    --f7-messagebar-bg-color-rgb: 255, 255, 255;
  });
  .dark-vars({
    --f7-messagebar-bg-color: var(--f7-bars-bg-color);
    --f7-messagebar-bg-color-rgb: var(--f7-bars-bg-color-rgb);
  });
}
.ios-vars({
  --f7-messagebar-height: 44px;
  --f7-messagebar-font-size: 17px;
  /*
  --f7-messagebar-link-color: var(--f7-theme-color);
  */
  --f7-messagebar-border-color: transparent;
  --f7-messagebar-textarea-border-radius: 17px;
  --f7-messagebar-textarea-padding: 6px 16px;
  --f7-messagebar-textarea-height: 34px;
  --f7-messagebar-textarea-font-size: 17px;
  --f7-messagebar-textarea-line-height: 20px;
  --f7-messagebar-sheet-bg-color: #d1d5da;
  --f7-messagebar-attachment-border-radius: 12px;
  .light-vars({
    --f7-messagebar-textarea-text-color: #000;
    --f7-messagebar-textarea-border: 1px solid #c8c8cd;
    --f7-messagebar-attachments-border-color: #c8c8cd;
  });
  .dark-vars({
    --f7-messagebar-textarea-text-color: #fff;
    --f7-messagebar-textarea-border: 1px solid var(--f7-bars-border-color);
    --f7-messagebar-attachments-border-color: var(--f7-bars-border-color);
  });
});
.md-vars({
  --f7-messagebar-height: 48px;
  --f7-messagebar-font-size: 16px;
  --f7-messagebar-textarea-border-radius: 0px;
  --f7-messagebar-textarea-padding: 5px 8px;
  --f7-messagebar-textarea-height: 32px;
  --f7-messagebar-textarea-font-size: 16px;
  --f7-messagebar-textarea-line-height: 22px;
  --f7-messagebar-textarea-border: 1px solid transparent;
  --f7-messagebar-sheet-bg-color: #fff;
  --f7-messagebar-attachment-border-radius: 4px;
  .light-vars({
    --f7-messagebar-link-color: #333;
    --f7-messagebar-border-color: #d1d1d1;
    --f7-messagebar-textarea-text-color: #333;
    --f7-messagebar-attachments-border-color: #ddd;
  });
  .dark-vars({
    --f7-messagebar-border-color: transparent;
    --f7-messagebar-link-color: rgba(255,255,255,0.87);
    --f7-messagebar-textarea-text-color: rgba(255,255,255,0.87);
    --f7-messagebar-attachments-border-color: rgba(255,255,255,0.2);
  });
});
.aurora-vars({
  --f7-messagebar-height: 48px;
  --f7-messagebar-font-size: 16px;
  /*
  --f7-messagebar-link-color: var(--f7-theme-color);
  */
  --f7-messagebar-border-color: transparent;
  --f7-messagebar-textarea-border-radius: 8px;
  --f7-messagebar-textarea-padding: 4px 8px;
  --f7-messagebar-textarea-height: 32px;
  --f7-messagebar-textarea-font-size: 16px;
  --f7-messagebar-textarea-line-height: 22px;
  --f7-messagebar-sheet-bg-color: #fff;
  --f7-messagebar-attachment-border-radius: 8px;
  .light-vars({
    --f7-messagebar-textarea-text-color: #000;
    --f7-messagebar-textarea-border: 1px solid #c8c8cd;
    --f7-messagebar-attachments-border-color: #c8c8cd;
  });
  .dark-vars({
    --f7-messagebar-textarea-text-color: #fff;
    --f7-messagebar-textarea-border: 1px solid var(--f7-bars-border-color);
    --f7-messagebar-attachments-border-color: var(--f7-bars-border-color);
  });
});
