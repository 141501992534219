:root {
  --f7-block-padding-horizontal: 16px;
  --f7-block-padding-vertical: 16px;
  --f7-block-font-size: inherit;
  --f7-block-text-color: inherit;
  --f7-block-header-margin: 10px;
  --f7-block-footer-margin: 10px;
  --f7-block-header-font-size: 14px;
  --f7-block-footer-font-size: 14px;
  --f7-block-title-text-transform: none;
  --f7-block-title-white-space: nowrap;
  --f7-block-title-medium-text-transform: none;
  --f7-block-title-large-text-transform: none;
  --f7-block-inset-side-margin: 16px;
  .light-vars({
    --f7-block-title-medium-text-color: #000;
    --f7-block-title-large-text-color: #000;
    --f7-block-strong-bg-color: #fff;
  });
  .dark-vars({
    --f7-block-title-text-color: #fff;
    --f7-block-strong-border-color: rgba(255, 255, 255, 0.15);
    --f7-block-title-medium-text-color: #fff;
    --f7-block-title-large-text-color: #fff;
    --f7-block-strong-bg-color: #1c1c1d;
  });
}
.ios-vars({
  --f7-block-margin-vertical: 35px;
  --f7-block-strong-border-color: rgba(0,0,0,0.22);
  --f7-block-title-text-color: #000;
  --f7-block-title-font-size: 16px;
  --f7-block-title-font-weight: 600;
  --f7-block-title-line-height: 20px;
  --f7-block-title-margin-bottom: 10px;
  --f7-block-title-medium-font-size: 22px;
  --f7-block-title-medium-font-weight: bold;
  --f7-block-title-medium-line-height: 1.4;
  --f7-block-title-large-font-size: 30px;
  --f7-block-title-large-font-weight: bold;
  --f7-block-title-large-line-height: 1.3;
  --f7-block-inset-border-radius: 8px;
  .light-vars({
    --f7-block-strong-text-color: #000;
    --f7-block-header-text-color: rgba(0, 0, 0, 0.45);
    --f7-block-footer-text-color: rgba(0, 0, 0, 0.45);
  });
  .dark-vars({
    --f7-block-header-text-color: rgba(255,255,255,0.55);
    --f7-block-footer-text-color: rgba(255,255,255,0.55);
    --f7-block-strong-text-color: #fff;
  });
});
.md-vars({
  --f7-block-margin-vertical: 32px;
  --f7-block-strong-text-color: inherit;
  --f7-block-strong-border-color: rgba(0,0,0,0.12);
  --f7-block-title-font-size: inherit;
  --f7-block-title-text-color: rgba(0,0,0,0.54);
  --f7-block-title-font-weight: 500;
  --f7-block-title-line-height: 16px;
  --f7-block-title-margin-bottom: 16px;
  --f7-block-title-medium-font-size: 24px;
  --f7-block-title-medium-font-weight: 500;
  --f7-block-title-medium-line-height: 1.3;
  --f7-block-title-large-font-size: 34px;
  --f7-block-title-large-font-weight: 500;
  --f7-block-title-large-line-height: 1.2;
  --f7-block-inset-border-radius: 4px;
  .light-vars({
    --f7-block-header-text-color: rgba(0,0,0,0.54);
    --f7-block-footer-text-color: rgba(0,0,0,0.54);
  });
  .dark-vars({
    --f7-block-header-text-color: rgba(255,255,255,0.54);
    --f7-block-footer-text-color: rgba(255,255,255,0.54);
  });
});
.aurora-vars({
  --f7-block-margin-vertical: 32px;
  --f7-block-strong-border-color: rgba(0,0,0,0.12);
  --f7-block-title-font-size: 16px;
  --f7-block-title-text-color: #000;
  --f7-block-title-font-weight: 600;
  --f7-block-title-line-height: 1.5;
  --f7-block-title-margin-bottom: 10px;
  --f7-block-title-medium-font-size: 22px;
  --f7-block-title-medium-font-weight: bold;
  --f7-block-title-medium-line-height: 1.4;
  --f7-block-title-large-font-size: 28px;
  --f7-block-title-large-font-weight: bold;
  --f7-block-title-large-line-height: 1.3;
  --f7-block-inset-border-radius: 8px;
  .light-vars({
    --f7-block-strong-text-color: inherit;
    --f7-block-header-text-color: rgba(0,0,0,0.6);
    --f7-block-footer-text-color: rgba(0,0,0,0.6);
  });
  .dark-vars({
    --f7-block-header-text-color: rgba(255,255,255,0.54);
    --f7-block-footer-text-color: rgba(255,255,255,0.54);
    --f7-block-strong-text-color: #fff;
  });
});
