:root {
  --f7-message-text-header-text-color: inherit;
  --f7-message-text-header-opacity: 0.65;
  --f7-message-text-header-font-size: 12px;
  --f7-message-text-footer-text-color: inherit;
  --f7-message-text-footer-opacity: 0.65;
  --f7-message-text-footer-font-size: 12px;
  --f7-message-bubble-line-height: 1.2;
  --f7-message-header-font-size: 12px;
  --f7-message-footer-font-size: 11px;
  --f7-message-name-font-size: 12px;
  --f7-message-name-font-weight: inherit;
  --f7-message-avatar-border-radius: 50%;
  --f7-messages-title-font-weight: inherit;
  /*
  --f7-message-sent-bg-color: var(--f7-theme-color);
  */
  --f7-message-sent-text-color: #fff;
  .light-vars({
    --f7-messages-content-bg-color: #fff;
    --f7-message-typing-indicator-bg-color: #000;
    --f7-message-received-bg-color: #e5e5ea;
    --f7-message-received-text-color: #000;
  });
  .dark-vars({
    --f7-messages-title-text-color: rgba(255,255,255,0.54);
    --f7-message-header-text-color: rgba(255,255,255,0.54);
    --f7-message-name-text-color: rgba(255,255,255,0.54);
    --f7-message-footer-text-color: rgba(255,255,255,0.54);
    --f7-messages-content-bg-color: transparent;
    --f7-message-received-bg-color: #252525;
    --f7-message-received-text-color: #fff;
    --f7-message-typing-indicator-bg-color: #fff;
  });
}
.ios-vars({
  --f7-messages-title-text-color: rgba(0,0,0,0.45);
  --f7-messages-title-font-size: 11px;
  --f7-message-header-text-color: rgba(0,0,0,0.45);
  --f7-message-footer-text-color: rgba(0,0,0,0.45);
  --f7-message-name-text-color: rgba(0,0,0,0.45);
  --f7-message-avatar-size: 29px;
  --f7-message-margin: 10px;
  --f7-message-bubble-min-height: 32px;
  --f7-message-bubble-font-size: 17px;
  --f7-message-bubble-border-radius: 16px;
  --f7-message-bubble-padding-vertical: 6px;
  --f7-message-bubble-padding-horizontal: 16px;
  --f7-message-typing-indicator-opacity: 0.35;
});
.md-vars({
  --f7-messages-title-font-size: 12px;
  --f7-message-avatar-size: 32px;
  --f7-message-margin: 16px;
  --f7-message-bubble-min-height: 32px;
  --f7-message-bubble-font-size: 16px;
  --f7-message-bubble-border-radius: 4px;
  --f7-message-bubble-padding-vertical: 6px;
  --f7-message-bubble-padding-horizontal: 8px;
  --f7-message-typing-indicator-opacity: 0.6;
  .light-vars({
    --f7-messages-title-text-color: rgba(0,0,0,0.51);
    --f7-message-header-text-color: rgba(0,0,0,0.51);
    --f7-message-footer-text-color: rgba(0,0,0,0.51);
    --f7-message-name-text-color: rgba(0,0,0,0.51);
  });
});
.aurora-vars({
  --f7-messages-title-font-size: 14px;
  --f7-message-avatar-size: 32px;
  --f7-message-margin: 16px;
  --f7-message-bubble-min-height: 34px;
  --f7-message-bubble-font-size: 16px;
  --f7-message-bubble-line-height: 1.4;
  --f7-message-bubble-border-radius: 16px;
  --f7-message-bubble-padding-vertical: 6px;
  --f7-message-bubble-padding-horizontal: 10px;
  --f7-message-typing-indicator-opacity: 0.5;

  --f7-message-header-font-size: 14px;
  --f7-message-footer-font-size: 12px;
  --f7-message-name-font-size: 14px;
  .light-vars({
    --f7-messages-title-text-color: rgba(0,0,0,0.51);
    --f7-message-header-text-color: rgba(0,0,0,0.51);
    --f7-message-footer-text-color: rgba(0,0,0,0.51);
    --f7-message-name-text-color: rgba(0,0,0,0.51);
  });
});
