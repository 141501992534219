// f7-cover
.router-transition-f7-cover-forward,
.router-transition-f7-cover-backward {
  background: #000;
  perspective: 1200px;
}
.router-transition-f7-cover-forward {
  .page-next {
    animation: f7-cover-next-to-current var(--f7-page-cover-transition-duration) forwards;
  }
  .page-current {
    animation: f7-cover-current-to-prev var(--f7-page-cover-transition-duration) forwards;
  }
}
.router-transition-f7-cover-backward {
  .page-current {
    animation: f7-cover-current-to-next var(--f7-page-cover-transition-duration) forwards;
  }
  .page-previous {
    animation: f7-cover-prev-to-current var(--f7-page-cover-transition-duration) forwards;
  }
}
@keyframes f7-cover-next-to-current {
  from {
    .ltr({
      transform: translateX(100%);
    });
    .rtl({
      transform: translateX(-100%);
    });
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes f7-cover-current-to-next {
  from {
    transform: translateX(0%);
  }
  to {
    .ltr({
      transform: translateX(100%);
    });
    .rtl({
      transform: translateX(-100%);
    });
  }
}
@keyframes f7-cover-current-to-prev {
  from {
    transform: translateZ(0);
    opacity: 1;
  }
  to {
    transform: translateZ(-300px);
    opacity: 0.5;
  }
}
@keyframes f7-cover-prev-to-current {
  from {
    transform: translateZ(-300px);
    opacity: 0.5;
  }
  to {
    transform: translateZ(0);
    opacity: 1;
  }
}
