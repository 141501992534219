/* === Gauge === */
.gauge {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}
.gauge-svg,
.gauge svg {
  max-width: 100%;
  height: auto;
  circle,
  path {
    transition-duration: 400ms;
  }
}

.if-ios-theme({
  @import './gauge-ios.less';
});
.if-md-theme({
  @import './gauge-md.less';
});
.if-aurora-theme({
  @import './gauge-aurora.less';
});
