:root {
  --f7-chip-font-size: 14px;
  --f7-chip-font-weight: normal;
  --f7-chip-media-font-size: 16px;
  .light-vars({
    --f7-chip-bg-color: rgba(0, 0, 0, 0.12);
    --f7-chip-outline-border-color: rgba(0, 0, 0, 0.12);
    --f7-chip-delete-button-color: #000;
  });
  .dark-vars({
    --f7-chip-delete-button-color: #fff;
    --f7-chip-bg-color: #333;
    --f7-chip-outline-border-color: rgba(255, 255, 255, 0.12);
  });
}
.ios-vars({
  --f7-chip-height: 28px;
  --f7-chip-padding-horizontal: 10px;
  .light-vars({
    --f7-chip-text-color: #000;
  });
  .dark-vars({
    --f7-chip-text-color: #fff;
  });
});
.md-vars({
  --f7-chip-height: 32px;
  --f7-chip-padding-horizontal: 12px;
  .light-vars({
    --f7-chip-text-color: rgba(0, 0, 0, 0.87);
  });
  .dark-vars({
    --f7-chip-text-color: rgba(255,255,255,0.87);
  });
});
.aurora-vars({
  --f7-chip-height: 32px;
  --f7-chip-padding-horizontal: 12px;
  .light-vars({
    --f7-chip-text-color: #000;
  });
  .dark-vars({
    --f7-chip-text-color: #fff;
  });
});
