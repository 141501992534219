.md {
  @red: #f44336;
  @blue: #2196f3;
  @green: #4caf50;
  @yellow: #ffeb3b;
  .progressbar-infinite {
    &:before {
      animation: md-progressbar-infinite-1 2s linear infinite;
    }
    &:after {
      animation: md-progressbar-infinite-2 2s linear infinite;
    }
    &.color-multi {
      &:before {
        background: none;
        animation: md-progressbar-infinite-multicolor-bg 3s step-end infinite;
      }
      &:after {
        background: none;
        animation: md-progressbar-infinite-multicolor-fill 3s linear infinite;
        transform-origin: center center;
      }
    }
  }

  @keyframes md-progressbar-infinite-1 {
    0% {
      transform: translateX(-10%) scaleX(0.1);
    }
    25% {
      transform: translateX(30%) scaleX(0.6);
    }
    50% {
      transform: translateX(100%) scaleX(1);
    }
    100% {
      transform: translateX(100%) scaleX(1);
    }
  }
  @keyframes md-progressbar-infinite-2 {
    0% {
      transform: translateX(-100%) scaleX(1);
    }
    40% {
      transform: translateX(-100%) scaleX(1);
    }
    75% {
      transform: translateX(60%) scaleX(0.35);
    }
    90% {
      transform: translateX(100%) scaleX(0.1);
    }
    100% {
      transform: translateX(100%) scaleX(0.1);
    }
  }
  @keyframes md-progressbar-infinite-multicolor-bg {
    0% {
      background-color: @green;
    }
    25% {
      background-color: @red;
    }
    50% {
      background-color: @blue;
    }
    75% {
      background-color: @yellow;
    }
  }
  @keyframes md-progressbar-infinite-multicolor-fill {
    0% {
      transform: scaleX(0);
      background-color: @red;
    }
    24.9% {
      transform: scaleX(1);
      background-color: @red;
    }
    25% {
      transform: scaleX(0);
      background-color: @blue;
    }
    49.9% {
      transform: scaleX(1);
      background-color: @blue;
    }
    50% {
      transform: scaleX(0);
      background-color: @yellow;
    }
    74.9% {
      transform: scaleX(1);
      background-color: @yellow;
    }
    75% {
      transform: scaleX(0);
      background-color: @green;
    }
    100% {
      transform: scaleX(1);
      background-color: @green;
    }
  }
}
