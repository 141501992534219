:root {
  --f7-list-index-width: 16px;
  --f7-list-index-font-size: 11px;
  --f7-list-index-font-weight: 600;
  /* --f7-list-index-text-color: var(--f7-theme-color); */
  --f7-list-index-item-height: 14px;
  --f7-list-index-label-text-color: #fff;
  /* --f7-list-index-label-bg-color: var(--f7-theme-color); */
  --f7-list-index-label-font-weight: 500;
}
.ios-vars({
  --f7-list-index-label-size: 44px;
  --f7-list-index-label-font-size: 17px;
  --f7-list-index-skip-dot-size: 6px;
});
.md-vars({
  --f7-list-index-label-size: 56px;
  --f7-list-index-label-font-size: 20px;
  --f7-list-index-skip-dot-size: 4px;
});
.aurora-vars({
  --f7-list-index-font-size: 12px;
  --f7-list-index-label-size: 32px;
  --f7-list-index-label-font-size: 12px;
  --f7-list-index-label-font-weight: 600;
  --f7-list-index-skip-dot-size: 4px;
});
