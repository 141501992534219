.md {
  .icon-back,
  .icon-forward,
  .icon-next,
  .icon-prev {
    width: 24px;
    height: 24px;
    &:after {
      line-height: 1.2;
    }
  }
  .ltr({
    .icon-back {
      &:after {
        content: 'arrow_left_md';
      }
    }
    .icon-forward {
      &:after {
        content: 'arrow_right_md';
      }
    }
    .icon-next {
      &:after {
        content: 'chevron_right_md';
      }
    }
    .icon-prev {
      &:after {
        content: 'chevron_left_md';
      }
    }
  });
  .rtl({
    .icon-back {
      &:after {
        content: 'arrow_right_md';
      }
    }
    .icon-forward {
      &:after {
        content: 'arrow_left_md';
      }
    }
    .icon-next {
      &:after {
        content: 'chevron_left_md';
      }
    }
    .icon-prev {
      &:after {
        content: 'chevron_right_md';
      }
    }
  });
}
