:root {
}
.ios-vars({
  --f7-ptr-preloader-size: 28px;
  --f7-ptr-size: 44px;
});
.md-vars({
  --f7-ptr-preloader-size: 22px;
  --f7-ptr-size: 40px;
});
.aurora-vars({
  --f7-ptr-preloader-size: 20px;
  --f7-ptr-size: 38px;
});
