:root {
  --f7-dialog-button-text-color: var(--f7-theme-color);
  --f7-dialog-button-text-align: center;
  .light-vars({
    --f7-dialog-input-bg-color:  #fff;
  });
  .dark-vars({
    --f7-dialog-text-color: #fff;
  });
}
.ios-vars({
  --f7-dialog-box-shadow: none;
  --f7-dialog-width: 270px;
  --f7-dialog-inner-padding: 16px;
  --f7-dialog-border-radius: 13px;
  --f7-dialog-text-align: center;
  --f7-dialog-font-size: 14px;
  --f7-dialog-title-text-color: inherit;
  --f7-dialog-title-font-size: 18px;
  --f7-dialog-title-font-weight: 600;
  --f7-dialog-title-line-height: inherit;

  --f7-dialog-button-font-size: 17px;
  --f7-dialog-button-height: 44px;
  --f7-dialog-button-letter-spacing: 0;
  --f7-dialog-button-font-weight: 400;
  --f7-dialog-button-text-transform: none;

  --f7-dialog-input-border-radius: 4px;
  --f7-dialog-input-font-size: 14px;
  --f7-dialog-input-height: 32px;
  --f7-dialog-input-border-width: 1px;
  --f7-dialog-input-placeholder-color: #a9a9a9;

  --f7-dialog-preloader-size: 34px;

  .light-vars({
    --f7-dialog-bg-color: rgba(255,255,255,0.95);
    --f7-dialog-bg-color-rgb: 255, 255, 255;
    --f7-dialog-text-color: #000;
    --f7-dialog-button-pressed-bg-color: rgba(0,0,0,0.1);
    --f7-dialog-input-border-color: rgba(0,0,0,0.3);
    --f7-dialog-border-divider-color: rgba(0,0,0,0.2);
  });
  .dark-vars({
    --f7-dialog-bg-color: rgba(45,45,45,0.95);
    --f7-dialog-bg-color-rgb: 45, 45, 45;
    --f7-dialog-button-pressed-bg-color: rgba(0,0,0,0.2);
    --f7-dialog-border-divider-color: rgba(255,255,255,0.15);
    --f7-dialog-input-border-color: rgba(255,255,255,0.15);
    --f7-dialog-input-bg-color: rgba(0,0,0,0.5);
  });
});
.md-vars({
  --f7-dialog-box-shadow: var(--f7-elevation-24);
  --f7-dialog-width: 280px;
  --f7-dialog-inner-padding: 24px;
  --f7-dialog-border-radius: 4px;
  .ltr({
    --f7-dialog-text-align: left;
  });
  .rtl({
    --f7-dialog-text-align: right;
  });
  --f7-dialog-font-size: 16px;
  --f7-dialog-title-font-size: 20px;
  --f7-dialog-title-font-weight: 500;
  --f7-dialog-title-line-height: 1.3;

  --f7-dialog-button-font-size: 14px;
  --f7-dialog-button-height: 36px;
  --f7-dialog-button-letter-spacing: 0.05em;
  --f7-dialog-button-font-weight: 500;
  --f7-dialog-button-text-transform: uppercase;
  --f7-dialog-button-pressed-bg-color: rgba(var(--f7-theme-color-rgb), 0.15);

  --f7-dialog-input-border-radius: 0px;
  --f7-dialog-input-font-size: 16px;
  --f7-dialog-input-height: 36px;
  --f7-dialog-input-border-color: transparent;
  --f7-dialog-input-border-width: 0px;

  --f7-dialog-preloader-size: 32px;
  .light-vars({
    --f7-dialog-bg-color: #fff;
    --f7-dialog-text-color: rgba(0,0,0,0.54);
    --f7-dialog-title-text-color: #212121;
    --f7-dialog-input-placeholder-color: rgba(0,0,0,0.35);
  });
  .dark-vars({
    --f7-dialog-bg-color: #1c1c1d;
    --f7-dialog-title-text-color: #fff;
    --f7-dialog-input-bg-color: transparent;
    --f7-dialog-input-placeholder-color: rgba(255,255,255,0.54);
  });

});
.aurora-vars({
  --f7-dialog-box-shadow: 0 0 20px 0 rgba(0,0,0,0.15), 0 25px 30px 0 rgba(0,0,0,0.35);
  --f7-dialog-width: 300px;
  --f7-dialog-inner-padding: 20px;
  --f7-dialog-border-radius: 8px;
  .ltr({
    --f7-dialog-text-align: left;
  });
  .rtl({
    --f7-dialog-text-align: right;
  });
  --f7-dialog-font-size: 14px;
  --f7-dialog-title-font-size: 18px;
  --f7-dialog-title-font-weight: 700;
  --f7-dialog-title-line-height: inherit;

  --f7-dialog-button-font-size: 14px;
  --f7-dialog-button-height: 36px;
  --f7-dialog-button-letter-spacing: 0;
  --f7-dialog-button-font-weight: 600;
  --f7-dialog-button-text-transform: uppercase;
  /*
  --f7-dialog-button-pressed-bg-color: var(--f7-theme-color-shade);
  --f7-dialog-button-hover-bg-color: var(--f7-theme-color-tint);
  */
  --f7-dialog-input-border-radius: 8px;
  --f7-dialog-input-font-size: 16px;
  --f7-dialog-input-height: 32px;
  --f7-dialog-input-border-width: 1px;
  --f7-dialog-preloader-size: 24px;
  .light-vars({
    --f7-dialog-bg-color: #fff;
    --f7-dialog-title-text-color: inherit;
    --f7-dialog-text-color: #000;
    --f7-dialog-button-text-color: #fff;
    --f7-dialog-input-border-color: rgba(0,0,0,0.2);
    --f7-dialog-input-placeholder-color: rgba(0,0,0,0.32);
  });
  .dark-vars({
    --f7-dialog-bg-color: #1c1c1d;
    --f7-dialog-title-text-color: #fff;
    --f7-dialog-input-bg-color: rgba(0,0,0,0.25);
    --f7-dialog-input-placeholder-color: rgba(255,255,255,0.54);
    --f7-dialog-input-border-color: rgba(255,255,255,0.15);
  });
});
