.md {
  .range-knob {
    transition-duration: 200ms;
    transition-property: transform, background-color;
  }
  .range-knob-active-state .range-knob {
    transform: scale(1.5);
  }
  .range-slider-min:not(.range-slider-dual) {
    .range-knob {
      background: #fff !important;
      border: 2px solid var(--f7-range-bar-bg-color);
    }
  }
  .range-knob-label {
    width: var(--f7-range-label-size);
    margin-left: calc(-1 * var(--f7-range-label-size) / 2);
    margin-bottom: 8px;
    &:before {
      content: '';
      left: 50%;
      top: 0px;
      margin-left: calc(-1 * var(--f7-range-label-size) / 2);
      position: absolute;
      z-index: -1;
      width: var(--f7-range-label-size);
      height: var(--f7-range-label-size);
      background: var(--f7-range-label-bg-color, var(--f7-theme-color));
      transform: rotate(-45deg);
      border-radius: 50% 50% 50% 0;
    }
  }
  .range-knob-active-state .range-knob-label {
    transform: translateY(0%) scale(1);
  }
  .range-slider-label {
    .range-knob-active-state .range-knob {
      transform: scale(0);
    }
  }
}
