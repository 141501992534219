/*====================
  Core
  ==================== */
:root {
  @themeColorRed: red(@themeColor);
  @themeColorGreen: green(@themeColor);
  @themeColorBlue: blue(@themeColor);
  --f7-theme-color: @themeColor;
  --f7-theme-color-rgb: @themeColorRed, @themeColorGreen, @themeColorBlue;
  --f7-theme-color-shade: darken(@themeColor, 8%);
  --f7-theme-color-tint: lighten(@themeColor, 8%);

  --f7-safe-area-left: 0px;
  --f7-safe-area-right: 0px;
  --f7-safe-area-top: 0px;
  --f7-safe-area-bottom: 0px;

  --f7-safe-area-outer-left: 0px;
  --f7-safe-area-outer-right: 0px;

  @supports (left: env(safe-area-inset-left)) {
    --f7-safe-area-top: env(safe-area-inset-top);
    --f7-safe-area-bottom: env(safe-area-inset-bottom);
    .ios-left-edge,
    .ios-edges,
    .safe-area-left,
    .safe-areas,
    .popup,
    .sheet-modal,
    .panel-left {
      --f7-safe-area-left: env(safe-area-inset-left);
      --f7-safe-area-outer-left: env(safe-area-inset-left);
    }
    .ios-right-edge,
    .ios-edges,
    .safe-area-right,
    .safe-areas,
    .popup,
    .sheet-modal,
    .panel-right {
      --f7-safe-area-right: env(safe-area-inset-right);
      --f7-safe-area-outer-right: env(safe-area-inset-right);
    }
    .no-safe-areas,
    .no-safe-area-left,
    .no-ios-edges,
    .no-ios-left-edge {
      --f7-safe-area-left: 0px;
      --f7-safe-area-outer-left: 0px;
    }
    .no-safe-areas,
    .no-safe-area-right,
    .no-ios-edges,
    .no-ios-right-edge {
      --f7-safe-area-right: 0px;
      --f7-safe-area-outer-right: 0px;
    }
  }

  --f7-device-pixel-ratio: 1;
  @media (min-resolution: 2dppx) {
    --f7-device-pixel-ratio: 2;
  }
  @media (min-resolution: 3dppx) {
    --f7-device-pixel-ratio: 3;
  }
}
/*====================
  Fonts & Bars
  ==================== */
:root {
  --f7-font-size: 14px;
  /*
  --f7-bars-link-color: var(--f7-theme-color);
  */
  --f7-bars-bg-image: none;
  --f7-bars-translucent-opacity: 0.8;
  --f7-bars-translucent-blur: 20px;
  --f7-bars-shadow-bottom-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.08) 40%,
    rgba(0, 0, 0, 0.04) 50%,
    rgba(0, 0, 0, 0) 90%,
    rgba(0, 0, 0, 0) 100%
  );
  --f7-bars-shadow-top-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.08) 40%,
    rgba(0, 0, 0, 0.04) 50%,
    rgba(0, 0, 0, 0) 90%,
    rgba(0, 0, 0, 0) 100%
  );
  .light-vars({
    --f7-bars-text-color: #000;
    --f7-bars-bg-color: #f7f7f8;
    --f7-bars-bg-color-rgb: 247, 247, 248;
  });
  .dark-vars({
    --f7-bars-text-color: #fff;
  });
}
.ios-vars({
  --f7-font-family: -apple-system, SF Pro Text, SF UI Text, system-ui, Helvetica Neue, Helvetica, Arial, sans-serif;
  --f7-line-height: 1.4;
  .light-vars({
    --f7-text-color: #000;
    --f7-bars-border-color: rgba(0,0,0,0.25);
  });
  .dark-vars({
    --f7-text-color: #fff;
    --f7-bars-bg-color: #121212;
    --f7-bars-bg-color-rgb: 22, 22, 22;
    --f7-bars-border-color: rgba(255,255,255,0.16);
  });
});
.md-vars({
  --f7-font-family: Roboto, system-ui, Noto, Helvetica, Arial, sans-serif;
  --f7-line-height: 1.5;
  --f7-bars-border-color: transparent;
  .light-vars({
    --f7-text-color: #212121;
  });
  .dark-vars({
    --f7-text-color: rgba(255,255,255,0.87);
    --f7-bars-bg-color: #202020;
  });
});
.aurora-vars({
  --f7-font-family: -apple-system, system-ui, Helvetica, Arial, sans-serif;
  --f7-line-height: 1.5;
  .light-vars({
    --f7-text-color: #000;
    --f7-bars-border-color: rgba(0,0,0,0.2);
  });
  .dark-vars({
    --f7-text-color: #fff;
    --f7-bars-bg-color: #202020;
    --f7-bars-border-color: rgba(255, 255, 255, 0.1);
  });
});

/*====================
  Color Themes
  ==================== */
.color-primary {
}
.text-color-primary {
  --f7-theme-color-text-color: var(--f7-theme-color);
}
.bg-color-primary {
  --f7-theme-color-bg-color: var(--f7-theme-color);
}
.border-color-primary {
  --f7-theme-color-border-color: var(--f7-theme-color);
}
.ripple-color-primary {
  --f7-theme-color-ripple-color: rgba(var(--f7-theme-color-rgb), 0.3);
}
:root {
  each(@colors, {
    @themeColorRed: red(@value);
    @themeColorGreen: green(@value);
    @themeColorBlue: blue(@value);
    --f7-color-@{key}: @value;
    --f7-color-@{key}-rgb: @themeColorRed, @themeColorGreen, @themeColorBlue;
    --f7-color-@{key}-shade: darken(@value, 8%);
    --f7-color-@{key}-tint: lighten(@value, 8%);
  });
}
each(@colors, {
  .color-theme-@{key} {
    @themeColorRed: red(@value);
    @themeColorGreen: green(@value);
    @themeColorBlue: blue(@value);
    --f7-theme-color: @value;
    --f7-theme-color-rgb: @themeColorRed, @themeColorGreen, @themeColorBlue;
    --f7-theme-color-shade: darken(@value, 8%);
    --f7-theme-color-tint: lighten(@value, 8%);
  }
});
each(@colors, {
  @themeColorRed: red(@value);
  @themeColorGreen: green(@value);
  @themeColorBlue: blue(@value);
  .color-@{key} {
    --f7-theme-color: @value;
    --f7-theme-color-rgb: @themeColorRed, @themeColorGreen, @themeColorBlue;
    --f7-theme-color-shade: darken(@value, 8%);
    --f7-theme-color-tint: lighten(@value, 8%);
  }
  .text-color-@{key} {
    --f7-theme-color-text-color: @value;
  }
  .bg-color-@{key} {
    --f7-theme-color-bg-color: @value;
  }
  .border-color-@{key} {
    --f7-theme-color-border-color: @value;
  }
  .ripple-color-@{key}, .ripple-@{key} {
    --f7-theme-color-ripple-color: rgba(@themeColorRed, @themeColorGreen, @themeColorBlue, 0.3);
  }
});
