/* === Link === */
@import './link-vars.less';

.link,
.tab-link {
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  --f7-touch-ripple-color: var(--f7-link-touch-ripple-color, rgba(var(--f7-theme-color-rgb), 0.25));
}
.link {
  i + span,
  i + i,
  span + i,
  span + span {
    .ltr({
      margin-left: 4px;
    });
    .rtl({
      margin-right: 4px;
    });
  }
}

.if-ios-theme({
  @import './link-ios.less';
});
.if-md-theme({
  @import './link-md.less';
});
.if-aurora-theme({
  @import './link-aurora.less';
});
