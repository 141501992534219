:root {
  --f7-page-master-width: 320px;
  --f7-page-master-border-color: rgba(0, 0, 0, 0.1);
  --f7-page-master-border-width: 1px;
  --f7-page-swipeback-transition-duration: 300ms;
  --f7-page-parallax-transition-duration: 500ms;
  --f7-page-cover-transition-duration: 450ms;
  --f7-page-dive-transition-duration: 500ms;
  --f7-page-fade-transition-duration: 500ms;
  --f7-page-flip-transition-duration: 700ms;
  --f7-page-push-transition-duration: 500ms;
  /*
  --f7-page-content-extra-padding-top: 0px;
  --f7-page-content-extra-padding-bottom: 0px;
  */
  --f7-page-title-line-height: 1.2;
  --f7-page-title-text-color: inherit;
  --f7-page-title-padding-left: 16px;
  --f7-page-title-padding-right: 16px;
}
.ios-vars({
  --f7-page-transition-duration: 400ms;
  --f7-page-title-font-size: 34px;
  --f7-page-title-font-weight: 700;
  --f7-page-title-letter-spacing: -0.03em;
  --f7-page-title-padding-vertical: 6px;
  .light-vars({
    --f7-page-bg-color: #efeff4;
  });
  .dark-vars({
    --f7-page-bg-color: #000;
  });

});
.md-vars({
  --f7-page-transition-duration: 250ms;
  --f7-page-title-font-size: 34px;
  --f7-page-title-font-weight: 500;
  --f7-page-title-letter-spacing: 0;
  --f7-page-title-padding-vertical: 8px;
  .light-vars({
    --f7-page-bg-color: #fff;
  });
  .dark-vars({
    --f7-page-bg-color: #121212;
  });
});
.aurora-vars({
  --f7-page-transition-duration: 250ms;
  --f7-page-title-font-size: 28px;
  --f7-page-title-font-weight: bold;
  --f7-page-title-letter-spacing: 0;
  --f7-page-title-padding-vertical: 7px;
  .light-vars({
    --f7-page-bg-color: #f3f4f6;
  });
  .dark-vars({
    --f7-page-bg-color: #121212;
  });
});
.theme-dark {
  --f7-page-master-border-color: rgba(255, 255, 255, 0.2);
}
