.md {
  .preloader-inner {
    @speed: 1.4s;
    animation: md-preloader-rotate @speed linear infinite;
    circle {
      fill: none;
      stroke: var(--f7-preloader-color);
      animation: md-preloader-circle-rotate @speed * 4 ease-in-out infinite;
      stroke-dasharray: 100px;
      stroke-dashoffset: 80px;
      stroke-width: 4;
      transform: rotate(-90deg);
      transform-origin: 18px 18px;
    }
  }

  .preloader.color-multi {
    @speed: 1.4s;
    circle {
      animation: md-preloader-circle-rotate @speed * 4 ease-in-out infinite,
        md-preloader-multicolor @speed * 4 ease-in-out infinite;
    }
  }

  @keyframes md-preloader-multicolor {
    0%,
    100% {
      stroke: #4285f4;
    }
    75% {
      stroke: #1b9a59;
    }
    50% {
      stroke: #f7c223;
    }
    25% {
      stroke: #de3e35;
    }
  }
}
@keyframes md-preloader-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes md-preloader-circle-rotate {
  0% {
    transform: rotate(-90deg);
    stroke-dashoffset: 100px;
  }

  12.5% {
    transform: rotate(-90deg);
    stroke-dashoffset: 25px;
  }

  25% {
    stroke-dashoffset: 100px;
    transform: rotate(180deg);
  }

  25.1% {
    transform: rotate(-180deg);
    stroke-dashoffset: 100px;
  }

  37.5% {
    transform: rotate(-180deg);
    stroke-dashoffset: 25px;
  }

  50% {
    stroke-dashoffset: 100px;
    transform: rotate(90deg);
  }

  50.1% {
    transform: rotate(90deg);
    stroke-dashoffset: 100px;
  }

  62.5% {
    transform: rotate(90deg);
    stroke-dashoffset: 25px;
  }

  75% {
    stroke-dashoffset: 100px;
    transform: rotate(360deg);
  }

  75.1% {
    transform: rotate(0deg);
    stroke-dashoffset: 100px;
  }

  87.5% {
    transform: rotate(0deg);
    stroke-dashoffset: 25px;
  }

  100% {
    stroke-dashoffset: 100px;
    transform: rotate(270deg);
  }
}
