:root {
  --f7-area-chart-current-line-stroke-width: 2px;
  --f7-area-chart-current-line-stroke: rgba(0, 0, 0, 0.15);
  --f7-area-chart-axis-text-color: inherit;
  --f7-area-chart-axis-height: 1px;
  --f7-area-chart-axis-font-size: 10px;
  --f7-area-chart-axis-font-weight: 500;
  --f7-area-chart-tooltip-font-size: 12px;
  --f7-area-chart-tooltip-total-label-text-color: rgba(255, 255, 255, 0.75);
  --f7-area-chart-tooltip-total-font-size: 16px;
  --f7-area-chart-tooltip-total-font-weight: bold;
  --f7-area-chart-tooltip-color-size: 10px;
  --f7-area-chart-legend-font-size: 14px;
  --f7-area-chart-legend-font-weight: 500;
  --f7-area-chart-legend-text-color: inherit;
  --f7-area-chart-legend-padding: 4px 8px;
  --f7-area-chart-legend-border-radius: 4px;
  --f7-area-chart-legend-color-size: 14px;
  --f7-area-chart-line-stroke-width: 2px;

  .light-vars({
    --f7-area-chart-axis-bg-color: rgba(0, 0, 0, 0.15);
    --f7-area-chart-legend-disabled-text-color: rgba(0,0,0,0.22);
  });
  .dark-vars({
    --f7-area-chart-axis-bg-color: rgba(255, 255, 255, 0.15);
    --f7-area-chart-legend-disabled-text-color: rgba(255, 255, 255, 0.22);
  });
}
