/* === Stepper === */
@import './text-editor-vars.less';

.text-editor {
  margin: var(--f7-text-editor-margin);
  background-color: var(--f7-text-editor-bg-color);
  display: block;
  position: relative;
  border: var(--f7-text-editor-border-width) solid var(--f7-text-editor-border-color);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: var(--f7-text-editor-height);
  box-sizing: border-box;
  &.text-editor-resizable {
    height: auto;
  }
}
.text-editor-toolbar {
  display: flex;
  flex-wrap: wrap;
  background: var(--f7-text-editor-toolbar-bg-color);
  z-index: 100;
  position: relative;
  position: sticky;
  left: 0;
  top: 0;
  padding: var(--f7-text-editor-toolbar-padding);
  flex-shrink: 0;
  box-shadow: var(--f7-text-editor-toolbar-box-shadow);
  box-sizing: border-box;
  .hairline(bottom, var(--f7-text-editor-toolbar-border-color));
}
button.text-editor-button {
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
  font-family: inherit;
  background: transparent;
  cursor: pointer;
  box-shadow: none;
  border-radius: var(--f7-text-editor-button-border-radius);
  position: relative;
  z-index: 1;
  display: flex;
  width: auto;
  overflow: hidden;
  width: var(--f7-text-editor-button-size);
  height: var(--f7-text-editor-button-size);
  align-items: center;
  justify-content: center;
  margin: var(--f7-text-editor-button-margin);
  box-sizing: border-box;
  color: var(--f7-text-editor-button-text-color);
  background-color: var(--f7-text-editor-button-bg-color);
  flex-shrink: 0;
  i,
  .f7-icons,
  .material-icons {
    font-size: var(--f7-text-editor-button-icon-size);
    font-style: normal;
    sup,
    sub {
      font-size: 60%;
    }
  }
}
.text-editor-button-divider {
  width: 1px;
  background: var(--f7-text-editor-button-divider-color);
  margin: 0 2px;
  flex-shrink: 0;
}
.text-editor-content {
  -webkit-user-modify: read-write;
  appearance: none;
  border: none;
  outline: 0;
  height: 100%;
  user-select: text;
  padding: var(--f7-text-editor-padding);
  overflow: auto;
  flex-shrink: 10;
  box-sizing: border-box;
  color: var(--f7-text-editor-text-color);
  font-size: var(--f7-text-editor-font-size);
  font-weight: var(--f7-text-editor-font-weight);
  .text-editor-placeholder {
    pointer-events: none;
    color: var(--f7-text-editor-placeholder-color);
  }
  img {
    max-width: 100%;
  }
  a {
    pointer-events: none;
  }
}
.text-editor-popover {
  z-index: 12500;
  width: auto;
  max-width: 80vw;
  .popover-inner {
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
  }
}
.text-editor-keyboard-toolbar {
  position: absolute;
  z-index: 6000;
  --f7-safe-area-bottom: 0px;
  .toolbar-inner {
    justify-content: flex-start !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    .text-editor-button-divider {
      height: 100%;
    }
  }
}
.item-input {
  .text-editor {
    border: none;
    margin: 0;
    background-color: var(--f7-input-bg-color, transparent);
  }
  .text-editor-content {
    padding-top: var(--f7-textarea-padding-vertical);
    padding-bottom: var(--f7-textarea-padding-vertical);
    padding-left: var(--f7-input-padding-left);
    padding-right: var(--f7-input-padding-right);
    color: var(--f7-input-text-color);
    font-size: var(--f7-input-font-size);
  }
  .text-editor-toolbar {
    box-shadow: none;
    .hairline-remove(bottom);
  }
}
.item-input-outline {
  .text-editor-content {
    border-radius: var(--f7-input-outline-border-radius);
    padding-left: var(--f7-input-outline-padding-horizontal);
    padding-right: var(--f7-input-outline-padding-horizontal);
  }
}
.if-ios-theme({
  @import './text-editor-ios.less';
});
.if-md-theme({
  @import './text-editor-md.less';
});
.if-aurora-theme({
  @import './text-editor-aurora.less';
});
