.md {
  button.text-editor-button {
    --f7-touch-ripple-color: rgba(var(--f7-theme-color-rgb), 0.25);
    .link-highlight();
  }
  .text-editor-keyboard-toolbar {
    .toolbar-inner {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
