:root {
  /*
  --f7-range-bar-active-bg-color: var(--f7-theme-color);
  --f7-range-scale-bg-color: var(--f7-range-bar-bg-color);
  --f7-range-scale-substep-bg-color: var(--f7-range-bar-bg-color);
  */
  --f7-range-scale-step-height: 5px;
  --f7-range-scale-text-color: #666;
  --f7-range-scale-substep-width: 1px;
  --f7-range-scale-substep-height: 4px;
}
.ios-vars({
  --f7-range-size: 28px;
  --f7-range-bar-bg-color: #b7b8b7;
  --f7-range-bar-size: 4px;
  --f7-range-bar-border-radius: 2px;
  --f7-range-knob-size: 28px;
  --f7-range-knob-color: #fff;
  --f7-range-knob-box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  --f7-range-label-size: 24px;
  --f7-range-label-text-color: #000;
  --f7-range-label-bg-color: #fff;
  --f7-range-label-font-size: 12px;
  --f7-range-label-font-weight: 500;
  --f7-range-label-border-radius: 5px;
  --f7-range-label-padding: 0px 2px;


  --f7-range-scale-step-width: 1px;
  --f7-range-scale-font-size: 12px;
  --f7-range-scale-font-weight: 400;
  --f7-range-scale-label-offset: 4px;
});
.md-vars({
  --f7-range-size: 20px;
  --f7-range-bar-bg-color: #b9b9b9;
  --f7-range-bar-size: 2px;
  --f7-range-bar-border-radius: 0px;
  --f7-range-knob-size: 12px;
  /*
  --f7-range-knob-color: var(--f7-theme-color);
  */
  --f7-range-knob-box-shadow: none;
  --f7-range-label-size: 26px;
  --f7-range-label-font-weight: normal;
  --f7-range-label-text-color: #fff;
  /*
  --f7-range-label-bg-color: var(--f7-theme-color);
  */
  --f7-range-label-font-size: 10px;
  --f7-range-label-border-radius: 50%;
  --f7-range-label-padding: 0px;

  --f7-range-scale-step-width: 2px;
  --f7-range-scale-font-size: 12px;
  --f7-range-scale-font-weight: 400;
  --f7-range-scale-label-offset: 4px;
});
.aurora-vars({
  --f7-range-size: 20px;
  --f7-range-bar-bg-color: #c7c7c7;
  --f7-range-bar-size: 2px;
  --f7-range-bar-border-radius: 2px;
  --f7-range-knob-size: 16px;
  /*
  --f7-range-knob-color: var(--f7-theme-color);
  */
  --f7-range-knob-box-shadow: none;
  --f7-range-label-size: 20px;
  --f7-range-label-text-color: #fff;
  /*
  --f7-range-label-bg-color: var(--f7-theme-color);
  */
  --f7-range-label-font-size: 10px;
  --f7-range-label-font-weight: 600;
  --f7-range-label-border-radius: 4px;
  --f7-range-label-padding: 0px 4px;

  --f7-range-scale-step-width: 2px;
  --f7-range-scale-font-size: 11px;
  --f7-range-scale-font-weight: 500;
  --f7-range-scale-label-offset: 2px;
});
