:root {
  --f7-panel-width: 260px;
  /*
  --f7-panel-left-width: var(--f7-panel-width);
  --f7-panel-right-width: var(--f7-panel-width);
  --f7-panel-left-collapsed-width: var(--f7-panel-collapsed-width);
  --f7-panel-right-collapsed-width: var(--f7-panel-collapsed-width);
  */
  --f7-panel-bg-color: #fff;
}
.ios-vars({
  --f7-panel-collapsed-width: 58px;
  --f7-panel-backdrop-bg-color: rgba(0,0,0,0);
  --f7-panel-transition-duration: 400ms;
  --f7-panel-shadow: transparent;
});
.md-vars({
  --f7-panel-collapsed-width: 60px;
  --f7-panel-backdrop-bg-color: rgba(0,0,0,0.3);
  --f7-panel-transition-duration: 300ms;
  --f7-panel-shadow:
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.07) 30%,
    rgba(0, 0, 0, 0.03) 40%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0) 100%;
});
.aurora-vars({
  --f7-panel-collapsed-width: 60px;
  --f7-panel-backdrop-bg-color: rgba(0,0,0,0.3);
  --f7-panel-transition-duration: 300ms;
  --f7-panel-shadow:
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.07) 30%,
    rgba(0, 0, 0, 0.03) 40%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0) 100%;
});
