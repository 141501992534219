.md {
  .icon-radio {
    transition-duration: 200ms;
    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      top: 50%;
      margin-left: -5px;
      margin-top: -5px;
      background-color: var(--f7-radio-active-color, var(--f7-theme-color));
      border-radius: 50%;
      transform: scale(0);
      transition-duration: 200ms;
    }
  }
  label.item-radio input[type='radio']:checked ~ .icon-radio,
  label.item-radio input[type='radio']:checked ~ * .icon-radio,
  .radio input[type='radio']:checked ~ .icon-radio {
    border-color: var(--f7-radio-active-color, var(--f7-theme-color));
    &:after {
      background-color: var(--f7-radio-active-color, var(--f7-theme-color));
      transform: scale(1);
    }
  }
  label.item-radio {
    position: relative;
    overflow: hidden;
    z-index: 0;
  }
  label.item-radio:not(.item-radio-icon-end) {
    > .icon-radio {
      .ltr({
        margin-right: calc(var(--f7-list-item-media-margin) + var(--f7-radio-extra-margin))
      });
      .rtl({
        margin-left: calc(var(--f7-list-item-media-margin) + var(--f7-radio-extra-margin))
      });
    }
  }
  label.item-radio-icon-end input[type='radio'] ~ .icon-radio {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    .ltr({
      right: calc(var(--f7-safe-area-right) + 16px);
    });
    .rtl({
      left: calc(var(--f7-safe-area-left) + 16px);
    });
  }
  label.item-radio-icon-end {
    .item-inner {
      .ltr({
        padding-right: calc(var(--f7-safe-area-right) + 52px);
      });
      .rtl({
        padding-left: calc(var(--f7-safe-area-left) + 52px);
      });
    }
  }
}
