:root {
  --f7-fab-margin: 16px;
  --f7-fab-text-color: #fff;
  --f7-fab-extended-text-font-size: 14px;
  --f7-fab-extended-text-padding: 0 20px;
  --f7-fab-label-bg-color: #fff;
  --f7-fab-label-text-color: #333;
  --f7-fab-label-border-radius: 4px;
  --f7-fab-label-padding: 4px 12px;
  --f7-fab-label-font-size: inherit;
  --f7-fab-button-size: 40px;
  /* --f7-fab-pressed-bg-color: var(--f7-theme-color-shade); */
}
.ios-vars({
  --f7-fab-size: 50px;
  --f7-fab-box-shadow: 0px 2px 4px rgba(0,0,0,0.4);
  --f7-fab-extended-size: 50px;
  --f7-fab-extended-text-font-weight: 600;
  --f7-fab-extended-text-letter-spacing: 0;
  --f7-fab-label-box-shadow: 0px 1px 2px rgba(0,0,0,0.4);
});
.md-vars({
  --f7-fab-size: 56px;
  --f7-fab-box-shadow: var(--f7-elevation-6);
  --f7-fab-extended-size: 48px;
  --f7-fab-extended-text-font-weight: 500;
  --f7-fab-extended-text-letter-spacing: 0.05em;
  --f7-fab-label-box-shadow: var(--f7-elevation-3);
});
.aurora-vars({
  --f7-fab-size: 56px;
  --f7-fab-box-shadow: var(--f7-elevation-6);
  --f7-fab-extended-size: 48px;
  --f7-fab-extended-text-font-weight: 600;
  --f7-fab-extended-text-letter-spacing: 0;
  --f7-fab-label-box-shadow: 0px 1px 2px rgba(0,0,0,0.4);
});
