.md {
  .dialog {
    &.modal-out {
      transform: translate3d(0, -50%, 0) scale(0.815);
    }
  }
  .dialog-title {
    + .dialog-text {
      margin-top: 20px;
    }
  }
  .dialog-text {
    line-height: 1.5;
  }
  .dialog-buttons {
    height: 48px;
    padding: 6px 8px;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: flex-end;
  }

  .dialog-button {
    --f7-touch-ripple-color: rgba(var(--f7-theme-color-rgb), 0.25);
    border-radius: 4px;
    min-width: 64px;
    padding: 0 8px;
    border: none;
    transition-duration: 300ms;
    transform: translate3d(0, 0, 0);
    &.dialog-button-bold {
      font-weight: 700;
    }
    + .dialog-button {
      margin-left: 4px;
    }
  }
  .dialog-button[class*='color-'] {
    --f7-dialog-button-text-color: var(--f7-theme-color);
  }

  .dialog-buttons-vertical {
    .dialog-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .dialog-button + .dialog-button {
      margin-top: 12px;
    }
  }

  // Inputs
  .dialog-input {
    padding: 0;
    transition-duration: 200ms;
    position: relative;
    + .dialog-input {
      margin-top: 16px;
    }
  }

  // Preloader
  .dialog-preloader,
  .dialog-progress {
    .dialog-title,
    .dialog-inner {
      text-align: center;
    }
  }
  .dialog-preloader {
    .dialog-title ~ .preloader,
    .dialog-text ~ .preloader {
      margin-top: 20px;
    }
  }
  .dialog-progress {
    .dialog-title ~ .progressbar,
    .dialog-text ~ .progressbar,
    .dialog-title ~ .progressbar-infinite,
    .dialog-text ~ .progressbar-infinite {
      margin-top: 16px;
    }
  }
}
