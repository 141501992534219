:root {
  --f7-card-margin-horizontal: 16px;
  --f7-card-margin-vertical: 16px;
  --f7-card-content-padding-horizontal: 16px;
  --f7-card-content-padding-vertical: 16px;
  --f7-card-border-radius: 4px;
  --f7-card-font-size: inherit;
  --f7-card-header-text-color: inherit;
  --f7-card-header-font-weight: 400;
  --f7-card-header-padding-horizontal: 16px;
  --f7-card-footer-font-weight: 400;
  --f7-card-footer-font-size: inherit;
  --f7-card-footer-padding-horizontal: 16px;
  --f7-card-expandable-font-size: 16px;
  --f7-card-expandable-tablet-width: 670px;
  --f7-card-expandable-tablet-height: 670px;
  .light-vars({
    --f7-card-bg-color: #fff;
    --f7-card-outline-border-color: rgba(0,0,0,0.12);
    --f7-card-header-border-color: rgba(0, 0, 0, 0.1);
    --f7-card-footer-border-color: rgba(0, 0, 0, 0.1);
    --f7-card-expandable-bg-color: #fff;

  });
  .dark-vars({
    --f7-card-bg-color: #1c1c1d;
    --f7-card-expandable-bg-color: #1c1c1d;
    --f7-card-outline-border-color: rgba(255, 255, 255, 0.15);
    --f7-card-header-border-color: rgba(255, 255, 255, 0.15);
    --f7-card-footer-border-color: rgba(255, 255, 255, 0.15);
    --f7-card-footer-text-color: rgba(255,255,255,0.55);
  });
}
.ios-vars({
  --f7-card-box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
  --f7-card-header-font-size: 17px;
  --f7-card-header-padding-vertical: 10px;
  --f7-card-header-min-height: 44px;
  --f7-card-footer-text-color: rgba(0,0,0,0.45);
  --f7-card-footer-padding-vertical: 10px;
  --f7-card-footer-min-height: 44px;
  --f7-card-expandable-margin-horizontal: 20px;
  --f7-card-expandable-margin-vertical: 30px;
  --f7-card-expandable-box-shadow: 0px 20px 40px rgba(0,0,0,0.3);
  --f7-card-expandable-border-radius: 15px;
  --f7-card-expandable-tablet-border-radius: 5px;
  --f7-card-expandable-header-font-size: 27px;
  --f7-card-expandable-header-font-weight: bold;
});
.md-vars({
  --f7-card-box-shadow: var(--f7-elevation-1);
  --f7-card-header-font-size: 16px;
  --f7-card-header-padding-vertical: 4px;
  --f7-card-header-min-height: 48px;
  --f7-card-footer-text-color: rgba(0,0,0,0.54);
  --f7-card-footer-padding-vertical: 4px;
  --f7-card-footer-min-height: 48px;
  --f7-card-expandable-margin-horizontal: 12px;
  --f7-card-expandable-margin-vertical: 24px;
  --f7-card-expandable-box-shadow: var(--f7-elevation-10);
  --f7-card-expandable-border-radius: 8px;
  --f7-card-expandable-tablet-border-radius: 4px;
  --f7-card-expandable-header-font-size: 24px;
  --f7-card-expandable-header-font-weight: 500;
});
.aurora-vars({
  --f7-card-border-radius: 8px;
  --f7-card-box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  --f7-card-header-font-size: 16px;
  --f7-card-header-font-weight: bold;
  --f7-card-header-padding-vertical: 8px;
  --f7-card-header-min-height: 48px;
  --f7-card-footer-text-color: rgba(0,0,0,0.6);
  --f7-card-footer-padding-vertical: 8px;
  --f7-card-footer-min-height: 48px;
  --f7-card-expandable-margin-horizontal: 10px;
  --f7-card-expandable-margin-vertical: 20px;
  --f7-card-expandable-box-shadow: 0px 10px 20px rgba(0,0,0,0.2);
  --f7-card-expandable-border-radius: 8px;
  --f7-card-expandable-tablet-border-radius: 4px;
  --f7-card-expandable-header-font-size: 24px;
  --f7-card-expandable-header-font-weight: bold;
});
