:root {
  /*
  --f7-progressbar-progress-color: var(--f7-theme-color);
  */
}
.ios-vars({
  --f7-progressbar-height: 2px;
  --f7-progressbar-border-radius: 2px;
  .light-vars({
    --f7-progressbar-bg-color: rgba(0,0,0,0.3);
  });
  .dark-vars({
    --f7-progressbar-bg-color: rgba(255,255,255,0.3);
  });
});
.md-vars({
  /*
  --f7-progressbar-bg-color: rgba(var(--f7-theme-color-rgb), 0.5);
  */
  --f7-progressbar-height: 4px;
  --f7-progressbar-border-radius: 0px;
});
.aurora-vars({
  --f7-progressbar-height: 6px;
  --f7-progressbar-border-radius: 3px;
  .light-vars({
    --f7-progressbar-bg-color: #dbdbdb;
  });
  .dark-vars({
    --f7-progressbar-bg-color: #444;
  });
});
