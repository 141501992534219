.md {
  .stepper-button,
  .stepper-button-minus,
  .stepper-button-plus {
    transition-duration: 300ms;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
  }
  .stepper-fill,
  .stepper-fill-md {
    --f7-stepper-button-pressed-bg-color: var(
      --f7-stepper-fill-button-pressed-bg-color,
      var(--f7-theme-color-shade)
    );
  }
}
