:root {
  --f7-timeline-inner-block-margin-vertical: 16px;
  --f7-timeline-divider-margin-horizontal: 16px;
  --f7-timeline-horizontal-date-height: 34px;
  --f7-timeline-year-height: 24px;
  --f7-timeline-year-font-weight: inherit;
  --f7-timeline-month-height: 24px;
  --f7-timeline-month-font-size: inherit;
  --f7-timeline-month-font-weight: inherit;
  --f7-timeline-item-text-font-weight: inherit;
  --f7-timeline-item-subtitle-font-weight: inherit;
  .light-vars({
    --f7-timeline-item-inner-bg-color: #fff;
  });
  .dark-vars({
    --f7-timeline-item-inner-bg-color: #1c1c1d;
    --f7-timeline-horizontal-item-border-color: rgba(255, 255, 255, 0.15);
    --f7-timeline-horizontal-item-date-border-color: rgba(255, 255, 255, 0.15);
  });
}
.ios-vars({
  --f7-timeline-padding-horizontal: 16px;
  --f7-timeline-margin-vertical: 35px;
  --f7-timeline-item-inner-border-radius: 7px;
  --f7-timeline-item-inner-box-shadow: none;
  --f7-timeline-item-time-font-size: 13px;
  --f7-timeline-item-title-font-size: 17px;
  --f7-timeline-item-title-line-height: inherit;
  --f7-timeline-item-title-font-weight: 600;
  --f7-timeline-item-subtitle-font-size: 15px;
  --f7-timeline-item-subtitle-line-height: inherit;
  --f7-timeline-item-text-font-size: inherit;
  --f7-timeline-item-text-color: inherit;
  --f7-timeline-item-text-line-height: inherit;
  --f7-timeline-year-font-size: 16px;
  --f7-timeline-horizontal-item-padding: 10px;
  --f7-timeline-horizontal-item-date-shadow-image: none;
  .light-vars({
    --f7-timeline-item-time-text-color: rgba(0,0,0,0.45);
    --f7-timeline-horizontal-item-border-color: rgba(0,0,0,0.22);
    --f7-timeline-horizontal-item-date-border-color: rgba(0,0,0,0.22);
  });
  .dark-vars({
    --f7-timeline-item-time-text-color: rgba(255,255,255,0.55);
  });
});
.md-vars({
  --f7-timeline-padding-horizontal: 16px;
  --f7-timeline-margin-vertical: 32px;
  --f7-timeline-item-inner-border-radius: 4px;
  --f7-timeline-item-inner-box-shadow: var(--f7-elevation-1);
  --f7-timeline-item-time-font-size: 13px;
  --f7-timeline-item-title-font-size: 16px;
  --f7-timeline-item-title-line-height: inherit;
  --f7-timeline-item-title-font-weight: 400;
  --f7-timeline-item-subtitle-font-size: inherit;
  --f7-timeline-item-subtitle-line-height: inherit;
  --f7-timeline-item-text-font-size: inherit;
  --f7-timeline-item-text-line-height: inherit;
  --f7-timeline-item-text-color: inherit;
  --f7-timeline-year-font-size: 16px;
  --f7-timeline-horizontal-item-padding: 12px;
  --f7-timeline-horizontal-item-date-shadow-image: var(--f7-bars-shadow-bottom-image);
  .light-vars({
    --f7-timeline-item-time-text-color: rgba(0, 0, 0, 0.54);
    --f7-timeline-horizontal-item-border-color: rgba(0,0,0,0.12);
    --f7-timeline-horizontal-item-date-border-color: transparent;
  });
  .dark-vars({
    --f7-timeline-item-time-text-color: rgba(255,255,255,0.54);
  });
});
.aurora-vars({
  --f7-timeline-padding-horizontal: 16px;
  --f7-timeline-margin-vertical: 32px;
  --f7-timeline-item-inner-border-radius: 8px;
  --f7-timeline-item-inner-box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  --f7-timeline-item-time-font-size: 13px;
  --f7-timeline-item-title-font-size: 16px;
  --f7-timeline-item-title-line-height: inherit;
  --f7-timeline-item-title-font-weight: 600;
  --f7-timeline-item-subtitle-font-size: inherit;
  --f7-timeline-item-subtitle-line-height: inherit;
  --f7-timeline-item-text-font-size: inherit;
  --f7-timeline-item-text-line-height: inherit;
  --f7-timeline-item-text-color: inherit;
  --f7-timeline-year-font-size: 16px;
  --f7-timeline-horizontal-item-padding: 12px;
  --f7-timeline-horizontal-item-date-shadow-image: none;
  .light-vars({
    --f7-timeline-item-time-text-color: rgba(0,0,0,0.5);
    --f7-timeline-horizontal-item-border-color: rgba(0,0,0,0.2);
    --f7-timeline-horizontal-item-date-border-color: rgba(0,0,0,0.2);
  });
  .dark-vars({
    --f7-timeline-item-time-text-color: rgba(255,255,255,0.54);
  });
});
