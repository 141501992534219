:root {
  --f7-treeview-item-height: 34px;
  --f7-treeview-item-padding-left: 16px;
  --f7-treeview-item-padding-right: 16px;
  --f7-treeview-toggle-size: 24px;
  --f7-treeview-children-offset: 29px;
  --f7-treeview-label-font-weight: 400;
  --f7-treeview-label-text-color: inherit;
  --f7-treeview-icon-size: 24px;

  /*
  --f7-treeview-selectable-selected-bg-color: rgba(var(--f7-theme-color-rgb), 0.2);
  */
  .light-vars({
    --f7-treeview-toggle-color: rgba(0,0,0,0.5);
    --f7-treeview-toggle-hover-bg-color: rgba(0,0,0,0.1);
    --f7-treeview-toggle-pressed-bg-color: rgba(0,0,0,0.15);
    --f7-treeview-icon-color: rgba(0,0,0,0.5);
    --f7-treeview-selectable-hover-bg-color: rgba(0,0,0,0.1);
    --f7-treeview-link-hover-bg-color: rgba(0,0,0,0.1);
    --f7-treeview-link-pressed-bg-color: rgba(0,0,0,0.15);
  });
  .dark-vars({
    --f7-treeview-toggle-color: rgba(255,255,255,0.5);
    --f7-treeview-toggle-hover-bg-color: rgba(255,255,255,0.03);
    --f7-treeview-toggle-pressed-bg-color: rgba(255,255,255,0.1);
    --f7-treeview-icon-color: rgba(255,255,255,0.75);
    --f7-treeview-selectable-hover-bg-color: rgba(255,255,255,0.03);
    --f7-treeview-link-hover-bg-color: rgba(255,255,255,0.03);
    --f7-treeview-link-pressed-bg-color: rgba(255,255,255,0.11);
  });
}
.ios-vars({
  --f7-treeview-label-font-size: 17px;
});
.md-vars({
  --f7-treeview-label-font-size: 16px;
});
.aurora-vars({
  --f7-treeview-label-font-size: 16px;
});
