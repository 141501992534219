.md {
  .ios-only,
  .if-ios,
  .aurora-only,
  .if-aurora,
  .if-not-md,
  .not-md {
    display: none !important;
  }
}
