:root {
  /*
  --f7-subnavbar-bg-image: var(--f7-bars-bg-image);
  --f7-subnavbar-bg-color: var(--f7-bars-bg-color);
  --f7-subnavbar-bg-color-rgb: var(--f7-bars-bg-color-rgb);
  --f7-subnavbar-border-color: var(--f7-bars-border-color);
  --f7-subnavbar-link-color: var(--f7-bars-link-color);
  --f7-subnavbar-text-color: var(--f7-bars-text-color);
  */
  --f7-subnavbar-title-line-height: 1.2;
}
.ios-vars({
  --f7-subnavbar-height: 44px;
  --f7-subnavbar-inner-padding-left: 8px;
  --f7-subnavbar-inner-padding-right: 8px;
  --f7-subnavbar-title-font-size: 34px;
  --f7-subnavbar-title-font-weight: 700;
  --f7-subnavbar-title-letter-spacing: -0.03em;
  --f7-subnavbar-title-margin-left: 8px;
  --f7-subnavbar-shadow-image: none;
  /*
  --f7-subnavbar-link-height: var(--f7-subnavbar-height);
  --f7-subnavbar-link-line-height: var(--f7-subnavbar-height);
  */
});
.md-vars({
  --f7-subnavbar-height: 48px;
  --f7-subnavbar-inner-padding-left: 16px;
  --f7-subnavbar-inner-padding-right: 16px;
  --f7-subnavbar-title-font-size: 20px;
  --f7-subnavbar-title-font-weight: 500;
  --f7-subnavbar-title-letter-spacing: 0;
  --f7-subnavbar-title-margin-left: 0px;
  --f7-subnavbar-shadow-image: var(--f7-bars-shadow-bottom-image);
  /*
  --f7-subnavbar-link-height: var(--f7-subnavbar-height);
  --f7-subnavbar-link-line-height: var(--f7-subnavbar-height);
  */
});
.aurora-vars({
  --f7-subnavbar-height: 48px;
  --f7-subnavbar-inner-padding-left: 16px;
  --f7-subnavbar-inner-padding-right: 16px;
  --f7-subnavbar-title-font-size: 28px;
  --f7-subnavbar-title-font-weight: bold;
  --f7-subnavbar-title-letter-spacing: 0em;
  --f7-subnavbar-title-margin-left: 0px;
  --f7-subnavbar-shadow-image: none;
  --f7-subnavbar-link-height: auto;
  --f7-subnavbar-link-line-height: inherit;
});
