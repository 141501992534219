/* === Preloader === */
@import './preloader-vars.less';

.preloader {
  display: inline-block;
  vertical-align: middle;
  width: var(--f7-preloader-size);
  height: var(--f7-preloader-size);
  font-size: 0;
  position: relative;
  .preloader-inner {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
  }
}

/* === Preloader Modal === */
.preloader-backdrop {
  visibility: visible;
  opacity: 0;
  background: none;
  z-index: 14000;
}
.preloader-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  padding: var(--f7-preloader-modal-padding);
  background: var(--f7-preloader-modal-bg-color);
  z-index: 14500;
  transform: translateX(-50%) translateY(-50%);
  border-radius: var(--f7-preloader-modal-border-radius);

  .preloader {
    --f7-preloader-size: var(--f7-preloader-modal-preloader-size);
    display: block !important;
  }
}
html.with-modal-preloader .page-content,
.with-modal-preloader .page-content,
.with-modal-preloader.page-content {
  .not-scrollable();
}
.preloader[class*='color-'] {
  --f7-preloader-color: var(--f7-theme-color);
}
.if-ios-theme({
  @import './preloader-ios.less';
});
.if-md-theme({
  @import './preloader-md.less';
});
.if-aurora-theme({
  @import './preloader-aurora.less';
});
