:root {
  /*
  --f7-actions-button-text-color: var(--f7-theme-color);
  */
  --f7-actions-grid-button-font-size: 12px;
  --f7-actions-grid-button-text-color: #757575;
  --f7-actions-grid-button-icon-size: 48px;
  .dark-vars({
    --f7-actions-label-text-color:rgba(255,255,255,0.55);
  });
}
.ios-vars({
  --f7-actions-border-radius: 13px;

  --f7-actions-button-padding: 0px;
  --f7-actions-button-text-align: center;
  --f7-actions-button-height: 57px;
  --f7-actions-button-height-landscape: 44px;
  --f7-actions-button-font-size: 20px;
  --f7-actions-button-icon-size: 28px;
  --f7-actions-button-justify-content: center;
  --f7-actions-label-padding: 8px 10px;

  --f7-actions-label-font-size: 13px;
  --f7-actions-label-justify-content: center;
  --f7-actions-group-border-color: transparent;
  --f7-actions-group-margin: 8px;
  .light-vars({
    --f7-actions-bg-color: rgba(255,255,255,0.95);
    --f7-actions-bg-color-rgb: 255, 255, 255;
    --f7-actions-button-border-color: rgba(0,0,0,0.2);
    --f7-actions-button-pressed-bg-color: rgba(230,230,230,0.9);
    --f7-actions-button-pressed-bg-color-rgb: 230, 230, 230;
    --f7-actions-label-text-color: #8a8a8a;
  });
  .dark-vars({
    --f7-actions-bg-color: rgba(45,45,45,0.95);
    --f7-actions-bg-color-rgb: 45, 45, 45;
    --f7-actions-button-border-color: rgba(255, 255, 255, 0.15);
    --f7-actions-button-pressed-bg-color: rgba(50,50,50,0.9);
    --f7-actions-button-pressed-bg-color-rgb: 50, 50, 50;

  })
});
.md-vars({
  --f7-actions-border-radius: 0px;
  --f7-actions-button-border-color: transparent;
  --f7-actions-button-padding: 0 16px;
  --f7-actions-button-text-align: left;
  --f7-actions-button-height: 48px;
  --f7-actions-button-height-landscape: 48px;
  --f7-actions-button-font-size: 16px;
  --f7-actions-button-icon-size: 24px;
  --f7-actions-button-justify-content: space-between;
  --f7-actions-label-padding: 12px 16px;
  --f7-actions-label-font-size: 16px;
  --f7-actions-label-justify-content: flex-start;
  --f7-actions-group-margin: 0px;
  .light-vars({
    --f7-actions-bg-color: #fff;
    --f7-actions-button-pressed-bg-color: #e5e5e5;
    --f7-actions-label-text-color: rgba(0,0,0,0.54);
    --f7-actions-group-border-color: rgba(0,0,0,0.12);
  });
  .dark-vars({
    --f7-actions-bg-color: #202020;
    --f7-actions-button-pressed-bg-color: #2e2e2e;
    --f7-actions-group-border-color: rgba(255,255,255,0.15);
  });
});
.aurora-vars({
  --f7-actions-border-radius: 8px;
  --f7-actions-button-padding: 0 16px;
  --f7-actions-button-text-align: center;
  --f7-actions-button-height: 48px;
  --f7-actions-button-height-landscape: 48px;
  --f7-actions-button-font-size: 16px;
  --f7-actions-button-icon-size: 24px;
  --f7-actions-button-justify-content: space-between;
  --f7-actions-label-padding: 10px 16px;
  --f7-actions-label-font-size: 14px;
  --f7-actions-label-justify-content: center;
  --f7-actions-group-margin: 16px;
  .light-vars({
    --f7-actions-bg-color: #fff;
    --f7-actions-button-border-color: rgba(0,0,0,0.12);
    --f7-actions-button-pressed-bg-color: #e5e5e5;
    --f7-actions-label-text-color: rgba(0,0,0,0.5);
    --f7-actions-group-border-color: rgba(0,0,0,0.1);
  });
  .dark-vars({
    --f7-actions-bg-color: #202020;
    --f7-actions-button-border-color: rgba(255, 255, 255, 0.15);
    --f7-actions-button-pressed-bg-color: #2e2e2e;
    --f7-actions-group-border-color: rgba(255,255,255,0.15);
  });
});
