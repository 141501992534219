.md {
  .popover {
    transform: scale(0.85, 0.6);
    transition-property: opacity, transform;
    &.modal-in {
      opacity: 1;
      transform: scale(1);
    }
    &.modal-out {
      opacity: 0;
      transform: scale(1);
    }
  }
  .popover-on-top {
    transform-origin: center bottom;
  }
  .popover-on-top.popover-on-right {
    transform-origin: left bottom;
  }
  .popover-on-top.popover-on-left {
    transform-origin: right bottom;
  }
  .popover-on-middle {
    transform-origin: center center;
  }
  .popover-on-middle.popover-on-right {
    transform-origin: left center;
  }
  .popover-on-middle.popover-on-left {
    transform-origin: right center;
  }
  .popover-on-bottom {
    transform-origin: center top;
  }
  .popover-on-bottom.popover-on-right {
    transform-origin: left top;
  }
  .popover-on-bottom.popover-on-left {
    transform-origin: right top;
  }
}
