.custom-modal-backdrop {
  z-index: 10500;
}

.custom-modal-backdrop,
.actions-backdrop,
.dialog-backdrop,
.popover-backdrop,
.popup-backdrop,
.preloader-backdrop,
.sheet-backdrop,
.fab-backdrop {
  .modal-backdrop();
}
