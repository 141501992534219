:root {
  /*
  --f7-navbar-bg-color: var(--f7-bars-bg-color);
  --f7-navbar-bg-color-rgb: var(--f7-bars-bg-color-rgb);
  --f7-navbar-bg-image: var(--f7-bars-bg-image);
  --f7-navbar-border-color: var(--f7-bars-border-color);
  --f7-navbar-link-color: var(--f7-bars-link-color);
  --f7-navbar-text-color: var(--f7-bars-text-color);
  */
  --f7-navbar-hide-show-transition-duration: 400ms;
  --f7-navbar-title-line-height: 1.2;
  --f7-navbar-title-font-size: inherit;
  --f7-navbar-subtitle-text-align: inherit;
  --f7-navbar-large-title-line-height: 1.2;
  --f7-navbar-large-title-text-color: inherit;
  --f7-navbar-large-title-padding-left: 16px;
  --f7-navbar-large-title-padding-right: 16px;
}
.ios-vars({
  --f7-navbar-height: 44px;
  --f7-navbar-tablet-height: 44px;
  --f7-navbar-font-size: 17px;
  --f7-navbar-inner-padding-left: 8px;
  --f7-navbar-inner-padding-right: 8px;
  --f7-navbar-title-font-weight: 600;
  --f7-navbar-title-margin-left: 0;
  --f7-navbar-title-margin-right: 0;
  --f7-navbar-title-text-align: center;
  --f7-navbar-subtitle-font-size: 10px;
  --f7-navbar-subtitle-line-height: 1;
  --f7-navbar-shadow-image: none;
  --f7-navbar-large-title-height: 52px;
  --f7-navbar-large-title-font-size: 34px;
  --f7-navbar-large-title-font-weight: 700;
  --f7-navbar-large-title-letter-spacing: -0.03em;
  --f7-navbar-large-title-padding-vertical: 6px;
  /*
  --f7-navbar-link-height: var(--f7-navbar-height);
  --f7-navbar-link-line-height: var(--f7-navbar-height);
  */
  .light-vars({
    --f7-navbar-subtitle-text-color: rgba(0,0,0,0.55);
  });
  .dark-vars({
    --f7-navbar-subtitle-text-color: rgba(255,255,255,0.55);
  });
});
.md-vars({
  --f7-navbar-height: 56px;
  --f7-navbar-tablet-height: 64px;
  --f7-navbar-font-size: 20px;
  --f7-navbar-inner-padding-left: 4px;
  --f7-navbar-inner-padding-right: 4px;
  --f7-navbar-title-font-weight: 500;
  --f7-navbar-title-margin-left: 20px;
  --f7-navbar-title-margin-right: 16px;
  --f7-navbar-title-text-align: left;
  --f7-navbar-subtitle-font-size: 14px;
  --f7-navbar-subtitle-line-height: 1.2;
  --f7-navbar-shadow-image: var(--f7-bars-shadow-bottom-image);
  --f7-navbar-large-title-font-size: 34px;
  --f7-navbar-large-title-height: 56px;
  --f7-navbar-large-title-font-weight: 500;
  --f7-navbar-large-title-letter-spacing: 0;
  --f7-navbar-large-title-padding-vertical: 8px;

  --f7-navbar-link-height: 48px;
  --f7-navbar-link-line-height: 48px;

  .light-vars({
    --f7-navbar-subtitle-text-color: rgba(0,0,0,0.85);
  });
  .dark-vars({
    --f7-navbar-subtitle-text-color: rgba(255,255,255,0.85);
  });
});
.aurora-vars({
  --f7-navbar-height: 56px;
  --f7-navbar-tablet-height: 56px;
  --f7-navbar-font-size: 18px;
  --f7-navbar-inner-padding-left: 16px;
  --f7-navbar-inner-padding-right: 16px;
  --f7-navbar-title-font-weight: bold;
  --f7-navbar-title-margin-left: 0;
  --f7-navbar-title-margin-right: 0;
  --f7-navbar-title-text-align: center;
  --f7-navbar-subtitle-font-size: 14px;
  --f7-navbar-subtitle-line-height: 1;
  --f7-navbar-shadow-image: none;
  --f7-navbar-large-title-height: 48px;
  --f7-navbar-large-title-font-size: 28px;
  --f7-navbar-large-title-font-weight: bold;
  --f7-navbar-large-title-letter-spacing: 0;
  --f7-navbar-large-title-padding-vertical: 7px;
  --f7-navbar-link-height: auto;
  --f7-navbar-link-line-height: inherit;
  .light-vars({
    --f7-navbar-subtitle-text-color: rgba(0,0,0,0.6);
  });
  .dark-vars({
    --f7-navbar-subtitle-text-color: rgba(255,255,255,0.5);
  });
});
