.md {
  .toggle {
    input[type='checkbox']:checked + .toggle-icon {
      background: var(--f7-toggle-active-color, rgba(var(--f7-theme-color-rgb), 0.5));
      &:after {
        .ltr({
          transform: translateX(calc(var(--f7-toggle-width) - var(--f7-toggle-height) - 6px));
        });
        .rtl({
          transform: translateX(calc(-1 * (var(--f7-toggle-width) - var(--f7-toggle-height) - 6px)));
        });
        background: var(--f7-toggle-active-color, var(--f7-theme-color));
      }
    }
  }
  .toggle-icon {
    background: var(--f7-toggle-inactive-color);
    &:after {
      height: calc(var(--f7-toggle-height) + 6px);
      width: calc(var(--f7-toggle-height) + 6px);
      top: -3px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      border-radius: var(--f7-toggle-height);
      .ltr({ left: 0; });
      .rtl({ right: 0; });
    }
  }
}
