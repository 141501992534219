.md {
  .messagebar-attachments {
    padding: 8px;
    border-bottom: 1px solid var(--f7-messagebar-attachments-border-color);
  }
  .messagebar-area {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .messagebar-sheet-image,
  .messagebar-sheet-item {
    .icon-checkbox {
      border-color: #fff;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }
  }
  .messagebar-attachment-delete {
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    background-color: var(--f7-theme-color);
    border-radius: 4px;
    &:after,
    &:before {
      width: 14px;
      height: 2px;
      background: #fff;
      margin-left: -7px;
      margin-top: -1px;
    }
  }
}
