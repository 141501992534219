/* === Timeline === */
@import './timeline-vars.less';

.timeline {
  box-sizing: border-box;
  margin: var(--f7-timeline-margin-vertical) 0;
  padding: 0 var(--f7-timeline-padding-horizontal);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: calc(var(--f7-timeline-padding-horizontal) + var(--f7-safe-area-left));
  padding-right: calc(var(--f7-timeline-padding-horizontal) + var(--f7-safe-area-right));
  .block-strong & {
    padding: 0;
    margin: 0;
  }
}
.timeline-item {
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  padding: 2px 0px var(--f7-timeline-padding-horizontal);
  &:last-child {
    padding-bottom: 2px;
  }
}
.timeline-item-date {
  flex-shrink: 0;
  width: 50px;
  text-align: right;
  box-sizing: border-box;
  small {
    font-size: 10px;
  }
}
.timeline-item-content {
  margin: 2px;
  min-width: 0;
  position: relative;
  flex-shrink: 10;
  .card,
  &.card,
  .list,
  &.list,
  .block,
  &.block {
    margin: 0;
    width: 100%;
  }
  .card,
  .list,
  .block {
    + .card,
    + .list,
    + .block {
      margin: var(--f7-timeline-inner-block-margin-vertical) 0 0;
    }
  }
  p,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4 {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.timeline-item-inner {
  background: var(--f7-timeline-item-inner-bg-color);
  box-sizing: border-box;
  border-radius: var(--f7-timeline-item-inner-border-radius);
  padding: 8px var(--f7-timeline-padding-horizontal);
  box-shadow: var(--f7-timeline-item-inner-box-shadow);

  + .timeline-item-inner {
    margin-top: var(--f7-timeline-inner-block-margin-vertical);
  }
  .block {
    padding: 0;
    color: inherit;
  }
  .block-strong {
    .hairline-remove-top-bottom();
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
  .list {
    ul {
      .hairline-remove-top-bottom();
    }
  }
}
.timeline-item-divider {
  width: 1px;
  position: relative;
  width: 10px;
  height: 10px;
  background: #bbb;
  border-radius: 50%;
  flex-shrink: 0;
  margin: 3px var(--f7-timeline-divider-margin-horizontal) 0;
  &:after,
  &:before {
    content: ' ';
    width: 1px;
    height: 100vh;
    position: absolute;
    left: 50%;
    background: inherit;
    transform: translate3d(-50%, 0, 0);
  }
  &:after {
    top: 100%;
  }
  &:before {
    bottom: 100%;
  }
  .timeline-item:last-child &:after {
    display: none;
  }
  .timeline-item:first-child &:before {
    display: none;
  }
}
.timeline-item-time {
  font-size: var(--f7-timeline-item-time-font-size);
  margin-top: var(--f7-timeline-inner-block-margin-vertical);
  color: var(--f7-timeline-item-time-text-color);
  &:first-child,
  &:last-child {
    margin-top: 0;
  }
  .timeline-item-title + & {
    margin-top: 0;
  }
}
.timeline-item-title {
  font-size: var(--f7-timeline-item-title-font-size);
  font-weight: var(--f7-timeline-item-title-font-weight);
  line-height: var(--f7-timeline-item-title-line-height);
}
.timeline-item-subtitle {
  font-size: var(--f7-timeline-item-subtitle-font-size);
  font-weight: var(--f7-timeline-item-subtitle-font-weight);
  line-height: var(--f7-timeline-item-subtitle-line-height);
}
.timeline-item-text {
  color: var(--f7-timeline-item-text-color);
  font-size: var(--f7-timeline-item-text-font-size);
  font-weight: var(--f7-timeline-item-text-font-weight);
  line-height: var(--f7-timeline-item-text-line-height);
}
.timelineItemLeft() {
  flex-direction: row-reverse;
  .ltr({
    margin-right: calc(50% - (var(--f7-timeline-divider-margin-horizontal) * 2 + 10px) / 2 - 50px);
    margin-left: 0;
    .timeline-item-date {
      text-align: left;
    }
  });
  .rtl({
    margin-left: calc(50% - (var(--f7-timeline-divider-margin-horizontal) * 2 + 10px) / 2 - 50px);
    margin-right: 0;
    .timeline-item-date {
      text-align: right;
    }
  });
}
.timelineItemRight() {
  .ltr({
    margin-left: calc(50% - (var(--f7-timeline-divider-margin-horizontal) * 2 + 10px) / 2 - 50px);
    margin-right: 0;
    .timeline-item-date {
      text-align: right;
    }
  });
  .rtl({
    margin-right: calc(50% - (var(--f7-timeline-divider-margin-horizontal) * 2 + 10px) / 2 - 50px);
    margin-left: 0;
    .timeline-item-date {
      text-align: left;
    }
  });
}
.timeline-sides {
  .timeline-item-right,
  .timeline-item {
    .timelineItemRight();
  }
  .timeline-item-left,
  .timeline-item:not(.timeline-item-right):nth-child(2n) {
    .timelineItemLeft();
  }
}
each(@breakpoints, {
  @media (min-width:@value) {
    .@{key}-sides {
      .timeline-sides;
    }
  }
});

.timeline-horizontal {
  height: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  position: relative;
  padding-left: var(--f7-safe-area-left);
  padding-right: 0;
  .timeline-item {
    display: block;
    width: 100/3vw;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    position: relative;
    height: 100%;
    padding-top: var(--f7-timeline-horizontal-date-height) !important;
    padding-bottom: var(--f7-timeline-horizontal-item-padding);
    .hairline(right, var(--f7-timeline-horizontal-item-border-color));
  }
  .timeline-item-date {
    padding: 0px var(--f7-timeline-horizontal-item-padding);
    width: auto;
    text-align: left;
    line-height: var(--f7-timeline-horizontal-date-height);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: var(--f7-timeline-horizontal-date-height);
    background-color: var(--f7-bars-bg-color);
    color: var(--f7-bars-text-color);
    .ios-translucent-bars(var(--f7-bars-bg-color-rgb));
    .hairline(bottom, var(--f7-timeline-horizontal-item-date-border-color));
    .bar-shadow-bottom(var(--f7-timeline-horizontal-item-date-shadow-image));
    .ltr({
      text-align: left;
    });
    .rtl({
      text-align: right;
    });
  }
  &.no-shadow .timeline-item-date:before {
    display: none;
  }
  .timeline-item-content {
    padding: var(--f7-timeline-horizontal-item-padding);
    height: calc(100% - var(--f7-timeline-horizontal-item-padding));
    .scrollable();
    margin: 0;
  }
  .timeline-item-divider {
    display: none;
  }
  > .timeline-item:last-child,
  .timeline-month:last-child .timeline-item:last-child {
    .hairline-remove(right);
  }

  @cols: 5, 10, 15, 20, 25, 30, 100/3, 35, 40, 45, 50, 55, 60, 65, 100 * (2/3), 70, 75, 80, 85, 90,
    95, 100;
  each(@cols, {
    @className: floor((@value));
    &.col-@{className} .timeline-item {
      width: ~"@{value}vw";
    }
  });

  @media (min-width: 768px) {
    each(@cols, {
      @className: floor((@value));
      &.tablet-@{className} .timeline-item {
        width: ~"@{value}vw";
      }
    });
  }
}
.timeline-year {
  padding-top: var(--f7-timeline-year-height);
  .hairline(right, var(--f7-timeline-horizontal-item-border-color));
  &:last-child {
    .hairline-remove(right);
  }
}
.timeline-month {
  padding-top: var(--f7-timeline-month-height);
  .timeline-item {
    .hairline(top, var(--f7-timeline-horizontal-item-border-color));
  }
}
.timeline-year,
.timeline-month {
  display: flex;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  height: 100%;
}
.timeline-year-title {
  line-height: var(--f7-timeline-year-height);
  height: var(--f7-timeline-year-height);
  font-size: var(--f7-timeline-year-font-size);
  font-weight: var(--f7-timeline-year-font-weight);
}
.timeline-month-title {
  line-height: var(--f7-timeline-month-height);
  height: var(--f7-timeline-month-height);
  font-size: var(--f7-timeline-month-font-size);
  font-weight: var(--f7-timeline-month-font-weight);
}
.timeline-year-title,
.timeline-month-title {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--f7-timeline-horizontal-item-padding);
  background-color: var(--f7-bars-bg-color);
  color: var(--f7-bars-text-color);
  .ios-translucent-bars(var(--f7-bars-bg-color-rgb));
  span {
    display: inline-block;
    position: sticky;
    .ltr({
      left: calc(var(--f7-timeline-horizontal-item-padding) + var(--f7-safe-area-left));
    });
    .rtl({
      right: calc(var(--f7-timeline-horizontal-item-padding) + var(--f7-safe-area-right));
    });
  }
}
.timeline-month-title {
  span {
    margin-top: -2px;
  }
}
.timeline-year:first-child .timeline-year-title,
.timeline-year:first-child .timeline-month:first-child .timeline-month-title,
.timeline-year:first-child .timeline-year-title + .timeline-month .timeline-month-title {
  left: calc(var(--f7-safe-area-left) * -1);
  right: 0;
  width: auto;
}
.timeline-horizontal .timeline-item:first-child,
.timeline-year:first-child .timeline-month:first-child .timeline-item:first-child,
.timeline-year:first-child .timeline-year-title + .timeline-month .timeline-item:first-child,
.timeline-year:first-child
  .timeline-year-title
  + .timeline-month
  .timeline-month-title
  + .timeline-item {
  overflow: visible;
  .timeline-item-date {
    width: auto;
    padding-left: calc(var(--f7-timeline-horizontal-item-padding) + var(--f7-safe-area-left));
    left: calc(0px - var(--f7-safe-area-left));
    right: 0;
  }
}
.timeline-year:last-child .timeline-year-title,
.timeline-year:last-child .timeline-month:last-child .timeline-month-title {
  width: auto;
  right: calc(0px - var(--f7-safe-area-right));
}
.timeline-horizontal .timeline-item:last-child,
.timeline-year:last-child .timeline-month:last-child .timeline-item:last-child {
  overflow: visible;
  .timeline-item-date {
    width: auto;
    right: calc(0px - var(--f7-safe-area-right));
    left: 0;
  }
}

.if-ios-theme({
  @import './timeline-ios.less';
});
.if-md-theme({
  @import './timeline-md.less';
});
.if-aurora-theme({
  @import './timeline-aurora.less';
});
