:root {
  --f7-grid-gap: 16px;
  --f7-grid-row-gap: 0px;
  .light-vars({
    --f7-grid-resize-handler-bg-color: rgba(0, 0, 0, 0.35);
  });
  .dark-vars({
    --f7-grid-resize-handler-bg-color: rgba(255, 255, 255, 0.35);
  });
}
