// f7-parallax
.router-transition-f7-parallax-forward {
  .page-next {
    animation: f7-parallax-next-to-current var(--f7-page-parallax-transition-duration) forwards;
  }
  .page-current {
    animation: f7-parallax-current-to-prev var(--f7-page-parallax-transition-duration) forwards;
  }
}
.router-transition-f7-parallax-backward {
  .page-current {
    animation: f7-parallax-current-to-next var(--f7-page-parallax-transition-duration) forwards;
  }
  .page-previous {
    animation: f7-parallax-prev-to-current var(--f7-page-parallax-transition-duration) forwards;
  }
}
@keyframes f7-parallax-next-to-current {
  from {
    .ltr({
      transform: translateX(100%);
    });
    .rtl({
      transform: translateX(-100%);
    });
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes f7-parallax-current-to-next {
  from {
    transform: translateX(0%);
  }
  to {
    .ltr({
      transform: translateX(100%);
    });
    .rtl({
      transform: translateX(-100%);
    });
  }
}
@keyframes f7-parallax-current-to-prev {
  from {
    transform: translateX(0%);
  }
  to {
    .ltr({
      transform: translateX(-20%);
    });
    .rtl({
      transform: translateX(20%);
    });
  }
}
@keyframes f7-parallax-prev-to-current {
  from {
    .ltr({
      transform: translateX(-20%);
    });
    .rtl({
      transform: translateX(20%);
    });
  }
  to {
    transform: translateX(-0%);
  }
}
