/* === Sortable === */
@import './sortable-vars.less';

.sortable {
  .sortable-handler {
    width: var(--f7-sortable-handler-width);
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    cursor: move;
    transition-duration: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &:after {
      .core-icons-font();
      transition-duration: 300ms;
      transform: translateX(10px);
      color: var(--f7-sortable-handler-color);
      overflow: hidden;
      height: 20px;
      width: 18px;
    }
    .ltr({
      right: var(--f7-safe-area-right);
    });
    .rtl({
      left: var(--f7-safe-area-left);
    });
  }
  li.sorting {
    z-index: 50;
    background: var(--f7-sortable-sorting-item-bg-color);
    transition-duration: 0ms;
    box-shadow: var(--f7-sortable-sorting-item-box-shadow);
    .item-inner {
      .hairline-remove(bottom);
    }
  }
}
.sortable-opposite {
  .sortable-handler {
    .ltr({
      left: var(--f7-safe-area-right);
      right: auto;
    });
    .rtl({
      right: var(--f7-safe-area-left);
      left: auto;
    });
    &:after {
      transform: translateX(-10px);
    }
  }
}
.sortable:not(.sortable-opposite) .item-inner,
.sortable-opposite .item-content,
.sortable.simple-list li,
.sortable-opposite.links-list li a {
  transition-duration: 300ms;
  transition-property: all;
}
.sortable-sorting li {
  transition-duration: 300ms;
}
.sortable-enabled li:not(.no-sorting):not(.disallow-sorting) {
  .sortable-handler {
    pointer-events: auto;
    opacity: 1;
    &:after {
      transform: translateX(0px);
    }
  }
}
.sortable-enabled {
  &:not(.sortable-opposite) li:not(.no-sorting):not(.disallow-sorting) .item-link .item-inner,
  &:not(.sortable-opposite) li:not(.no-sorting):not(.disallow-sorting) .item-link .item-title-row,
  &:not(.sortable-opposite).links-list li:not(.no-sorting):not(.disallow-sorting) > a {
    &:before {
      display: none;
    }
  }
}
.sortable-enabled:not(.sortable-opposite) li:not(.no-sorting):not(.disallow-sorting) .item-inner,
.sortable-enabled:not(.sortable-opposite)
  li:not(.no-sorting):not(.disallow-sorting)
  .item-link
  .item-inner,
.sortable-enabled:not(.sortable-opposite)
  li:not(.no-sorting):not(.disallow-sorting)
  .item-link.no-chevron
  .item-inner,
.sortable-enabled.no-chevron:not(.sortable-opposite)
  li:not(.no-sorting):not(.disallow-sorting)
  .item-link
  .item-inner,
.sortable-enabled :not(.sortable-opposite).no-chevron .item-link .item-inner,
.no-chevron
  .sortable-enabled:not(.sortable-opposite)
  li:not(.no-sorting):not(.disallow-sorting)
  .item-link
  .item-inner,
.simple-list.sortable-enabled:not(.sortable-opposite) li:not(.no-sorting):not(.disallow-sorting),
.links-list.sortable-enabled:not(.sortable-opposite) li:not(.no-sorting):not(.disallow-sorting) a {
  .ltr({
    padding-right: calc(var(--f7-sortable-handler-width) + var(--f7-safe-area-right));
  });
  .rtl({
    padding-left: calc(var(--f7-sortable-handler-width) + var(--f7-safe-area-right));
  });
}
.sortable-opposite.sortable-enabled li:not(.no-sorting):not(.disallow-sorting) .item-content,
.simple-list.sortable-opposite.sortable-enabled li:not(.no-sorting):not(.disallow-sorting),
.links-list.sortable-opposite.sortable-enabled li:not(.no-sorting):not(.disallow-sorting) a {
  .ltr({
    padding-left: calc(var(--f7-sortable-handler-width) + var(--f7-safe-area-right));
  });
  .rtl({
    padding-right: calc(var(--f7-sortable-handler-width) + var(--f7-safe-area-right));
  });
}

.if-ios-theme({
  @import './sortable-ios.less';
});
.if-md-theme({
  @import './sortable-md.less';
});
.if-aurora-theme({
  @import './sortable-aurora.less';
});
