:root {
  --f7-sheet-height: 260px;
  --f7-sheet-border-color: transparent;
  --f7-sheet-transition-duration: 300ms;
  --f7-sheet-push-border-radius: 10px;
  --f7-sheet-push-offset: var(--f7-safe-area-top);
  .light-vars({
    --f7-sheet-bg-color: #fff;
  });
  .dark-vars({
    --f7-sheet-bg-color: #202020;
  });
}
.ios-vars({
  --f7-sheet-border-color: var(--f7-bars-border-color);
});
