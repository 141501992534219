.md {
  .chip-label + .chip-delete {
    .ltr({
      margin-left: 4px;
    });
    .rtl({
      margin-right: 4px;
    });
  }
  .chip-delete {
    .ltr({
      margin-right: calc(-1 * var(--f7-chip-padding-horizontal) + 4px);
    });
    .rtl({
      margin-left: calc(-1 * var(--f7-chip-padding-horizontal) + 4px);
    });
    &:after {
      font-size: 12px;
    }
  }
}
