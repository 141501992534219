/* === Images Lazy Loading === */
.lazy-loaded.lazy-fade-in {
  animation: lazyFadeIn 600ms;
}

@keyframes lazyFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.if-ios-theme({
  @import './lazy-ios.less';
});
.if-md-theme({
  @import './lazy-md.less';
});
.if-aurora-theme({
  @import './lazy-aurora.less';
});
