.md {
  // Buttons
  .button {
    transition-duration: 300ms;
    transform: translate3d(0, 0, 0);
  }
  .button-fill,
  .button-fill-md {
    --f7-button-pressed-bg-color: var(
      --f7-button-fill-pressed-bg-color,
      var(--f7-theme-color-shade)
    );
  }
}
