:root {
  /*
  --f7-stepper-button-text-color: var(--f7-theme-color);
  --f7-stepper-button-pressed-text-color: var(--f7-button-text-color, var(--f7-theme-color));
  --f7-stepper-value-text-color: var(--f7-theme-color);
  */
  --f7-stepper-fill-button-text-color: #fff;
  /*
  --f7-stepper-fill-button-bg-color: var(--f7-theme-color);
  */
  --f7-stepper-raised-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --f7-stepper-value-font-weight: 500;
  --f7-stepper-border-width: 2px;
  --f7-stepper-small-border-width: 2px;
}
.ios-vars({
  --f7-stepper-height: 28px;
  --f7-stepper-border-radius: 5px;
  /*
  --f7-stepper-button-pressed-bg-color: rgba(var(--f7-theme-color-rgb), .15);
  --f7-stepper-fill-button-pressed-bg-color: var(--f7-theme-color-tint);
  */
  --f7-stepper-large-height: 44px;
  --f7-stepper-small-height: 26px;
  --f7-stepper-value-font-size: 17px;
});
.md-vars({
  --f7-stepper-height: 36px;
  --f7-stepper-border-radius: 4px;
  /*
  --f7-stepper-fill-button-pressed-bg-color: var(--f7-theme-color-shade);
  */
  --f7-stepper-large-height: 48px;
  --f7-stepper-small-height: 28px;
  --f7-stepper-value-font-size: 14px;
});
.aurora-vars({
  /*
  --f7-stepper-button-hover-bg-color: rgba(var(--f7-theme-color-rgb), .07);
  --f7-stepper-button-fill-hover-bg-color: var(--f7-theme-color-tint);
  */
  --f7-stepper-height: 36px;
  --f7-stepper-border-radius: 8px;
  /*
  --f7-stepper-button-pressed-bg-color: rgba(var(--f7-theme-color-rgb), .15);
  --f7-stepper-fill-button-pressed-bg-color: var(--f7-theme-color-shade);
  */
  --f7-stepper-large-height: 48px;
  --f7-stepper-small-height: 28px;
  --f7-stepper-value-font-size: 14px;
});
