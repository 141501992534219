/* === Progressbar === */
@import './progressbar-vars.less';

.progressbar,
.progressbar-infinite {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: block;
  transform-style: preserve-3d;
  background: var(--f7-progressbar-bg-color, rgba(var(--f7-theme-color-rgb), 0.5));
  transform-origin: center top;
  height: var(--f7-progressbar-height);
  border-radius: var(--f7-progressbar-border-radius);
}
.progressbar {
  vertical-align: middle;
  span {
    background-color: var(--f7-progressbar-progress-color, var(--f7-theme-color));
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate3d(-100%, 0, 0);
    transition-duration: 150ms;
  }
}
.progressbar-infinite {
  z-index: 15000;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform-origin: left center;
    transform: translate3d(0, 0, 0);
    display: block;
    background-color: var(--f7-progressbar-progress-color, var(--f7-theme-color));
  }
  &.color-multi {
    background: none !important;
  }
}
.progressbar-in {
  animation: progressbar-in 150ms forwards;
}
.progressbar-out {
  animation: progressbar-out 150ms forwards;
}

body,
.view,
.views,
.page,
.panel,
.popup,
.framework7-root {
  > .progressbar,
  > .progressbar-infinite {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 15000;
    border-radius: 0 !important;
    transform-origin: center top !important;
  }
}

@keyframes progressbar-in {
  from {
    opacity: 0;
    transform: scaleY(0);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}
@keyframes progressbar-out {
  from {
    opacity: 1;
    transform: scaleY(1);
  }
  to {
    opacity: 0;
    transform: scaleY(0);
  }
}

.if-ios-theme({
  @import './progressbar-ios.less';
});
.if-md-theme({
  @import './progressbar-md.less';
});
.if-aurora-theme({
  @import './progressbar-aurora.less';
});
