:root {
  --f7-popover-width: 260px;
}
.ios-vars({
  --f7-popover-border-radius: 13px;
  --f7-popover-box-shadow: none;
  --f7-popover-actions-icon-size: 28px;
  .light-vars({
    --f7-popover-bg-color: rgba(255,255,255,0.95);
    --f7-popover-actions-label-text-color: rgba(0,0,0,0.45);
  });
  .dark-vars({
    --f7-popover-bg-color: rgba(30,30,30,0.95);
    --f7-popover-actions-label-text-color: rgba(255,255,255,0.55);
  });
});
.md-vars({
  --f7-popover-border-radius: 4px;
  --f7-popover-box-shadow: var(--f7-elevation-8);
  --f7-popover-actions-icon-size: 24px;
  .light-vars({
    --f7-popover-bg-color: #fff;
    --f7-popover-actions-label-text-color: rgba(0,0,0,0.54);
  });
  .dark-vars({
    --f7-popover-bg-color: #1c1c1d;
    --f7-popover-actions-label-text-color: rgba(255,255,255,0.54);
  });
});
.aurora-vars({
  --f7-popover-border-radius: 8px;
  --f7-popover-box-shadow: var(--f7-elevation-8);
  --f7-popover-actions-icon-size: 24px;
  .light-vars({
    --f7-popover-bg-color: #fff;
    --f7-popover-actions-label-text-color: rgba(0,0,0,0.6);
  });
  .dark-vars({
    --f7-popover-bg-color: #1c1c1d;
    --f7-popover-actions-label-text-color: rgba(255,255,255,0.6);
  });
});
