/* === Messages === */
@import './messages-vars.less';
.messages-content,
.messages {
  background: var(--f7-messages-content-bg-color);
}
.messages {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  z-index: 1;
}
.messages-title,
.message {
  margin-top: var(--f7-message-margin);
  &:last-child {
    margin-bottom: var(--f7-message-margin);
  }
}
.messages-title {
  text-align: center;
  width: 100%;
  line-height: 1;
  color: var(--f7-messages-title-text-color);
  font-size: var(--f7-messages-title-font-size);
  font-weight: var(--f7-messages-title-font-weight);
}
.message {
  max-width: 70%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
  transform: translate3d(0, 0, 0);
}
.message-avatar {
  border-radius: var(--f7-message-avatar-border-radius);
  position: relative;
  background-size: cover;
  align-self: flex-end;
  flex-shrink: 0;
  width: var(--f7-message-avatar-size);
  height: var(--f7-message-avatar-size);
}
.message-content {
  position: relative;
  display: flex;
  flex-direction: column;
}
.message-header,
.message-footer,
.message-name {
  line-height: 1;
}
.message-header {
  color: var(--f7-message-header-text-color);
  font-size: var(--f7-message-header-font-size);
}
.message-footer {
  color: var(--f7-message-footer-text-color);
  font-size: var(--f7-message-footer-font-size);
  margin-bottom: -1em;
}
.message-name {
  color: var(--f7-message-name-text-color);
  font-size: var(--f7-message-name-font-size);
  font-weight: var(--f7-message-name-font-weight);
}
.message-bubble {
  box-sizing: border-box;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: var(--f7-message-bubble-line-height);
  font-size: var(--f7-message-bubble-font-size);
  border-radius: var(--f7-message-bubble-border-radius);
  padding: var(--f7-message-bubble-padding-vertical) var(--f7-message-bubble-padding-horizontal);
  min-height: var(--f7-message-bubble-min-height);
}
.message-image {
  img {
    display: block;
    max-width: 100%;
    height: auto;
    width: auto;
  }
}
.message-text-header,
.message-text-footer {
  line-height: 1;
}
.message-text-header {
  color: var(--f7-message-text-header-text-color);
  opacity: var(--f7-message-text-header-opacity);
  font-size: var(--f7-message-text-header-font-size);
}
.message-text-footer {
  color: var(--f7-message-text-footer-text-color);
  opacity: var(--f7-message-text-footer-opacity);
  font-size: var(--f7-message-text-footer-font-size);
}
.message-text {
  text-align: left;
}
.message-sent {
  text-align: right;
  .ltr({
    flex-direction: row-reverse;
    align-self: flex-end;
  });
  .rtl({
    flex-direction: row;
    align-self: flex-start;
  });
  .message-bubble {
    color: var(--f7-message-sent-text-color);
    background: var(--f7-message-sent-bg-color, var(--f7-theme-color));
  }
  .message-content {
    .ltr({
      align-items: flex-end;
    });
    .rtl({
      align-items: flex-start;
    });
  }
  &.message-tail .message-bubble {
    border-radius: var(--f7-message-bubble-border-radius) var(--f7-message-bubble-border-radius) 0
      var(--f7-message-bubble-border-radius);
  }
}
// Rules
.message-received {
  .ltr({
    flex-direction: row;
  });
  .rtl({
    flex-direction: row-reverse;
    align-self: flex-end;
  });
  .message-bubble {
    color: var(--f7-message-received-text-color);
    background: var(--f7-message-received-bg-color);
  }
  .message-content {
    .ltr({
      align-items: flex-start;
    });
    .rtl({
      align-items: flex-end;
    });
  }
  &.message-tail .message-bubble {
    border-radius: var(--f7-message-bubble-border-radius) var(--f7-message-bubble-border-radius)
      var(--f7-message-bubble-border-radius) 0;
  }
}
.message:not(.message-last) {
  .message-avatar {
    opacity: 0;
  }
}
.message:not(.message-first) {
  .message-name {
    display: none;
  }
}
.message.message-same-name {
  .message-name {
    display: none;
  }
}
.message.message-same-header {
  .message-header {
    display: none;
  }
}
.message.message-same-footer {
  .message-footer {
    display: none;
  }
}
.message-appear-from-bottom {
  animation: message-appear-from-bottom 300ms;
}
.message-appear-from-top {
  animation: message-appear-from-top 300ms;
}
.message-typing-indicator {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  > div {
    display: inline-block;
    position: relative;
    background: var(--f7-message-typing-indicator-bg-color);
    opacity: var(--f7-message-typing-indicator-opacity);
    vertical-align: middle;
    border-radius: 50%;
  }
}
@keyframes message-appear-from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes message-appear-from-top {
  from {
    transform: translate3d(0, -100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.if-ios-theme({
  @import './messages-ios.less';
});
.if-md-theme({
  @import './messages-md.less';
});
.if-aurora-theme({
  @import './messages-aurora.less';
});
