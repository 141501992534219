:root {
  --f7-toggle-handle-color: #fff;
  .dark-vars({
    --f7-toggle-inactive-color: #555;

  });
}
.ios-vars({
  --f7-toggle-width: 52px;
  --f7-toggle-height: 32px;
  .light-vars({
    --f7-toggle-border-color: #e5e5e5;
    --f7-toggle-inactive-color: #fff;
  });
  .dark-vars({
    --f7-toggle-border-color: #555;
  });
});
.md-vars({
  --f7-toggle-width: 36px;
  --f7-toggle-height: 14px;
  .light-vars({
    --f7-toggle-inactive-color: #b0afaf;
  });
});
.aurora-vars({
  --f7-toggle-width: 40px;
  --f7-toggle-height: 20px;
  .light-vars({
    --f7-toggle-inactive-color: #aaa;
  });
});
