/* === Infinite === */
.infinite-scroll-preloader {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  &.preloader {
    display: block;
  }
}
.if-ios-theme({
  @import './infinite-scroll-ios.less';
});
.if-md-theme({
  @import './infinite-scroll-md.less';
});
.if-aurora-theme({
  @import './infinite-scroll-aurora.less';
});
