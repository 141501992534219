.md {
  .toast {
    transition-duration: 200ms;
    border-radius: var(--f7-toast-border-radius);
    width: calc(100% - 16px);
    transform: scale(0.9);
    left: 8px;
    &.modal-in {
      transform: scale(1);
    }
    &.modal-out {
      transform: scale(1);
    }
    &.toast-top {
      top: 8px;
    }
    &.toast-center {
      left: 50%;
      width: auto;
      transform: scale(0.9) translate3d(-55%, -55%, 0);
      &.modal-in {
        transform: scale(1) translate3d(-50%, -50%, 0);
      }
      &.modal-out {
        transform: scale(1) translate3d(-50%, -50%, 0);
      }
    }
    &.toast-bottom {
      bottom: calc(8px + var(--f7-safe-area-bottom));
    }
    // 568 + 16
    @media (min-width: 584px) {
      left: 50%;
      margin-left: calc(-1 * var(--f7-toast-max-width) / 2);
      &.toast-center {
        margin-left: 0;
      }
    }
    @media (min-width: 1024px) {
      margin-left: 0;
      width: auto;
      &.toast-horizontal-left {
        left: 24px;
      }
      &.toast-horizontal-right {
        left: auto;
        right: 24px;
      }
      &.toast-horizontal-center {
        left: 50%;
        width: var(--f7-toast-max-width);
        margin-left: calc(-1 * var(--f7-toast-max-width) / 2);
      }
      &.toast-bottom {
        bottom: calc(24px + var(--f7-safe-area-bottom));
      }
      &.toast-top {
        top: 24px;
      }
    }
  }
  .toast-button {
    .ltr({
      margin-left: 16px;
      margin-right: -8px;
    });
    .rtl({
      margin-right: 16px;
      margin-left: -8px;
    });
  }
}
