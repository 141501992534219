:root {
  --f7-tooltip-bg-color: rgba(0, 0, 0, 0.87);
  --f7-tooltip-text-color: #fff;
  --f7-tooltip-border-radius: 4px;
  --f7-tooltip-padding: 8px 16px;
  --f7-tooltip-font-size: 14px;
  --f7-tooltip-font-weight: 500;
  --f7-tooltip-desktop-padding: 6px 8px;
  --f7-tooltip-desktop-font-size: 12px;
}
