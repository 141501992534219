:root {
  --f7-notification-max-width: 568px;
  --f7-notification-subtitle-text-transform: none;
  --f7-notification-subtitle-line-height: 1.35;
  --f7-notification-text-text-transform: none;
  --f7-notification-text-font-weight: 400;
  .dark-vars({
    --f7-notification-subtitle-color: #fff;
  });
}
.ios-vars({
  --f7-notification-margin: 8px;
  --f7-notification-padding: 10px;
  --f7-notification-border-radius: 12px;
  --f7-notification-box-shadow: 0px 5px 25px -10px rgba(0, 0, 0, 0.7);
  --f7-notification-icon-size: 20px;

  --f7-notification-title-font-size: 13px;
  --f7-notification-title-text-transform: uppercase;
  --f7-notification-title-line-height: 1.4;
  --f7-notification-title-font-weight: 400;
  --f7-notification-title-letter-spacing: 0.02em;

  --f7-notification-title-right-font-size: 13px;

  --f7-notification-subtitle-font-size: 15px;
  --f7-notification-subtitle-font-weight: 600;

  --f7-notification-text-font-size: 15px;
  --f7-notification-text-line-height: 1.2;
  .light-vars({
    --f7-notification-bg-color: rgba(250,250,250,0.95);
    --f7-notification-bg-color-rgb: 255, 255, 255;
    --f7-notification-title-color: #000;
    --f7-notification-title-right-color: rgba(0,0,0,0.45);
    --f7-notification-subtitle-color: #000;
    --f7-notification-text-color: #000;
  });
  .dark-vars({
    --f7-notification-bg-color: rgba(30,30,30,0.95);
    --f7-notification-bg-color-rgb: 30, 30, 30;
    --f7-notification-title-color: #fff;
    --f7-notification-text-color: #fff;
    --f7-notification-title-right-color: rgba(255,255,255,0.55);
  });
});
.md-vars({
  --f7-notification-margin: 0px;
  --f7-notification-padding: 16px;
  --f7-notification-border-radius: 0px;
  --f7-notification-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22), 0 1px 2px rgba(0, 0, 0, 0.24);
  --f7-notification-icon-size: 16px;

  --f7-notification-title-color: var(--f7-theme-color);
  --f7-notification-title-font-size: 12px;
  --f7-notification-title-text-transform: none;
  --f7-notification-title-line-height: 1;
  --f7-notification-title-font-weight: 400;

  --f7-notification-title-right-font-size: 12px;

  --f7-notification-subtitle-font-size: 14px;
  --f7-notification-subtitle-font-weight: 400;

  --f7-notification-text-font-size: 14px;
  --f7-notification-text-line-height: 1.35;
  .light-vars({
    --f7-notification-bg-color: #fff;
    --f7-notification-title-right-color: rgba(0,0,0,0.54);
    --f7-notification-subtitle-color: #212121;
    --f7-notification-text-color: rgba(0,0,0,0.54);
  });
  .dark-vars({
    --f7-notification-bg-color: #242424;
    --f7-notification-title-right-color: rgba(255,255,255,0.54);
    --f7-notification-text-color: rgba(255,255,255,0.54);
  });
});
.aurora-vars({
  --f7-notification-margin: 16px;
  --f7-notification-padding: 16px;
  --f7-notification-border-radius: 8px;
  --f7-notification-box-shadow: 0px 5px 25px -10px rgba(0, 0, 0, 0.7);
  --f7-notification-icon-size: 24px;

  --f7-notification-title-font-size: 16px;
  --f7-notification-title-text-transform: none;
  --f7-notification-title-line-height: 1.4;
  --f7-notification-title-font-weight: 600;
  --f7-notification-title-letter-spacing: 0.02em;

  --f7-notification-title-right-font-size: 14px;

  --f7-notification-subtitle-font-size: 14px;
  --f7-notification-subtitle-font-weight: 600;

  --f7-notification-text-font-size: 14px;
  --f7-notification-text-line-height: 1.35;
  .light-vars({
    --f7-notification-bg-color: #fff;
    --f7-notification-title-color: #000;
    --f7-notification-title-right-color: rgba(0,0,0,0.6);
    --f7-notification-subtitle-color: #000;
    --f7-notification-text-color: #000;
  });
  .dark-vars({
    --f7-notification-bg-color: #242424;
    --f7-notification-title-color: #fff;
    --f7-notification-text-color: #fff;
    --f7-notification-title-right-color: rgba(255,255,255,0.54);
  });
});
