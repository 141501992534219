.md {
  .contacts-list {
    .list-group-title {
      pointer-events: none;
      overflow: visible;
      width: 56px;
      + li {
        margin-top: calc(var(--f7-contacts-list-title-height) * -1);
      }
    }
    li:not(.list-group-title) {
      .ltr({
        padding-left: 56px;
      });
      .rtl({
        padding-right: 56px;
      });
    }
  }
}
