.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_signin_disp_footer {
  padding-top: 50px;
}

.float-right {
  float: right;
}

.boldtext {
  font-weight: bold !important;
}

.large-item-title {
  font-size: 1.3em !important;
}

.overflowbreak {
  white-space: initial !important;
  word-wrap: break-word !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.backgroundsvg {
  display: block;
  margin: auto;
  width: 300px;
  height: 250px;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checklist-item-changed {
  border: 5px solid #4cd964;
}

.noclick-overlay {
  height: 0px;
  overflow: visible;
  pointer-events: none;
  background: none !important;
}

.sortable-checkbox-container {
  height: 100%;
}

.sortable-checkbox {
  float: right;
  margin-top: -60px;
  padding-right: 10px;
}

/* Fixes f7 menu dropdowns being semi transparent and making it hard to see the items */
.menu-item-dropdown,
.menu-item-content,
.menu-dropdown-content,
.menu-dropdown-link {
  background-color: black;
}

@media print {

  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  .page,
  .page-content,
  html,
  body,
  .framework7-root,
  .views,
  .view {
    height: auto !important;
  }

  html,
  body,
  .framework7-root,
  .views,
  .view {
    overflow: visible !important;
    overflow-x: visible !important;
  }

  .page.page-previous,
  .searchbar,
  .left,
  .panel-left,
  .noclick-overlay,
  .right,
  .appbar,
  .fab,
  .notification,
  .button,
  .subnavbar,
  .hideforprint {
    display: none !important;
  }

  .view-main {
    margin-left: 0px !important;
  }

  .browserprintwarning {
    display: block !important;
  }

  .data-table {
    transform: scale(0.8);
    overflow: visible !important;
    margin-left: -7%;
  }

  .printpage {
    page-break-inside: avoid;
  }

  .printpagebreak {
    page-break-after: always;
  }

  .printpagebreakbefore {
    page-break-before: always !important;
  }
}

.hide {
  display: none !important;
}

.browserprintwarning {
  display: none;
}

.attendance-table {
  width: 100%;
}

.attendance-table td,
.attendance-table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
}

.attendance-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.attendance-table tr:hover {
  background-color: #ddd;
}

/* ==== Audio Player ==== */
div.control-container {
  margin-top: 10px;
  padding-bottom: 10px;
}

div.control-container div.amplitude-play-pause {
  width: 74px;
  height: 74px;
  cursor: pointer;
  float: left;
  margin-left: 10px;
}

div.control-container div.amplitude-play-pause.amplitude-paused {
  background: url("https://521dimensions.com/img/open-source/amplitudejs/examples/single-song/play.svg");
  background-size: cover;
}

div.control-container div.amplitude-play-pause.amplitude-playing {
  background: url("https://521dimensions.com/img/open-source/amplitudejs/examples/single-song/pause.svg");
  background-size: cover;
}

div.control-container div.meta-container {
  float: left;
  width: calc(100% - 84px);
  text-align: center;
  color: white;
  margin-top: 10px;
}

div.control-container div.meta-container span[data-amplitude-song-info="name"] {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: #fff;
  display: block;
}

div.control-container div.meta-container span[data-amplitude-song-info="artist"] {
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  font-size: 14px;
  color: #fff;
  display: block;
}

div.control-container:after {
  content: "";
  display: table;
  clear: both;
}

/*
  Small only
*/
@media screen and (max-width: 39.9375em) {
  div.control-container div.amplitude-play-pause {
    background-size: cover;
    width: 64px;
    height: 64px;
  }

  div.control-container div.meta-container {
    width: calc(100% - 74px);
  }
}

/*
  Medium only
*/
/*
  Large Only
*/
div.time-container {
  opacity: 0.5;
  font-family: 'Open Sans';
  font-weight: 100;
  font-size: 12px;
  color: #fff;
  height: 15px;
}

div.time-container span.current-time {
  float: left;
  margin-left: 5px;
}

div.time-container span.duration {
  float: right;
  margin-right: 5px;
}

/*
  Small only
*/
/*
  Medium only
*/
/*
  Large Only
*/
progress.amplitude-song-played-progress {
  background-color: #313252;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 15px;
  display: block;
  cursor: pointer;
  border: none;
}

progress.amplitude-song-played-progress:not([value]) {
  background-color: #313252;
}

progress[value]::-webkit-progress-bar {
  background-color: #313252;
}

progress[value]::-moz-progress-bar {
  background-color: #00a0ff;
}

progress[value]::-webkit-progress-value {
  background-color: #00a0ff;
}

/*
  Small only
*/
/*
  Medium only
*/
/*
  Large Only
*/
/*
  3. Layout
*/
div.bottom-container {
  background-color: #202136;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/*
  Small only
*/
/*
  Medium only
*/
/*
  Large Only
*/
div#single-song-player {
  border-radius: 10px;
  margin: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: 50px;
  width: 100%;
  max-width: 460px;
  -webkit-font-smoothing: antialiased;
}

div#single-song-player img[data-amplitude-song-info="cover_art_url"] {
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

a.learn-more {
  display: block;
  width: 300px;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  color: white;
  text-decoration: none;
  background-color: #202136;
  font-family: "Lato", sans-serif;
  padding: 20px;
  font-weight: 100;
}

/* ==== End Audio Player ==== */

.label-beta {
  font-size: 0.6em;
  vertical-align: super;
}