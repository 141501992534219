:root {
  --f7-autocomplete-dropdown-placeholder-color: #a9a9a9;
  --f7-autocomplete-dropdown-preloader-size: 20px;
  --f7-autocomplete-dropdown-font-size: var(--f7-list-font-size);
  /*
  --f7-autocomplete-dropdown-selected-bg-color: rgba(var(--f7-theme-color-rgb), 0.2);
  */
  .light-vars({
    --f7-autocomplete-dropdown-bg-color: #fff;
  });
  .dark-vars({
    --f7-autocomplete-dropdown-bg-color: #1c1c1d;
  });
}
.ios-vars({
  --f7-autocomplete-dropdown-box-shadow: 0px 3px 3px rgba(0,0,0,0.2);
  --f7-autocomplete-dropdown-text-matching-font-weight: 600;
  .light-vars({
    --f7-autocomplete-dropdown-text-color: #000;
    --f7-autocomplete-dropdown-text-matching-color: #000;
  });
  .dark-vars({
    --f7-autocomplete-dropdown-text-color: #fff;
    --f7-autocomplete-dropdown-text-matching-color: #fff;
  });
});
.md-vars({
  --f7-autocomplete-dropdown-box-shadow: 0 2px 2px rgba(0,0,0,0.25);
  --f7-autocomplete-dropdown-text-matching-font-weight: 400;
  .light-vars({
    --f7-autocomplete-dropdown-text-color: rgba(0,0,0,0.54);
    --f7-autocomplete-dropdown-text-matching-color: #212121;
  });
  .dark-vars({
    --f7-autocomplete-dropdown-text-color: rgba(255,255,255,0.54);
    --f7-autocomplete-dropdown-text-matching-color: rgba(255,255,255,0.87);
  });
});
.aurora-vars({
  --f7-autocomplete-dropdown-box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
  --f7-autocomplete-dropdown-text-matching-font-weight: 700;
  .light-vars({
    --f7-autocomplete-dropdown-text-color: #000;
    --f7-autocomplete-dropdown-text-matching-color: #000;
  });
  .dark-vars({
    --f7-autocomplete-dropdown-text-color: #fff;
    --f7-autocomplete-dropdown-text-matching-color: #fff;
  });
});
