:root {
  --f7-toast-text-color: #fff;
  --f7-toast-font-size: 14px;
  --f7-toast-icon-size: 48px;
  --f7-toast-max-width: 568px;
}
.ios-vars({
  --f7-toast-bg-color: rgba(0,0,0,0.75);
  --f7-toast-bg-color-rgb: 0, 0, 0;
  --f7-toast-padding-horizontal: 16px;
  --f7-toast-padding-vertical: 12px;
  --f7-toast-border-radius: 8px;
  --f7-toast-button-min-width: 64px;
});
.md-vars({
  --f7-toast-bg-color: #323232;
  --f7-toast-padding-horizontal: 24px;
  --f7-toast-padding-vertical: 14px;
  --f7-toast-border-radius: 4px;
  --f7-toast-button-min-width: 64px;
});
.aurora-vars({
  --f7-toast-bg-color: #323232;
  --f7-toast-padding-horizontal: 16px;
  --f7-toast-padding-vertical: 16px;
  --f7-toast-border-radius: 8px;
  --f7-toast-button-min-width: 32px;
});
