/* === Color Picker === */
@import './color-picker-vars.less';
.color-picker {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  &.color-picker-inline,
  .color-picker-popover &,
  .color-picker-popup & {
    position: relative;
  }
}
.color-picker-sheet-modal {
  background: var(--f7-color-picker-sheet-bg-color);
  &:before {
    z-index: 600;
  }
  .sheet-modal-inner {
    margin-bottom: var(--f7-safe-area-bottom);
  }
}
.color-picker-popup {
  .page {
    background: var(--f7-color-picker-popup-bg-color);
  }
  .page-content {
    padding-bottom: var(--f7-safe-area-bottom);
  }
}
.color-picker-popover,
.color-picker-popup,
.color-picker-page {
  .color-picker {
    height: 100%;
    .toolbar {
      position: absolute;
    }
  }
}
.color-picker-popover {
  width: var(--f7-color-picker-popover-width);
  max-width: 90vw;
  max-height: 80vh;
  .color-picker {
    max-height: 80vh;
  }
  .toolbar-top {
    border-top-left-radius: var(--f7-popover-border-radius);
    border-top-right-radius: var(--f7-popover-border-radius);
  }
  .color-picker-module-palette {
    overflow: hidden;
    flex-shrink: 0;
  }
  .color-picker-module-palette:first-child {
    border-top-left-radius: var(--f7-popover-border-radius);
    border-top-right-radius: var(--f7-popover-border-radius);
  }
  .color-picker-module-palette:last-child {
    border-bottom-left-radius: var(--f7-popover-border-radius);
    border-bottom-right-radius: var(--f7-popover-border-radius);
  }
  .color-picker-module-palette:first-child:last-child {
    border-radius: var(--f7-popover-border-radius);
  }
  .toolbar ~ .page-content .color-picker-module-palette:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.color-picker-popup .page-content,
.color-picker-popover .page-content,
.color-picker-sheet-modal .page-content,
.color-picker-page .page-content {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  overflow-x: hidden;
}

.color-picker-module {
  margin-top: 5px;
  &:last-child {
    margin-bottom: 5px;
  }
}
// Spectrum
.color-picker-module-sb-spectrum,
.color-picker-module-hs-spectrum {
  margin-left: 10px;
  margin-right: 10px;
  &:first-child {
    margin-top: 10px;
  }
  .color-picker-sb-spectrum,
  .color-picker-hs-spectrum {
    border-radius: 4px;
    height: var(--f7-color-picker-sb-spectrum-height);
  }
}
.color-picker-sb-spectrum {
  background-color: #000;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000 100%),
    linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #fff 100%);
  position: relative;
}
.color-picker-hs-spectrum {
  position: relative;
  background-image: linear-gradient(
    to right,
    hsl(0, 100%, 50%),
    hsl(60, 100%, 50%),
    hsl(120, 100%, 50%),
    hsl(180, 100%, 50%),
    hsl(240, 100%, 50%),
    hsl(300, 100%, 50%),
    hsl(360, 100%, 50%)
  );
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
}
.color-picker-sb-spectrum-handle,
.color-picker-hs-spectrum-handle {
  width: 4px;
  height: 4px;
  position: absolute;
  left: -2px;
  top: -2px;
  z-index: 1;
  &:after {
    background-color: inherit;
    content: '';
    position: absolute;
    width: var(--f7-color-picker-sb-spectrum-handle-size);
    height: var(--f7-color-picker-sb-spectrum-handle-size);
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 150ms;
    transition-property: transform;
    transform-origin: center;
  }
  &.color-picker-sb-spectrum-handle-pressed:after,
  &.color-picker-hs-spectrum-handle-pressed:after {
    transform: scale(1.5) translate(-33.333%, -33.333%);
  }
}
// Wheel
.color-picker-module-wheel {
  margin-left: 10px;
  margin-right: 10px;
}
.color-picker-wheel {
  position: relative;
  width: var(--f7-color-picker-wheel-width);
  max-width: 100%;
  height: auto;
  font-size: 0;
  margin-left: auto;
  margin-right: auto;
  svg {
    width: 100%;
    height: auto;
  }
  .color-picker-wheel-handle {
    width: (100% / 6);
    height: (100% / 6);
    position: absolute;
    box-sizing: border-box;
    border: 2px solid #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    background: red;
    border-radius: 50%;
    left: 0;
    top: 0;
  }
  .color-picker-sb-spectrum {
    width: 45%;
    height: 45%;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
  }
}
// Slider
.color-picker-slider-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  + .color-picker-slider-wrap {
    margin-top: 5px;
  }
}
.color-picker-hex-wrap {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.color-picker-slider-label,
.color-picker-hex-label {
  font-size: var(--f7-color-picker-label-font-size);
  width: var(--f7-color-picker-label-size);
  flex-shrink: 0;
  .ltr({
    margin-right: 12px;
  });
  .rtl({
    margin-left: 12px;
  });
}
.color-picker-hex-label {
  width: auto;
}
.color-picker-bar-value,
.color-picker-slider-value,
.color-picker-hex-value {
  width: var(--f7-color-picker-value-width);
  height: var(--f7-color-picker-value-height);
  background: var(--f7-color-picker-value-bg-color);
  border-radius: var(--f7-color-picker-value-border-radius);
  text-align: center;
  font-size: var(--f7-color-picker-value-font-size);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  .ltr({
    margin-left: 10px;
  });
  .rtl({
    margin-right: 10px;
  });
  input {
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    outline: 0;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-align: center;
    display: block;
    border-radius: 4px;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
      display: none;
    }
  }
}

.color-picker-hex-value {
  width: var(--f7-color-picker-hex-value-width);
  &:first-child {
    .ltr({
      margin-left: auto;
    });
    .rtl({
      margin-right: auto;
    });
  }
}
.color-picker-slider {
  --f7-range-bar-active-bg-color: transparent;
  --f7-range-bar-size: var(--f7-color-picker-slider-size);
  --f7-range-bar-border-radius: var(--f7-color-picker-slider-size);
  --f7-range-knob-size: var(--f7-color-picker-slider-knob-size);
  --f7-range-knob-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  .range-knob {
    transition-duration: 200ms;
    transition-property: transform;
    &:after {
      width: 30px;
      height: 30px;
      margin-left: -16px;
      margin-top: -16px;
    }
  }
  .range-knob-active-state .range-knob {
    transform: scale(1.5);
  }
}
.md .color-picker-slider.range-slider-min:not(.range-slider-dual) .range-knob {
  background: var(--f7-range-knob-color) !important;
  border: none;
}

// Bars
.color-picker-module-rgb-bars {
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
  align-items: stretch;
  height: 100%;
  min-height: var(--f7-color-picker-bar-min-height);
  padding-bottom: 10px;
  padding-top: 10px;
  box-sizing: border-box;
}
.color-picker-bar-wrap {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}
.color-picker-bar {
  --f7-range-bar-active-bg-color: transparent;
  --f7-range-bar-size: var(--f7-color-picker-bar-size);
  --f7-range-bar-border-radius: 2px;
  --f7-range-knob-size: 6px;
  --f7-range-knob-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
  --f7-range-knob-color: #fff;
  .range-knob {
    transition-duration: 0ms;
    transition-property: transform;
    border-radius: 3px;
  }
  .range-knob-wrap {
    height: 6px;
    width: calc(var(--f7-color-picker-bar-size) - 4px);
    margin-left: calc(-0.5 * (var(--f7-color-picker-bar-size) - 4px));
  }
  .range-knob-active-state .range-knob {
    transform: scale(1);
  }
}
.md .color-picker-bar.range-slider-min:not(.range-slider-dual) .range-knob {
  background: var(--f7-range-knob-color) !important;
  border: none;
}

.color-picker-bar-label {
  font-size: var(--f7-color-picker-label-size);
  margin-top: 12px;
  line-height: 1;
  height: var(--f7-color-picker-label-height);
  flex-shrink: 0;
}
.color-picker-bar-value {
  margin-left: 0;
  margin-bottom: 10px;
}

// Specific Sliders
.color-picker-slider-alpha {
  --f7-range-knob-color: #fff;
  .range-bar {
    .ltr({
      background-image: linear-gradient(to right, rgba(0,0,0,0), #000),
        linear-gradient(to right, rgba(255,255,255,0.2) 50%, rgba(0,0,0,0.2) 50%),
        linear-gradient(to left, rgba(255,255,255,0.2) 50%, rgba(0,0,0,0.2) 50%);
    });
    .rtl({
      background-image: linear-gradient(to left, rgba(0,0,0,0), #000),
        linear-gradient(to right, rgba(255,255,255,0.2) 50%, rgba(0,0,0,0.2) 50%),
        linear-gradient(to left, rgba(255,255,255,0.2) 50%, rgba(0,0,0,0.2) 50%);
    });

    background-size: 100% 100%, 6px 3px, 6px 3px;
    background-position: 0 0, 0px 3px, 0 0;
    background-repeat: repeat-y, repeat-x, repeat-x;
  }
}
.color-picker-slider-hue {
  .range-bar {
    .ltr({
      background-image: linear-gradient(to right,
        hsl(0, 100%, 50%),
        hsl(60, 100%, 50%),
        hsl(120, 100%, 50%),
        hsl(180, 100%, 50%),
        hsl(240, 100%, 50%),
        hsl(300, 100%, 50%),
        hsl(360, 100%, 50%)
      );
    });
    .rtl({
      background-image: linear-gradient(to left,
        hsl(0, 100%, 50%),
        hsl(60, 100%, 50%),
        hsl(120, 100%, 50%),
        hsl(180, 100%, 50%),
        hsl(240, 100%, 50%),
        hsl(300, 100%, 50%),
        hsl(360, 100%, 50%)
      );
    });
  }
}
.color-picker-slider-brightness {
  .range-bar {
    .ltr({
      background-image: linear-gradient(to right, #000, #fff);
    });
    .rtl({
      background-image: linear-gradient(to left, #000, #fff);
    });
  }
}
// Palette
.color-picker-module-palette {
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child:last-child {
    margin: 0;
  }
}
.color-picker-palette {
  display: flex;
  flex-wrap: wrap;
}
.color-picker-palette-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  .color-picker-palette-value {
    width: 100%;
  }
}
.color-picker-palette-value {
  width: var(--f7-color-picker-palette-value-width);
  height: var(--f7-color-picker-palette-value-height);
  cursor: pointer;
}
// Initial-Current Colors
.color-picker-module-initial-current-colors,
.color-picker-module-current-color {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: var(--f7-color-picker-initial-current-color-border-radius);
  overflow: hidden;
  flex-shrink: 0;
}
.color-picker-initial-color,
.color-picker-current-color {
  height: var(--f7-color-picker-initial-current-color-height);
}
.color-picker-initial-current-colors {
  display: flex;
  .color-picker-initial-color,
  .color-picker-current-color {
    width: 50%;
  }
}
// Grouped Modules
.color-picker-module-rgb-sliders,
.color-picker-module-hsb-sliders,
.color-picker-module-alpha-slider,
.color-picker-module-hue-slider,
.color-picker-module-brightness-slider,
.color-picker-module-hex {
  margin-left: 10px;
  margin-right: 10px;
}
.color-picker-grouped-modules {
  .color-picker-module-rgb-sliders,
  .color-picker-module-hsb-sliders,
  .color-picker-module-alpha-slider,
  .color-picker-module-hue-slider,
  .color-picker-module-brightness-slider,
  .color-picker-module-hex {
    background: var(--f7-color-picker-group-bg-color);
    padding: 5px;
    border-radius: 4px;
    margin-top: 16px;
    margin-left: 5px;
    margin-right: 5px;
    &:last-child {
      margin-bottom: 16px;
    }
  }
  .color-picker-slider-value,
  .color-picker-hex-value {
    background: var(--f7-color-picker-group-value-bg-color);
  }
  .color-picker-slider-label,
  .color-picker-hex-label {
    .ltr({
      margin-left: 5px;
    });
    .rtl({
      margin-right: 5px;
    });
  }
  .color-picker-module-rgb-sliders,
  .color-picker-module-hsb-sliders,
  .color-picker-module-alpha-slider,
  .color-picker-module-hue-slider,
  .color-picker-module-brightness-slider {
    .range-slider:first-child {
      .ltr({
        margin-left: 5px;
      });
      .rtl({
        margin-right: 5px;
      });
    }
    .range-slider:last-child {
      .ltr({
        margin-right: 5px;
      });
      .rtl({
        margin-left: 5px;
      });
    }
  }
}

.if-ios-theme({
  @import './color-picker-ios.less';
});
.if-md-theme({
  @import './color-picker-md.less';
});
.if-aurora-theme({
  @import './color-picker-aurora.less';
});
