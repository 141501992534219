.aurora {
  .data-table {
    th,
    td {
      &.actions-cell {
        a.link + a.link {
          .ltr({ margin-left: 8px; });
          .rtl({ margin-right: 8px; });
        }
      }
    }
  }
  .sortable-cell:not(.numeric-cell):after {
    .ltr({ margin-left: 8px; });
    .rtl({ margin-right: 8px; });
  }
  .sortable-cell.numeric-cell:before {
    .ltr({ margin-right: 8px; });
    .rtl({ margin-left: 8px; });
  }
  .data-table-links,
  .data-table-actions {
    a.link + a.link,
    .button + .button {
      .ltr({ margin-left: 16px; });
      .rtl({ margin-right: 16px; });
    }
  }

  // Footer
  .data-table-rows-select,
  .data-table-pagination {
    a.link {
      width: 48px;
      height: 48px;
    }
  }
  .data-table-rows-select {
    + .data-table-pagination {
      .ltr({
        margin-left: 16px;
      });
      .rtl({
        margin-right: 16px;
      });
    }
    .input {
      .ltr({
        margin-left: 16px;
      });
      .rtl({
        margin-right: 16px;
      });
    }
  }
  .data-table-pagination-label {
    .ltr({
      margin-right: 16px;
    });
    .rtl({
      margin-left: 16px;
    });
  }
}
