@import './button-vars.less';

button {
  appearance: none;
  width: 100%;
}
.button {
  --f7-touch-ripple-color: rgba(var(--f7-theme-color-rgb), 0.25);
  text-decoration: none;
  text-align: center;
  display: flex;
  appearance: none;
  background: none;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  overflow: hidden;
  font-family: inherit;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  border: var(--f7-button-border-width, 0px) solid
    var(--f7-button-border-color, var(--f7-theme-color));
  font-size: var(--f7-button-font-size);
  color: var(--f7-button-text-color, var(--f7-theme-color));
  height: var(--f7-button-height);
  line-height: calc(var(--f7-button-height) - var(--f7-button-border-width, 0) * 2);
  padding: var(--f7-button-padding-vertical, 0px) var(--f7-button-padding-horizontal);
  border-radius: var(--f7-button-border-radius);
  min-width: var(--f7-button-min-width);
  font-weight: var(--f7-button-font-weight);
  letter-spacing: var(--f7-button-letter-spacing);
  text-transform: var(--f7-button-text-transform);
  background-color: var(--f7-button-bg-color);
  box-shadow: var(--f7-button-box-shadow);
  &.active-state {
    background-color: var(--f7-button-pressed-bg-color, rgba(var(--f7-theme-color-rgb), 0.15));
    color: var(--f7-button-pressed-text-color, var(--f7-button-text-color, var(--f7-theme-color)));
  }
  input[type='submit']&,
  input[type='button']&,
  input[type='reset']& {
    width: 100%;
  }

  > i + span,
  > span + span,
  > span + i,
  > i + i {
    .ltr({
      margin-left: 4px;
    });
    .rtl({
      margin-right: 4px;
    });
  }
}
.subnavbar .button,
.navbar .button,
.toolbar .button,
.searchbar .button,
.appbar .button {
  color: var(--f7-button-text-color, var(--f7-theme-color));
}

.button-round,
.ios .button-round-ios,
.md .button-round-md,
.aurora .button-round-aurora {
  --f7-button-border-radius: var(--f7-button-height);
}
.button-fill,
.ios .button-fill-ios,
.md .button-fill-md,
.aurora .button-fill-aurora,
.button-active,
.button.tab-link-active {
  --f7-button-bg-color: var(--f7-button-fill-bg-color, var(--f7-theme-color));
  --f7-button-text-color: var(--f7-button-fill-text-color, #fff);
  --f7-touch-ripple-color: var(--f7-touch-ripple-white);
}
.button-fill,
.ios .button-fill-ios,
.md .button-fill-md,
.aurora .button-fill-aurora {
  --f7-button-pressed-bg-color: var(--f7-button-fill-pressed-bg-color);
}
.button-active,
.button.tab-link-active {
  --f7-button-pressed-bg-color: var(--f7-button-bg-color);
}

.button-outline,
.ios .button-outline-ios,
.md .button-outline-md,
.aurora .button-outline-aurora {
  --f7-button-border-color: var(--f7-button-outline-border-color, var(--f7-theme-color));
  --f7-button-border-width: var(--f7-button-outline-border-width);
}

.button-large,
.ios .button-large-ios,
.md .button-large-md,
.aurora .button-large-aurora {
  --f7-button-height: var(--f7-button-large-height);
  --f7-button-font-size: var(--f7-button-large-font-size);
  --f7-button-font-weight: var(--f7-button-large-font-weight);
  --f7-button-text-transform: var(--f7-button-large-text-transform);
}
.button-small,
.ios .button-small-ios,
.md .button-small-md,
.aurora .button-small-aurora {
  --f7-button-outline-border-width: var(--f7-button-small-outline-border-width);
  --f7-button-height: var(--f7-button-small-height);
  --f7-button-font-size: var(--f7-button-small-font-size);
  --f7-button-font-weight: var(--f7-button-small-font-weight);
  --f7-button-text-transform: var(--f7-button-small-text-transform);
}
.ios .button-small.button-fill,
.ios .button-small-ios.button-fill,
.ios .button-small.button-fill-ios {
  --f7-button-border-width: var(--f7-button-small-outline-border-width);
  --f7-button-pressed-text-color: var(--f7-theme-color);
  --f7-button-pressed-bg-color: transparent;
}

.segmented {
  align-self: center;
  display: flex;
  flex-wrap: nowrap;
  border-radius: var(--f7-button-border-radius);
  box-shadow: var(--f7-button-box-shadow);
  box-sizing: border-box;
  .button,
  button {
    width: 100%;
    flex-shrink: 1;
    min-width: 0;
    border-radius: 0;
  }
  .ltr({
    .button:first-child {
      border-radius: var(--f7-button-border-radius) 0 0 var(--f7-button-border-radius);
    }
    .button:not(.button-outline):first-child {
      border-left: none;
    }
    .button.button-outline {
      &:nth-child(n + 2) {
        border-left: none;
      }
    }
    .button:last-child {
      border-radius: 0 var(--f7-button-border-radius) var(--f7-button-border-radius) 0;
    }
    .button-round:first-child {
      border-radius: var(--f7-button-height) 0 0 var(--f7-button-height);
    }
    .button-round:last-child {
      border-radius: 0 var(--f7-button-height) var(--f7-button-height) 0;
    }
  });
  .rtl({
    .button:first-child {
      border-radius: 0 var(--f7-button-border-radius) var(--f7-button-border-radius) 0;
    }
    .button:not(.button-outline):first-child {
      border-right: none;
    }
    .button.button-outline {
      &:nth-child(n + 2) {
        border-right: none;
      }
    }
    .button:last-child {
      border-radius: var(--f7-button-border-radius) 0 0 var(--f7-button-border-radius);
    }
    .button-round:first-child {
      border-radius: 0 var(--f7-button-height) var(--f7-button-height) 0;
    }
    .button-round:last-child {
      border-radius: var(--f7-button-height) 0 0 var(--f7-button-height);
    }
  });
  .button:first-child:last-child {
    border-radius: var(--f7-button-border-radius);
  }
}

.segmented-raised,
.ios .segmented-raised-ios,
.md .segmented-raised-md,
.aurora .segmented-raised-aurora {
  box-shadow: var(--f7-button-raised-box-shadow);
  .button:not(.button-outline) {
    .ltr({
      border-left: 1px solid var(--f7-segmented-raised-divider-color);
    });
    .rtl({
      border-right: 1px solid var(--f7-segmented-raised-divider-color);
    });
  }
}

// Raised
.button-raised,
.ios .button-raised-ios,
.md .button-raised-md,
.aurora .button-raised-aurora {
  --f7-button-box-shadow: var(--f7-button-raised-box-shadow);
  &.active-state {
    --f7-button-box-shadow: var(--f7-button-raised-pressed-box-shadow);
  }
}

// Strong
.segmented-strong,
.ios .segmented-strong-ios,
.md .segmented-strong-md,
.aurora .segmented-strong-aurora {
  --f7-button-bg-color: transparent;
  --f7-button-hover-bg-color: var(--f7-segmented-strong-button-hover-bg-color);
  --f7-button-text-color: var(--f7-segmented-strong-button-text-color);
  --f7-button-font-weight: var(--f7-segmented-strong-button-font-weight);
  --f7-button-text-transform: var(--f7-segmented-strong-button-text-transform);
  --f7-button-pressed-bg-color: var(--f7-segmented-strong-button-pressed-bg-color);
  position: relative;
  background: var(--f7-segmented-strong-bg-color);
  border-radius: calc(var(--f7-button-border-radius) + 2px);
  box-shadow: none;
  padding: var(--f7-segmented-strong-padding);
  overflow: hidden;

  .button {
    z-index: 1;
    transform: translate3d(0, 0, 0);
  }
  .button,
  .button:first-child,
  .button:last-child {
    border-radius: var(--f7-button-border-radius);
  }
  .button + .button {
    .ltr({
      margin-left: var(--f7-segmented-strong-between-buttons);
    });
    .rtl({
      margin-right: var(--f7-segmented-strong-between-buttons);
    });
  }
  .button.button-active,
  .button.tab-link-active {
    --f7-button-hover-bg-color: transparent;
    --f7-button-bg-color: transparent;
    --f7-button-text-color: var(--f7-segmented-strong-button-active-text-color);
    --f7-button-font-weight: var(--f7-segmented-strong-button-active-font-weight);
  }

  .segmented-highlight {
    --f7-segmented-highlight-num: 1;
    --f7-segmented-highlight-active: 0;
    --f7-segmented-highlight-padding: calc(var(--f7-segmented-strong-padding) * 2);
    --f7-segmented-highlight-between: var(--f7-segmented-strong-between-buttons);
    --f7-segmented-highlight-width: calc(
      (
          100% - var(--f7-segmented-highlight-padding) - var(--f7-segmented-highlight-between) *
            (var(--f7-segmented-highlight-num) - 1)
        ) / var(--f7-segmented-highlight-num)
    );

    position: absolute;
    background-color: var(--f7-segmented-strong-button-active-bg-color);
    border-radius: var(--f7-button-border-radius);
    box-shadow: var(--f7-segmented-strong-button-active-box-shadow);
    left: var(--f7-segmented-strong-padding);
    top: var(--f7-segmented-strong-padding);
    height: calc(100% - var(--f7-segmented-strong-padding) * 2);
    width: var(--f7-segmented-highlight-width);
    z-index: 0;
    transform: translateX(
      calc(
        var(--f7-segmented-highlight-active) * 100% + var(--f7-segmented-highlight-active) *
          var(--f7-segmented-highlight-between)
      )
    );
    transition: 200ms;
  }
}

.segmented-highlight {
  .button:nth-child(2) ~ & {
    --f7-segmented-highlight-num: 2;
  }
  .button:nth-child(3) ~ & {
    --f7-segmented-highlight-num: 3;
  }
  .button:nth-child(4) ~ & {
    --f7-segmented-highlight-num: 4;
  }
  .button:nth-child(5) ~ & {
    --f7-segmented-highlight-num: 5;
  }
  .button:nth-child(6) ~ & {
    --f7-segmented-highlight-num: 6;
  }
  .button:nth-child(7) ~ & {
    --f7-segmented-highlight-num: 7;
  }
  .button:nth-child(8) ~ & {
    --f7-segmented-highlight-num: 8;
  }
  .button:nth-child(9) ~ & {
    --f7-segmented-highlight-num: 9;
  }
  .button:nth-child(10) ~ & {
    --f7-segmented-highlight-num: 10;
  }

  .button-active:nth-child(2) ~ &,
  .tab-link-active:nth-child(2) ~ & {
    --f7-segmented-highlight-active: 1;
  }
  .button-active:nth-child(3) ~ &,
  .tab-link-active:nth-child(3) ~ & {
    --f7-segmented-highlight-active: 2;
  }
  .button-active:nth-child(4) ~ &,
  .tab-link-active:nth-child(4) ~ & {
    --f7-segmented-highlight-active: 3;
  }
  .button-active:nth-child(5) ~ &,
  .tab-link-active:nth-child(5) ~ & {
    --f7-segmented-highlight-active: 4;
  }
  .button-active:nth-child(6) ~ &,
  .tab-link-active:nth-child(6) ~ & {
    --f7-segmented-highlight-active: 5;
  }
  .button-active:nth-child(7) ~ &,
  .tab-link-active:nth-child(7) ~ & {
    --f7-segmented-highlight-active: 6;
  }
  .button-active:nth-child(8) ~ &,
  .tab-link-active:nth-child(8) ~ & {
    --f7-segmented-highlight-active: 7;
  }
  .button-active:nth-child(9) ~ &,
  .tab-link-active:nth-child(9) ~ & {
    --f7-segmented-highlight-active: 8;
  }
  .button-active:nth-child(10) ~ &,
  .tab-link-active:nth-child(10) ~ & {
    --f7-segmented-highlight-active: 9;
  }
}

// Round
.segmented-round,
.ios .segmented-round-ios,
.md .segmented-round-md,
.aurora .segmented-round-aurora {
  border-radius: var(--f7-button-height);
  .segmented-highlight {
    border-radius: var(--f7-button-height);
  }
}

.subnavbar .segmented {
  width: 100%;
}

.button-preloader {
  position: relative;
  --f7-preloader-color: var(--f7-button-text-color, var(--f7-theme-color));
  &:not(.button-large) {
    --f7-preloader-size: calc(var(--f7-button-height) - 6px);
  }
  &.button-fill {
    --f7-preloader-color: #fff;
  }
  & > span:not(.preloader) {
    transition: 0.2s;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    transform: scale(1);
  }
  & > .preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transition: 0.2s;
    transition-property: opacity;
    opacity: 0;
    visibility: hidden;
    margin-left: calc(-1 * var(--f7-preloader-size) / 2);
    margin-top: calc(-1 * var(--f7-preloader-size) / 2);
  }

  &.button-loading > span:not(.preloader) {
    opacity: 0;
    transform: scale(0);
  }
  &.button-loading > .preloader {
    opacity: 1;
    visibility: visible;
  }
}

.if-ios-theme({
  @import './button-ios.less';
});
.if-md-theme({
  @import './button-md.less';
});
.if-aurora-theme({
  @import './button-aurora.less';
});
