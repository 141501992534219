:root {
  --f7-color-picker-popover-width: 350px;

  --f7-color-picker-slider-size: 6px;
  --f7-color-picker-slider-knob-size: 16px;
  --f7-color-picker-bar-size: 50px;
  --f7-color-picker-bar-min-height: 260px;
  --f7-color-picker-value-width: 64px;
  --f7-color-picker-value-height: 32px;
  --f7-color-picker-value-font-size: 16px;
  --f7-color-picker-value-border-radius: 4px;
  --f7-color-picker-hex-value-width: 84px;
  --f7-color-picker-label-font-size: 14px;
  --f7-color-picker-label-width: 10px;
  --f7-color-picker-label-height: 14px;
  --f7-color-picker-sb-spectrum-height: 260px;
  --f7-color-picker-sb-spectrum-handle-size: 16px;
  --f7-color-picker-wheel-width: 330px;

  --f7-color-picker-palette-value-width: 36px;
  --f7-color-picker-palette-value-height: 36px;

  --f7-color-picker-initial-current-color-height: 40px;
  --f7-color-picker-initial-current-color-border-radius: 4px;
  .light-vars({
    --f7-color-picker-sheet-bg-color: #fff;
    --f7-color-picker-popup-bg-color: #fff;
    --f7-color-picker-value-bg-color: rgba(0,0,0,0.05);
    --f7-color-picker-group-bg-color: rgba(0,0,0,0.05);
    --f7-color-picker-group-value-bg-color: #fff;
  });
  .dark-vars({
    --f7-color-picker-sheet-bg-color: #121212;
    --f7-color-picker-popup-bg-color: #121212;
    --f7-color-picker-value-bg-color: rgba(255,255,255,0.1);
    --f7-color-picker-group-bg-color: #000;
    --f7-color-picker-group-value-bg-color: rgba(255,255,255,0.12);
  });
}
