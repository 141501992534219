:root {
  /*
  --f7-appbar-offset: var(--f7-appbar-height);
  --f7-appbar-extra-offset: 0px;
  --f7-appbar-bg-color: var(--f7-bars-bg-color);
  --f7-appbar-bg-color-rgb: var(--f7-bars-bg-color-rgb);
  --f7-appbar-bg-image: var(--f7-bars-bg-image);
  --f7-appbar-border-color: var(--f7-bars-border-color);
  --f7-appbar-link-color: var(--f7-bars-link-color);
  --f7-appbar-text-color: var(--f7-bars-text-color);
  */
  --f7-appbar-shadow-image: none;
}
.ios-vars({
  --f7-appbar-height: 44px;
  --f7-appbar-inner-padding-left: 8px;
  --f7-appbar-inner-padding-right: 8px;
});
.md-vars({
  --f7-appbar-height: 48px;
  --f7-appbar-inner-padding-left: 16px;
  --f7-appbar-inner-padding-right: 16px;
});
.aurora-vars({
  --f7-appbar-height: 64px;
  --f7-appbar-inner-padding-left: 16px;
  --f7-appbar-inner-padding-right: 16px;
});
