:root {
  --f7-list-inset-side-margin: 16px;
  --f7-list-item-cell-margin: 16px;
  --f7-list-item-padding-horizontal: 16px;
  --f7-list-item-padding-vertical: 8px;
  --f7-list-media-item-padding-horizontal: 16px;
  --f7-list-item-text-max-lines: 2;
  --f7-list-chevron-icon-font-size: 20px;
  --f7-list-item-media-margin: 16px;

  --f7-list-item-title-font-size: inherit;
  --f7-list-item-title-font-weight: 400;
  --f7-list-item-title-text-color: inherit;
  --f7-list-item-title-line-height: inherit;
  --f7-list-item-title-white-space: nowrap;

  --f7-list-item-subtitle-font-weight: 400;
  --f7-list-item-subtitle-text-color: inherit;
  --f7-list-item-subtitle-line-height: inherit;

  --f7-list-item-text-font-weight: 400;
  --f7-list-item-after-font-weight: 400;
  --f7-list-item-after-line-height: inherit;

  --f7-list-item-header-text-color: inherit;
  --f7-list-item-header-font-size: 12px;
  --f7-list-item-header-font-weight: 400;
  --f7-list-item-header-line-height: 1.2;

  --f7-list-item-footer-font-size: 12px;
  --f7-list-item-footer-font-weight: 400;
  --f7-list-item-footer-line-height: 1.2;

  /*
  --f7-list-button-text-color: var(--f7-theme-color);
  --f7-list-button-pressed-bg-color: rgba(var(--f7-theme-color-rgb), .15);
  */
  --f7-list-button-font-size: inherit;
  --f7-list-button-font-weight: 400;
  --f7-list-button-text-align: center;

  --f7-list-item-divider-line-height: inherit;
  --f7-list-item-divider-font-weight: 400;
  --f7-list-group-title-line-height: inherit;

  --f7-menu-list-offset: 8px;
  --f7-menu-list-border-radius: 8px;
  --f7-menu-list-font-size: 14px;
  --f7-menu-list-item-title-font-size: 14px;
  --f7-menu-list-item-title-font-weight: 500;
  --f7-menu-list-item-subtitle-font-size: 14px;
  --f7-menu-list-item-text-font-size: 14px;
  --f7-menu-list-item-after-font-size: 14px;

  .light-vars({
    --f7-list-bg-color: #fff;
    --f7-list-chevron-icon-color: rgba(0, 0, 0, 0.2);
    --f7-menu-list-selected-text-color: var(--f7-theme-color);
    --f7-menu-list-selected-bg-color: rgba(var(--f7-theme-color-rgb), 0.15);
  });
  .dark-vars({
    --f7-list-button-border-color: rgba(255, 255, 255, 0.15);
    --f7-list-bg-color: #1c1c1d;
    --f7-list-border-color: rgba(255, 255, 255, 0.15);
    --f7-list-item-border-color: rgba(255, 255, 255, 0.15);
    --f7-list-item-divider-border-color: rgba(255, 255, 255, 0.15);
    --f7-list-item-divider-bg-color: #232323;
    --f7-list-group-title-bg-color: #232323;
    --f7-list-chevron-icon-color: rgba(255, 255, 255, 0.3);
    --f7-menu-list-selected-text-color: inherit;
    --f7-menu-list-selected-bg-color: var(--f7-theme-color);
  });
}
.ios-vars({
  --f7-list-in-list-padding-left: 30px;
  --f7-list-inset-border-radius: 8px;
  --f7-list-margin-vertical: 35px;
  --f7-list-font-size: 17px;
  --f7-list-chevron-icon-area: 20px;
  --f7-list-border-color: rgba(0,0,0,0.22);
  --f7-list-item-border-color: rgba(0,0,0,0.22);
  --f7-list-link-pressed-bg-color: rgba(0,0,0,0.15);

  --f7-list-item-subtitle-font-size: 15px;

  --f7-list-item-text-font-size: 15px;
  --f7-list-item-text-line-height: 21px;

  --f7-list-item-after-font-size: inherit;
  --f7-list-item-after-padding: 5px;

  --f7-list-item-min-height: 44px;
  --f7-list-item-media-icons-margin: 5px;
  --f7-list-media-item-padding-vertical: 10px;
  --f7-list-media-item-title-font-weight: 600;
  --f7-list-button-border-color: rgba(0,0,0,0.22);

  --f7-list-item-divider-height: 31px;
  --f7-list-item-divider-font-size: inherit;
  --f7-list-item-divider-bg-color: rgba(0,0,0,0.03);
  --f7-list-item-divider-border-color: rgba(0,0,0,0.22);

  --f7-list-group-title-height: 31px;
  --f7-list-group-title-font-size: inherit;
  --f7-list-group-title-font-weight: 400;
  --f7-list-group-title-bg-color: #f7f7f7;
  .light-vars({
    --f7-list-item-after-text-color: rgba(0,0,0,0.45);
    --f7-list-item-footer-text-color: rgba(0,0,0,0.45);
    --f7-list-item-text-text-color: rgba(0,0,0,0.45);
    --f7-list-item-divider-text-color: rgba(0,0,0,0.45);
    --f7-list-group-title-text-color: rgba(0,0,0,0.45);
  });
  .dark-vars({
    --f7-list-item-after-text-color: rgba(255,255,255,0.55);
    --f7-list-item-header-text-color: rgba(255,255,255,0.55);
    --f7-list-item-footer-text-color: rgba(255,255,255,0.55);
    --f7-list-item-text-text-color: rgba(255,255,255,0.55);
    --f7-list-item-divider-text-color: rgba(255,255,255,0.55);
    --f7-list-group-title-text-color: rgba(255,255,255,0.55);
    --f7-list-link-pressed-bg-color: rgba(255, 255, 255, 0.08);
  });
});
.md-vars({
  --f7-list-in-list-padding-left: 40px;
  --f7-list-inset-border-radius: 4px;
  --f7-list-margin-vertical: 32px;
  --f7-list-font-size: 16px;
  --f7-list-chevron-icon-area: 26px;
  --f7-list-border-color: rgba(0,0,0,0.12);
  --f7-list-item-border-color: rgba(0,0,0,0.12);

  --f7-list-item-subtitle-font-size: 14px;

  --f7-list-item-text-font-size: 14px;
  --f7-list-item-text-line-height: 20px;

  --f7-list-item-after-font-size: 14px;
  --f7-list-item-after-padding: 8px;


  --f7-list-item-min-height: 48px;
  --f7-list-item-media-icons-margin: 8px;
  --f7-list-media-item-padding-vertical: 14px;
  /*
  --f7-list-media-item-title-font-weight: var(--f7-list-item-title-font-weight);
  */
  --f7-list-button-border-color: transparent;

  --f7-list-item-divider-height: 48px;
  --f7-list-item-divider-font-size: 14px;
  --f7-list-item-divider-bg-color: #f4f4f4;
  --f7-list-item-divider-border-color: transparent;

  --f7-list-group-title-height: 48px;
  --f7-list-group-title-font-size: 14px;
  --f7-list-group-title-font-weight: 400;
  --f7-list-group-title-bg-color: #f4f4f4;
  --f7-menu-list-border-radius: 4px;
  .light-vars({
    --f7-list-link-pressed-bg-color: rgba(0,0,0,0.1);
    --f7-list-item-text-text-color: rgba(0,0,0,0.54);
    --f7-list-item-after-text-color: rgba(0,0,0,0.54);
    --f7-list-item-footer-text-color: rgba(0,0,0,0.5);
    --f7-list-item-divider-text-color: rgba(0,0,0,0.54);
    --f7-list-group-title-text-color: rgba(0,0,0,0.54);
  });
  .dark-vars({
    --f7-list-item-divider-text-color: #fff;
    --f7-list-group-title-text-color: #fff;
    --f7-list-link-pressed-bg-color: rgba(255,255,255,0.05);
    --f7-list-item-text-text-color: rgba(255,255,255,0.54);
    --f7-list-item-after-text-color: rgba(255,255,255,0.54);
    --f7-list-item-footer-text-color: rgba(255,255,255,0.54);
  });
});
.aurora-vars({
  --f7-list-in-list-padding-left: 16px;
  --f7-list-inset-border-radius: 8px;
  --f7-list-margin-vertical: 32px;
  --f7-list-font-size: 16px;
  --f7-list-chevron-icon-area: 20px;
  --f7-list-border-color: rgba(0,0,0,0.12);
  --f7-list-item-border-color: rgba(0,0,0,0.12);

  --f7-list-item-subtitle-font-size: 14px;

  --f7-list-item-text-font-size: 14px;
  --f7-list-item-text-line-height: 20px;

  --f7-list-item-after-font-size: 14px;
  --f7-list-item-after-padding: 8px;


  --f7-list-item-min-height: 48px;
  --f7-list-item-media-icons-margin: 8px;
  --f7-list-media-item-padding-vertical: 16px;
  --f7-list-media-item-title-font-weight: 600;

  --f7-list-button-border-color: rgba(0,0,0,0.12);
  --f7-list-button-font-weight: 500;

  --f7-list-item-divider-height: 32px;
  --f7-list-item-divider-font-size: 14px;
  --f7-list-item-divider-bg-color: rgba(0,0,0,0.03);
  --f7-list-item-divider-border-color: transparent;

  --f7-list-group-title-height: 32px;
  --f7-list-group-title-font-size: 14px;
  --f7-list-group-title-font-weight: 500;
  --f7-list-group-title-bg-color: #f7f7f7;

  .light-vars({
    --f7-list-link-pressed-bg-color: rgba(0,0,0,0.1);
    --f7-list-link-hover-bg-color: rgba(0,0,0,0.03);
    --f7-list-item-text-text-color: rgba(0,0,0,0.6);
    --f7-list-item-after-text-color: rgba(0,0,0,0.5);
    --f7-list-item-footer-text-color: rgba(0,0,0,0.6);
    --f7-list-button-hover-bg-color: rgba(0,0,0,0.03);
    --f7-list-item-divider-text-color: rgba(0,0,0,0.6);
    --f7-list-group-title-text-color: rgba(0,0,0,0.6);
  });
  .dark-vars({
    --f7-list-item-text-text-color: rgba(255,255,255,0.54);
    --f7-list-item-after-text-color: rgba(255,255,255,0.54);
    --f7-list-item-footer-text-color: rgba(255,255,255,0.54);
    --f7-list-item-divider-text-color: rgba(255,255,255,0.6);
    --f7-list-group-title-text-color: rgba(255,255,255,0.6);
    --f7-list-link-pressed-bg-color: rgba(255,255,255,0.05);
    --f7-list-link-hover-bg-color: rgba(255,255,255,0.03);
    --f7-list-button-hover-bg-color: rgba(255,255,255,0.03);
  });
});
