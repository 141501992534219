:root {
  --f7-badge-text-color: #fff;
  --f7-badge-bg-color: #8e8e93;
  --f7-badge-padding: 0 4px;
  --f7-badge-in-icon-size: 16px;
  --f7-badge-in-icon-font-size: 10px;
  --f7-badge-font-weight: normal;
  --f7-badge-font-size: 12px;
}
.ios-vars({
  --f7-badge-size: 20px;
  --f7-badge-font-weight: 600;
});
.md-vars({
  --f7-badge-size: 18px;
  --f7-badge-font-weight: 500;
});
.aurora-vars({
  --f7-badge-size: 18px;
  --f7-badge-font-weight: 600;
});
