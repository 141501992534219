/* === Indexed List === */
@import './list-index-vars.less';

.list-index {
  position: absolute;
  top: 0;
  bottom: 0;
  text-align: center;
  z-index: 10;
  width: var(--f7-list-index-width);
  cursor: pointer;
  user-select: none;
  .ltr({
    right: var(--f7-safe-area-right);
  });
  .rtl({
    left: var(--f7-safe-area-left);
  });
  &:before {
    content: '';
    position: absolute;
    width: 20px;
    top: 0;
    right: 100%;
    height: 100%;
  }
  ul {
    color: var(--f7-list-index-text-color, var(--f7-theme-color));
    font-size: var(--f7-list-index-font-size);
    font-weight: var(--f7-list-index-font-weight);
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    position: relative;
  }
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    height: var(--f7-list-index-item-height);
    line-height: var(--f7-list-index-item-height);
    flex-shrink: 0;
    display: block;
    width: 100%;
  }
  .list-index-skip-placeholder {
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      width: var(--f7-list-index-skip-dot-size);
      height: var(--f7-list-index-skip-dot-size);
      margin-left: calc(-1 * var(--f7-list-index-skip-dot-size) / 2);
      margin-top: calc(-1 * var(--f7-list-index-skip-dot-size) / 2);
      background: var(--f7-list-index-text-color, var(--f7-theme-color));
    }
  }
  .list-index-label {
    position: absolute;
    bottom: 0;
    right: 100%;
    text-align: center;
    background-color: var(--f7-list-index-label-bg-color, var(--f7-theme-color));
    color: var(--f7-list-index-label-text-color);
    width: var(--f7-list-index-label-size);
    height: var(--f7-list-index-label-size);
    line-height: var(--f7-list-index-label-size);
    font-size: var(--f7-list-index-label-font-size);
    font-weight: var(--f7-list-index-label-font-weight);
  }
}
.navbar ~ .page > .list-index,
.navbars ~ .page > .list-index,
.navbar ~ .list-index {
  top: var(--f7-navbar-height);
}

.navbar ~ .toolbar-top,
.navbars ~ .toolbar-top,
.ios .navbar ~ .toolbar-top-ios,
.ios .navbars ~ .toolbar-top-ios .md .navbar ~ .toolbar-top-md {
  ~ .list-index {
    top: calc(var(--f7-navbar-height) + var(--f7-toolbar-height));
  }
  &.tabbar-labels {
    ~ .list-index {
      top: calc(var(--f7-navbar-height) + var(--f7-tabbar-labels-height));
    }
  }
}

.navbar ~ .subnavbar ~ .list-index,
.navbars ~ .subnavbar ~ .list-index,
.page-with-subnavbar .navbar ~ .list-index {
  top: calc(var(--f7-navbar-height) + var(--f7-subnavbar-height));
}

.toolbar-bottom,
.ios .toolbar-bottom-ios,
.md .toolbar-bottom-md {
  ~ .page > .list-index,
  ~ * .page > .list-index,
  ~ .list-index {
    bottom: calc(var(--f7-toolbar-height) + var(--f7-safe-area-bottom));
  }
  &.tabbar-labels {
    ~ .page > .list-index,
    ~ * .page > .list-index,
    ~ .list-index {
      bottom: calc(var(--f7-tabbar-labels-height) + var(--f7-safe-area-bottom));
    }
  }
}

.if-ios-theme({
  @import './list-index-ios.less';
});
.if-md-theme({
  @import './list-index-md.less';
});
.if-aurora-theme({
  @import './list-index-aurora.less';
});
