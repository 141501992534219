:root {
  --f7-swipeout-button-text-color: #fff;
  --f7-swipeout-delete-button-bg-color: #ff3b30;
  --f7-swipeout-button-font-size: inherit;
  --f7-swipeout-button-font-weight: inherit;
  --f7-swipeout-button-padding-vertical: 0px;
  .light-vars({
    --f7-swipeout-button-bg-color: rgba(0,0,0,0.22);
  });
  .dark-vars({
    --f7-swipeout-button-bg-color: rgba(255,255,255,0.55);
  });
}
.ios-vars({
  --f7-swipeout-button-padding-horizontal: 30px;
});
.md-vars({
  --f7-swipeout-button-padding-horizontal: 24px;
});
.aurora-vars({
  --f7-swipeout-button-padding-horizontal: 24px;
  --f7-swipeout-button-font-weight: 500;
});
