:root {
  /* --f7-checkbox-active-color: var(--f7-theme-color); */
  --f7-checkbox-icon-color: #fff;
  --f7-checkbox-extra-margin: 0px;
  .dark-vars({
    --f7-checkbox-inactive-color: rgba(255, 255, 255, 0.3);
    --f7-checkbox-icon-color: #000;
  });
}
.ios-vars({
  --f7-checkbox-size: 22px;
  --f7-checkbox-border-radius: 50%;
  --f7-checkbox-border-width: 1px;
  .light-vars({
    --f7-checkbox-inactive-color: #c7c7cc;
  });
});
.md-vars({
  --f7-checkbox-size: 18px;
  --f7-checkbox-border-radius: 2px;
  --f7-checkbox-border-width: 2px;
  .light-vars({
    --f7-checkbox-inactive-color: #6d6d6d;
  });
});
.aurora-vars({
  --f7-checkbox-size: 16px;
  --f7-checkbox-border-radius: 4px;
  --f7-checkbox-border-width: 1px;
  .light-vars({
    --f7-checkbox-inactive-color: rgba(0,0,0,0.25);
  });
});
