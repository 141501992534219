.pie-chart {
  position: relative;
  svg {
    display: block;
    width: 100%;
    font-size: 0;
    height: auto;
    margin: 0 auto;
  }
  path {
    transition-duration: 150ms;
  }
  &-hidden {
    opacity: 0.4;
  }
  &-tooltip {
    pointer-events: none;
    text-align: left;
    font-size: 12px;
    line-height: 1.4;
    white-space: nowrap;
    width: auto;
    max-width: none;
    &-label {
      display: flex;
      align-items: center;
    }
    &-color {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
}

.if-ios-theme({
  @import './pie-chart-ios.less';
});
.if-md-theme({
  @import './pie-chart-md.less';
});
.if-aurora-theme({
  @import './pie-chart-aurora.less';
});
