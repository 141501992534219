/* === Autocomplete === */
@import './autocomplete-vars.less';

.autocomplete-page {
  .autocomplete-found {
    display: block;
  }
  .autocomplete-not-found {
    display: none;
  }
  .autocomplete-values {
    display: block;
  }
  .list ul:empty {
    display: none;
  }
}
.autocomplete-preloader:not(.autocomplete-preloader-visible) {
  visibility: hidden;
  &,
  * {
    animation: none;
  }
}
.autocomplete-dropdown {
  background: var(--f7-autocomplete-dropdown-bg-color);
  box-shadow: var(--f7-autocomplete-dropdown-box-shadow);
  box-sizing: border-box;
  position: absolute;
  z-index: 500;
  width: 100%;
  .ltr({ left: 0; });
  .rtl({ right: 0; });
  .autocomplete-dropdown-inner {
    position: relative;
    .scrollable();
    height: 100%;
    z-index: 1;
  }
  .autocomplete-preloader {
    display: none;
    position: absolute;
    bottom: 100%;
    width: var(--f7-autocomplete-dropdown-preloader-size);
    height: var(--f7-autocomplete-dropdown-preloader-size);
  }
  .autocomplete-preloader-visible {
    display: block;
  }
  .autocomplete-dropdown-placeholder {
    color: var(--f7-autocomplete-dropdown-placeholder-color);
  }
  .list {
    margin: 0;
    color: var(--f7-autocomplete-dropdown-text-color);
    font-size: var(--f7-autocomplete-dropdown-font-size);
    b {
      color: var(--f7-autocomplete-dropdown-text-matching-color);
      font-weight: var(--f7-autocomplete-dropdown-text-matching-font-weight);
    }
    ul {
      .hairline-remove-top-bottom();
      background: none !important;
    }
  }
  .autocomplete-dropdown-selected {
    background: var(
      --f7-autocomplete-dropdown-selected-bg-color,
      rgba(var(--f7-theme-color-rgb), 0.2)
    );
  }
}
.searchbar-input-wrap .autocomplete-dropdown {
  background-color: var(--f7-searchbar-input-bg-color, var(--f7-searchbar-bg-color));
  border-radius: var(--f7-searchbar-input-border-radius);
  .autocomplete-dropdown-placeholder {
    color: var(--f7-searchbar-placeholder-color);
  }
  li:last-child {
    border-radius: 0 0 var(--f7-searchbar-input-border-radius)
      var(--f7-searchbar-input-border-radius);
    position: relative;
    overflow: hidden;
  }
  .item-content {
    .ltr({ padding-left: calc(var(--f7-searchbar-input-padding-horizontal) + var(--f7-searchbar-input-extra-padding-left, 0px)); });
    .rtl({ padding-right: calc(var(--f7-searchbar-input-padding-horizontal) + var(--f7-searchbar-input-extra-padding-left, 0px)); });
  }
}

.list .item-content-dropdown-expanded {
  .item-title.item-label {
    width: 0;
    flex-shrink: 10;
    overflow: hidden;
    + .item-input-wrap {
      .ltr({ margin-left: 0; });
      .rtl({ margin-right: 0; });
    }
  }
  .item-input-wrap {
    width: 100%;
  }
}

.if-ios-theme({
  @import './autocomplete-ios.less';
});
.if-md-theme({
  @import './autocomplete-md.less';
});
.if-aurora-theme({
  @import './autocomplete-aurora.less';
});
