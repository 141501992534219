.md {
  .icon-checkbox,
  .checkbox i {
    transition-duration: 200ms;
    &:after {
      content: 'checkbox_md';
      transition-duration: 200ms;
      font-size: 15px;
    }
  }

  label.item-checkbox {
    position: relative;
    overflow: hidden;
    z-index: 0;
  }
}
