:root {
  /*
  --f7-toolbar-bg-color: var(--f7-bars-bg-color);
  --f7-toolbar-bg-color-rgb: var(--f7-bars-bg-color-rgb);
  --f7-toolbar-bg-image: var(--f7-bars-bg-image);
  --f7-toolbar-border-color: var(--f7-bars-border-color);
  --f7-toolbar-link-color: var(--f7-bars-link-color);
  --f7-toolbar-text-color: var(--f7-bars-text-color);
  --f7-tabbar-link-active-color: var(--f7-theme-color);
  */
  --f7-tabbar-link-active-bg-color: transparent;
  --f7-tabbar-label-text-transform: none;
  --f7-toolbar-hide-show-transition-duration: 400ms;
}
.ios-vars({
  --f7-toolbar-height: 44px;
  --f7-toolbar-font-size: 17px;
  --f7-toolbar-inner-padding-left: 8px;
  --f7-toolbar-inner-padding-right: 8px;
  /*
  --f7-toolbar-link-height: var(--f7-toolbar-height);
  --f7-toolbar-link-line-height: var(--f7-toolbar-height);
  */
  --f7-tabbar-labels-height: 50px;
  --f7-tabbar-labels-tablet-height: 50px;
  --f7-toolbar-top-shadow-image: none;
  --f7-toolbar-bottom-shadow-image: none;
  --f7-tabbar-icon-size: 28px;
  --f7-tabbar-link-text-transform: none;
  --f7-tabbar-link-font-weight: 400;
  --f7-tabbar-link-letter-spacing: 0;

  --f7-tabbar-label-font-size: 12px;
  --f7-tabbar-label-tablet-font-size: 14px;
  --f7-tabbar-label-font-weight: 500;
  --f7-tabbar-label-letter-spacing: 0.01;
  .light-vars({
    --f7-tabbar-link-inactive-color: rgba(0,0,0,0.4);
  });
  .dark-vars({
    --f7-tabbar-link-inactive-color: rgba(255,255,255,0.54);
  });

});
.md-vars({
  --f7-toolbar-height: 48px;
  --f7-toolbar-font-size: 14px;
  --f7-toolbar-inner-padding-left: 0px;
  --f7-toolbar-inner-padding-right: 0px;
  /*
  --f7-toolbar-link-height: var(--f7-toolbar-height);
  --f7-toolbar-link-line-height: var(--f7-toolbar-height);
  */
  --f7-tabbar-labels-height: 56px;
  --f7-tabbar-labels-tablet-height: 56px;
  /*
  --f7-tabbar-link-active-border-color: var(--f7-theme-color);
  */
  --f7-toolbar-top-shadow-image: var(--f7-bars-shadow-bottom-image);
  --f7-toolbar-bottom-shadow-image: var(--f7-bars-shadow-top-image);
  --f7-tabbar-icon-size: 24px;
  --f7-tabbar-link-text-transform: uppercase;
  --f7-tabbar-link-font-weight: 500;
  --f7-tabbar-link-letter-spacing: 0.05em;

  --f7-tabbar-label-font-size: 14px;
  --f7-tabbar-label-tablet-font-size: 14px;
  --f7-tabbar-label-font-weight: 400;
  --f7-tabbar-label-letter-spacing: 0;
  .light-vars({
    --f7-tabbar-link-inactive-color: rgba(0,0,0,0.54);
  });
  .dark-vars({
    --f7-tabbar-link-inactive-color: rgba(255,255,255,0.54);
  });
});
.aurora-vars({
  --f7-toolbar-height: 48px;
  --f7-toolbar-font-size: 16px;
  --f7-toolbar-inner-padding-left: 16px;
  --f7-toolbar-inner-padding-right: 16px;
  --f7-toolbar-link-height: auto;
  --f7-toolbar-link-line-height: inherit;
  --f7-tabbar-labels-height: 52px;
  --f7-tabbar-labels-tablet-height: 52px;
  /*
  --f7-tabbar-link-active-border-color: var(--f7-theme-color);
  */
  --f7-toolbar-top-shadow-image: none;
  --f7-toolbar-bottom-shadow-image: none;
  --f7-tabbar-icon-size: 24px;
  --f7-tabbar-link-text-transform: none;
  --f7-tabbar-link-font-weight: 500;
  --f7-tabbar-link-letter-spacing: 0;

  --f7-tabbar-label-font-size: 14px;
  --f7-tabbar-label-tablet-font-size: 14px;
  --f7-tabbar-label-font-weight: 500;
  --f7-tabbar-label-letter-spacing: 0;
  .light-vars({
    --f7-tabbar-link-inactive-color: rgba(0,0,0,0.5);
  });
  .dark-vars({
    --f7-tabbar-link-inactive-color: rgba(255,255,255,0.5);
  });
});
