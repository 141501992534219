:root {
  --f7-preloader-modal-padding: 8px;
  --f7-preloader-modal-bg-color: rgba(0, 0, 0, 0.8);
}
.ios-vars({
  --f7-preloader-color: #6c6c6c;
  --f7-preloader-size: 20px;
  --f7-preloader-modal-preloader-size: 34px;
  --f7-preloader-modal-border-radius: 5px;
});
.md-vars({
  --f7-preloader-color: #757575;
  --f7-preloader-size: 32px;
  --f7-preloader-modal-preloader-size: 32px;
  --f7-preloader-modal-border-radius: 4px;
});
.aurora-vars({
  --f7-preloader-color: #757575;
  --f7-preloader-size: 24px;
  --f7-preloader-modal-preloader-size: 24px;
  --f7-preloader-modal-border-radius: 4px;
});
