// f7-flip
.router-transition-f7-flip-forward,
.router-transition-f7-flip-backward {
  background: #000;
  perspective: 1200px;
  .page {
    backface-visibility: hidden;
  }
}
.router-transition-f7-flip-forward {
  .page-next {
    animation: f7-flip-next-to-current var(--f7-page-flip-transition-duration) forwards;
  }
  .page-current {
    animation: f7-flip-current-to-prev var(--f7-page-flip-transition-duration) forwards;
  }
}
.router-transition-f7-flip-backward {
  .page-current {
    animation: f7-flip-current-to-next var(--f7-page-flip-transition-duration) forwards;
  }
  .page-previous {
    animation: f7-flip-prev-to-current var(--f7-page-flip-transition-duration) forwards;
  }
}
@keyframes f7-flip-next-to-current {
  from {
    border-radius: 30px;
    .ltr({
      transform: translateZ(-100vmax) rotateY(180deg);
    });
    .rtl({
      transform: translateZ(-100vmax) rotateY(-180deg);
    });
  }
  to {
    border-radius: 0;
    transform: translateZ(0px) rotateY(0deg);
  }
}
@keyframes f7-flip-current-to-next {
  from {
    border-radius: 0px;
    transform: translateZ(0px) rotateY(0deg);
  }
  to {
    border-radius: 30px;
    .ltr({
      transform: translateZ(-100vmax) rotateY(180deg);
    });
    .rtl({
      transform: translateZ(-100vmax) rotateY(-180deg);
    });
  }
}
@keyframes f7-flip-current-to-prev {
  from {
    border-radius: 0px;
    transform: translateZ(0px) rotateY(0deg);
  }
  to {
    border-radius: 30px;
    .ltr({
      transform: translateZ(-100vmax) rotateY(-180deg);
    });
    .rtl({
      transform: translateZ(-100vmax) rotateY(180deg);
    });
  }
}
@keyframes f7-flip-prev-to-current {
  from {
    border-radius: 30px;
    .ltr({
      transform: translateZ(-100vmax) rotateY(-180deg);
    });
    .rtl({
      transform: translateZ(-100vmax) rotateY(180deg);
    });
  }
  to {
    border-radius: 0px;
    transform: translateZ(0px) rotateY(0deg);
  }
}
