/* === Range Slider === */
@import './range-vars.less';

.range-slider {
  display: block;
  position: relative;
  align-self: center;
  cursor: pointer;
  user-select: none;
  input[type='range'] {
    display: none;
  }
  &.range-slider-horizontal {
    width: 100%;
    height: var(--f7-range-size);
    touch-action: pan-y;
  }
  &.range-slider-vertical {
    height: 100%;
    width: var(--f7-range-size);
    touch-action: pan-x;
  }
}
.range-bar {
  position: absolute;
  overflow: hidden;
  background: var(--f7-range-bar-bg-color);
  border-radius: var(--f7-range-bar-border-radius);

  .range-slider-vertical & {
    left: 50%;
    top: 0;
    height: 100%;
    width: var(--f7-range-bar-size);
    margin-left: calc(-1 * var(--f7-range-bar-size) / 2);
  }
  .range-slider-horizontal & {
    left: 0;
    top: 50%;
    width: 100%;
    height: var(--f7-range-bar-size);
    margin-top: calc(-1 * var(--f7-range-bar-size) / 2);
  }
}
.range-bar-active {
  position: absolute;
  background: var(--f7-range-bar-active-bg-color, var(--f7-theme-color));
  .range-slider-horizontal & {
    .ltr({ left: 0; });
    .rtl({ right: 0; });
    top: 0;
    height: 100%;
  }
  .range-slider-vertical & {
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .range-slider-vertical-reversed & {
    top: 0;
    bottom: auto;
  }
}
.range-knob-wrap {
  z-index: 20;
  position: absolute;
  height: var(--f7-range-knob-size);
  width: var(--f7-range-knob-size);

  .range-slider-horizontal & {
    top: 50%;
    margin-top: calc(-1 * var(--f7-range-knob-size) / 2);
    .ltr({
      margin-left: calc(-1 * var(--f7-range-knob-size) / 2);
      left: 0;
    });
    .rtl({
      margin-right: calc(-1 * var(--f7-range-knob-size) / 2);
      right: 0;
    });
  }
  .range-slider-vertical & {
    left: 50%;
    margin-left: calc(-1 * var(--f7-range-knob-size) / 2);
    bottom: 0;
    margin-bottom: calc(-1 * var(--f7-range-knob-size) / 2);
  }
  .range-slider-vertical-reversed & {
    bottom: auto;
    top: 0;
    margin-bottom: 0;
    margin-top: calc(-1 * var(--f7-range-knob-size) / 2);
  }
}
.range-knob {
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: var(--f7-range-knob-color, var(--f7-range-knob-bg-color, var(--f7-theme-color)));
  box-shadow: var(--f7-range-knob-box-shadow);
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 44px;
    height: 44px;
    margin-left: -22px;
    margin-top: -22px;
  }
}
.range-knob-label {
  position: absolute;
  left: 50%;
  bottom: 100%;
  text-align: center;
  transition-duration: 120ms;
  transition-property: transform;
  box-sizing: border-box;
  transform: translateY(100%) scale(0);
  height: var(--f7-range-label-size);
  line-height: var(--f7-range-label-size);
  min-width: var(--f7-range-label-size);
  color: var(--f7-range-label-text-color);
  background-color: var(--f7-range-label-bg-color, var(--f7-theme-color));
  font-size: var(--f7-range-label-font-size);
  font-weight: var(--f7-range-label-font-weight);
  border-radius: var(--f7-range-label-border-radius);
  padding: var(--f7-range-label-padding);
}
.range-knob-active-state .range-knob-label {
  transform: translateY(0%) scale(1);
}
.range-scale {
  position: absolute;
  .range-slider-horizontal & {
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: calc(var(--f7-range-bar-size) / 2);
  }
  .range-slider-vertical & {
    right: 50%;
    top: 0;
    height: 100%;
    margin-right: calc(var(--f7-range-bar-size) / 2);
  }
}
.range-scale-step {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  font-size: var(--f7-range-scale-font-size);
  font-weight: var(--f7-range-scale-font-weight);
  color: var(--f7-range-scale-text-color, var(--f7-range-bar-bg-color));
  line-height: 1;
  &:before {
    content: '';
    position: absolute;
    background: var(--f7-range-scale-step-bg-color, var(--f7-range-bar-bg-color));
  }
  .range-slider-horizontal & {
    justify-content: center;
    align-items: flex-start;
    width: var(--f7-range-scale-step-width);
    height: var(--f7-range-scale-step-height);
    padding-top: calc(var(--f7-range-scale-step-height) + var(--f7-range-scale-label-offset));
    top: 0;
    &:before {
      left: 0;
      top: 0;
      width: 100%;
      height: var(--f7-range-scale-step-height);
    }
    .ltr({
      margin-left: calc(-1 * var(--f7-range-scale-step-width) / 2);
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-left: calc(-1 * var(--f7-range-scale-step-width));
      }
    });
    .rtl({
      margin-right: calc(-1 * var(--f7-range-scale-step-width) / 2);
      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-right: calc(-1 * var(--f7-range-scale-step-width));
      }
    });
  }
  .range-slider-vertical & {
    line-height: 1;
    justify-content: flex-end;
    align-items: center;
    height: var(--f7-range-scale-step-width);
    width: var(--f7-range-scale-step-height);
    padding-right: calc(var(--f7-range-scale-step-height) + var(--f7-range-scale-label-offset));
    right: 0;
    margin-bottom: calc(-1 * var(--f7-range-scale-step-width) / 2);
    &:first-child {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: calc(-1 * var(--f7-range-scale-step-width));
    }
    &:before {
      right: 0;
      top: 0;
      height: 100%;
      width: var(--f7-range-scale-step-height);
    }
  }
}
.range-scale-substep {
  --f7-range-scale-step-bg-color: var(
    --f7-range-scale-substep-bg-color,
    var(--f7-range-bar-bg-color)
  );
  --f7-range-scale-step-width: var(--f7-range-scale-substep-width);
  --f7-range-scale-step-height: var(--f7-range-scale-substep-height);
}
.if-ios-theme({
  @import './range-ios.less';
});
.if-md-theme({
  @import './range-md.less';
});
.if-aurora-theme({
  @import './range-aurora.less';
});
