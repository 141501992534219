.md {
  .navbar {
    a.link {
      padding: 0 12px;
      min-width: 48px;
      .link-highlight();
    }
    a.icon-only {
      min-width: 0;
      flex-shrink: 0;
      width: 48px;
    }
    .right {
      .ltr({
        margin-left: auto;
      });
      .rtl({
        margin-right: auto;
      });
    }
    .right:first-child {
      .ltr({
        right: var(--f7-safe-area-right)
      });
      .rtl({
        left: var(--f7-safe-area-left)
      });
    }
  }
  .navbar-inner {
    justify-content: flex-start;
    overflow: hidden;
  }
  .navbar-large:not(.navbar-large-collapsed) .navbar-inner {
    overflow: visible;
  }
  .page.page-with-subnavbar .navbar-inner {
    overflow: visible;
  }
  .navbar-inner-centered-title {
    justify-content: space-between;
    .right {
      .ltr({
        margin-left: 0;
      });
      .rtl({
        margin-right: 0;
      });
    }
    .title {
      text-align: center;
    }
  }
}
