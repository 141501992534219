/* === Actions === */
@import './actions-vars.less';
.actions-backdrop {
}
.actions-modal {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 13500;
  width: 100%;
  transform: translate3d(0, 100%, 0);
  display: none;
  max-height: 100%;
  .scrollable();
  transition-property: transform;
  will-change: transform;
  &.modal-in,
  &.modal-out {
    transition-duration: 300ms;
  }
  &.not-animated {
    transition-duration: 0ms;
  }
  &.modal-in {
    transform: translate3d(0, calc(-1 * var(--f7-safe-area-bottom)), 0);
  }
  &.modal-out {
    z-index: 13500 - 1;
    transform: translate3d(0, 100%, 0);
  }
  @media (min-width: 496px) {
    width: 480px;
    left: 50%;
    margin-left: -240px;
  }
  @media (orientation: landscape) {
    --f7-actions-button-height: var(--f7-actions-button-height-landscape);
  }
}
.actions-group {
  overflow: hidden;
  position: relative;
  margin: var(--f7-actions-group-margin);
  border-radius: var(--f7-actions-border-radius);
  .hairline(bottom, var(--f7-actions-group-border-color));
  transform: translate3d(0, 0, 0);
  &:last-child {
    .hairline-remove(bottom);
  }
}
.actions-button,
.actions-label {
  width: 100%;
  font-weight: normal;
  margin: 0;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: var(--f7-actions-button-text-align);
  background: var(--f7-actions-bg-color);
  .ios-translucent-modals(var(--f7-actions-bg-color-rgb));
  .hairline(bottom, var(--f7-actions-button-border-color));
  &:first-child {
    border-radius: var(--f7-actions-border-radius) var(--f7-actions-border-radius) 0 0;
  }
  &:last-child {
    .hairline-remove(bottom);
    border-radius: 0 0 var(--f7-actions-border-radius) var(--f7-actions-border-radius);
  }
  &:first-child:last-child {
    border-radius: var(--f7-actions-border-radius);
  }
  a {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  b,
  &.actions-button-bold {
    font-weight: 600;
  }
}
.actions-button {
  cursor: pointer;
  display: flex;
  color: var(--f7-actions-button-text-color, var(--f7-theme-color));
  font-size: var(--f7-actions-button-font-size);
  height: var(--f7-actions-button-height);
  line-height: var(--f7-actions-button-height);
  padding: var(--f7-actions-button-padding);
  justify-content: var(--f7-actions-button-justify-content);
  z-index: 10;
  &.active-state {
    background-color: var(--f7-actions-button-pressed-bg-color) !important;
  }
  &[class*='color-'] {
    color: var(--f7-theme-color);
  }
}
.actions-button.active-state {
  .ios-translucent-modals(var(--f7-actions-button-pressed-bg-color-rgb));
}

.actions-button-media {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  i.icon {
    width: var(--f7-actions-button-icon-size);
    height: var(--f7-actions-button-icon-size);
    font-size: var(--f7-actions-button-icon-size);
  }
}
.actions-button a,
.actions-button-text {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.actions-button-text {
  width: 100%;
  flex-shrink: 1;
  text-align: var(--f7-actions-button-text-align);
}
.actions-label {
  line-height: 1.3;
  display: flex;
  align-items: center;
  font-size: var(--f7-actions-label-font-size);
  color: var(--f7-actions-label-text-color);
  padding: var(--f7-actions-label-padding);
  justify-content: var(--f7-actions-label-justify-content);
  min-height: var(--f7-actions-label-min-height, var(--f7-actions-button-height));
}
.actions-label[class*=' color-'] {
  --f7-actions-label-text-color: var(--f7-theme-color);
}
.actions-grid {
  .actions-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 0;
    background: var(--f7-actions-bg-color);
    margin-top: 0;
    &:first-child {
      border-radius: var(--f7-actions-border-radius) var(--f7-actions-border-radius) 0 0;
    }
    &:last-child {
      border-radius: 0 0 var(--f7-actions-border-radius) var(--f7-actions-border-radius);
    }
    &:first-child:last-child {
      border-radius: var(--f7-actions-border-radius);
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
  .actions-button,
  .actions-label {
    border-radius: 0 !important;
    background: none;
  }
  .actions-button {
    width: (100% / 3);
    display: block;
    color: var(--f7-actions-grid-button-text-color);
    height: auto;
    line-height: 1;
    .hairline-remove(bottom);
    padding: 16px;
  }
  .actions-button-media {
    margin-left: auto !important;
    margin-right: auto !important;
    width: var(--f7-actions-grid-button-icon-size);
    height: var(--f7-actions-grid-button-icon-size);
    i.icon {
      width: var(--f7-actions-grid-button-icon-size);
      height: var(--f7-actions-grid-button-icon-size);
      font-size: var(--f7-actions-grid-button-icon-size);
    }
  }
  .actions-button-text {
    margin-left: 0 !important;
    text-align: center !important;
    margin-top: 8px;
    line-height: 1.33em;
    height: 1.33em;
    font-size: var(--f7-actions-grid-button-font-size);
  }
}
.actions-grid .actions-group {
  .ios-translucent-modals(var(--f7-actions-bg-color-rgb));
}
.if-ios-theme({
  @import './actions-ios.less';
});
.if-md-theme({
  @import './actions-md.less';
});
.if-aurora-theme({
  @import './actions-aurora.less';
});
