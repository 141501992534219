/* === Picker === */
@import './picker-vars.less';

.picker {
  width: 100%;
  height: var(--f7-picker-height);
  &.picker-inline {
    height: var(--f7-picker-inline-height);
  }
  .popover & {
    height: var(--f7-picker-popover-height);
  }
  @media (orientation: landscape) and (max-height: 415px) {
    &:not(.picker-inline) {
      height: var(--f7-picker-landscape-height);
    }
  }
  &.sheet-modal {
    background: var(--f7-picker-sheet-bg-color, var(--f7-sheet-bg-color));
  }
}
.picker-popover {
  width: var(--f7-picker-popover-width);
  .toolbar {
    .hairline-remove(top);
    background: none;
    border-radius: var(--f7-popover-border-radius) var(--f7-popover-border-radius) 0 0;
  }
  .toolbar + .picker-columns {
    height: calc(100% - var(--f7-toolbar-height));
  }
}
.picker-columns {
  display: flex;
  overflow: hidden;
  justify-content: center;
  padding: 0;
  text-align: right;
  height: 100%;
  position: relative;
  -webkit-mask-box-image: linear-gradient(
    to top,
    transparent,
    transparent 5%,
    white 20%,
    white 80%,
    transparent 95%,
    transparent
  );
  font-size: var(--f7-picker-column-font-size);
}
.picker-column {
  position: relative;
  max-height: 100%;
  &.picker-column-first:before,
  &.picker-column-last:after {
    height: 100%;
    width: 100vw;
    position: absolute;
    content: '';
    top: 0;
  }
  &.picker-column-first:before {
    .ltr({
      right: 100%;
    });
    .rtl({
      left: 100%;
    });
  }
  &.picker-column-last:after {
    .ltr({
      left: 100%;
    });
    .rtl({
      right: 100%;
    });
  }
  &.picker-column-left {
    text-align: left;
  }
  &.picker-column-center {
    text-align: center;
  }
  &.picker-column-right {
    text-align: right;
  }
  &.picker-column-divider {
    display: flex;
    align-items: center;
    color: var(--f7-picker-divider-text-color);
  }
}
.picker-items {
  transition: 300ms;
  transition-timing-function: ease-out;
}
.picker-item {
  height: var(--f7-picker-item-height);
  line-height: var(--f7-picker-item-height);
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  transition: 300ms;
  color: var(--f7-picker-item-text-color);
  cursor: pointer;
  span {
    padding: 0 10px;
  }
  .picker-column-absolute & {
    position: absolute;
  }
  &.picker-item-far {
    pointer-events: none;
  }
  &.picker-item-selected {
    color: var(--f7-picker-item-selected-text-color);
    transform: translate3d(0, 0, 0) rotateX(0deg);
  }
}
.picker-center-highlight {
  height: var(--f7-picker-item-height);
  box-sizing: border-box;
  position: absolute;
  left: 16px;
  right: 16px;
  top: 50%;
  margin-top: calc(-1 * var(--f7-picker-item-height) / 2);
  pointer-events: none;
  background-color: var(--f7-picker-item-selected-bg-color);
  border-radius: 8px;
  .popover & {
    left: 8px;
    right: 8px;
  }
}
.picker-3d {
  .picker-columns {
    overflow: hidden;
    perspective: 1200px;
  }
  .picker-column,
  .picker-items,
  .picker-item {
    transform-style: preserve-3d;
  }
  .picker-column {
    overflow: visible;
  }
  .picker-item {
    transform-origin: center center -110px;
    backface-visibility: hidden;
    transition-timing-function: ease-out;
  }
}

.if-ios-theme({
  @import './picker-ios.less';
});
.if-md-theme({
  @import './picker-md.less';
});
.if-aurora-theme({
  @import './picker-aurora.less';
});
