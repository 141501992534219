.md {
  .page-next {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 56px, 0);
    &.page-next-on-right {
      .ltr({
        transform: translate3d(100%, 0, 0);
      });
      .rtl({
        transform: translate3d(-100%, 0, 0);
      });
    }
  }
  // Animations
  .router-transition-forward {
    .page-next {
      animation: md-page-next-to-current var(--f7-page-transition-duration) forwards;
    }
    .page-current {
      animation: none;
    }
  }
  .router-transition-backward {
    .page-current {
      animation: md-page-current-to-next var(--f7-page-transition-duration) forwards;
    }
    .page-previous {
      animation: none;
    }
  }
}
@keyframes md-page-next-to-current {
  from {
    transform: translate3d(0, 56px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}
@keyframes md-page-current-to-next {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 56px, 0);
    opacity: 0;
  }
}
