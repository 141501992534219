:root {
  /*
  --f7-radio-active-color: var(--f7-theme-color);
  */
  --f7-radio-border-radius: 50%;
  --f7-radio-extra-margin: 0px;
  .dark-vars({
    --f7-radio-inactive-color: rgba(255, 255, 255, 0.3);
  });
}
.ios-vars({
  --f7-radio-size: 22px;
  --f7-radio-border-width: 1px;
  .light-vars({
    --f7-radio-inactive-color: #c7c7cc;
  })
});
.md-vars({
  --f7-radio-size: 20px;
  --f7-radio-border-width: 2px;
  .light-vars({
    --f7-radio-inactive-color: #6d6d6d;
  })
});
.aurora-vars({
  --f7-radio-size: 16px;
  --f7-radio-border-width: 1px;
  .light-vars({
    --f7-radio-inactive-color: rgba(0,0,0,0.25);
  })
});
