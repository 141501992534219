:root {
  --f7-menu-text-color: #fff;
  --f7-menu-font-size: 16px;
  --f7-menu-font-weight: 500;
  --f7-menu-line-height: 1.2;
  --f7-menu-bg-color: rgba(0, 0, 0, 0.9);
  --f7-menu-item-pressed-bg-color: rgba(20, 20, 20, 0.9);
  --f7-menu-item-padding-horizontal: 12px;
  --f7-menu-item-spacing: 6px;
  --f7-menu-item-height: 40px;
  --f7-menu-item-dropdown-icon-color: rgba(255, 255, 255, 0.4);
  --f7-menu-item-border-radius: 8px;
  /*
  --f7-menu-dropdown-pressed-bg-color: var(--f7-theme-color);
  */
  --f7-menu-dropdown-item-height: 32px;
  --f7-menu-dropdown-divider-color: rgba(255, 255, 255, 0.2);
  --f7-menu-dropdown-padding-vertical: 6px;
  /*
  --f7-menu-dropdown-border-radius: var(--f7-menu-item-border-radius);
  */
}
.aurora-vars({
  /*
  --f7-menu-dropdown-hover-bg-color: rgba(255, 255, 255, 0.15);
  */
});
