:root {
  --f7-link-highlight-black: rgba(0, 0, 0, 0.1);
  --f7-link-highlight-white: rgba(255, 255, 255, 0.15);
  --f7-link-highlight-color: rgba(var(--f7-theme-color-rgb), 0.15);
}

.ios-vars({
  --f7-link-pressed-opacity: 0.3;
});

.aurora-vars({
  --f7-link-pressed-opacity: 0.3;
  --f7-link-hover-opacity: 0.65;
});
