/* === Data Table === */
@import './data-table-vars.less';

.data-table {
  overflow-x: auto;
  table,
  table& {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    .ltr({ text-align: left; });
    .rtl({ text-align: right; });
  }
  thead {
    th,
    td {
      font-size: var(--f7-table-head-font-size);
      font-weight: var(--f7-table-head-font-weight);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 16px;
      height: var(--f7-table-head-cell-height);
      background-color: var(--f7-table-head-bg-color);
      &:not(.sortable-cell-active) {
        color: var(--f7-table-head-text-color);
      }
    }
    i.icon,
    i.f7-icons,
    i.material-icons {
      vertical-align: top;
      font-size: var(--f7-table-head-icon-size);
    }
  }
  tbody {
    font-size: var(--f7-table-body-font-size);
    th,
    td {
      height: var(--f7-table-body-cell-height);
    }
    tr.data-table-row-selected,
    .device-desktop & tr:hover {
      background: var(--f7-table-selected-row-bg-color);
    }
    td,
    th {
      .hairline(top, var(--f7-table-cell-border-color));
    }
  }
  th,
  td {
    --f7-table-cell-padding-left: var(--f7-table-cell-padding-horizontal);
    --f7-table-cell-padding-right: var(--f7-table-cell-padding-horizontal);
    padding-top: var(--f7-table-cell-padding-vertical);
    padding-bottom: var(--f7-table-cell-padding-vertical);
    padding-left: var(--f7-table-cell-padding-left);
    padding-right: var(--f7-table-cell-padding-right);
    position: relative;
    box-sizing: border-box;
    &:first-child {
      .ltr({
        --f7-table-cell-padding-left: var(--f7-table-edge-cell-padding-horizontal);
      });
      .rtl({
        --f7-table-cell-padding-right: var(--f7-table-edge-cell-padding-horizontal);
      });
    }
    &:last-child {
      .ltr({
        --f7-table-cell-padding-right: var(--f7-table-edge-cell-padding-horizontal);
      });
      .rtl({
        --f7-table-cell-padding-left: var(--f7-table-edge-cell-padding-horizontal);
      });
    }
    &.label-cell {
      --f7-table-cell-padding-left: var(--f7-table-label-cell-padding-horizontal);
      --f7-table-cell-padding-right: var(--f7-table-label-cell-padding-horizontal);
    }
    &.numeric-cell {
      .ltr({ text-align: right; });
      .rtl({ text-align: left; });
    }
    &.checkbox-cell {
      overflow: visible;
      width: var(--f7-table-checkbox-cell-width);
      label + span {
        .ltr({ margin-left: 8px; });
        .rtl({ margin-right: 8px; });
      }
      &:first-child {
        .ltr({
          padding-right: calc(var(--f7-table-cell-padding-right) / 2);
          + td, + th {
            padding-left: calc(var(--f7-table-cell-padding-left) / 2);
          }
        });
        .rtl({
          padding-left: calc(var(--f7-table-cell-padding-left) / 2);
          + td, + th {
            padding-right: calc(var(--f7-table-cell-padding-right) / 2);
          }
        });
      }
      &:last-child {
        .ltr({
          padding-left: calc(var(--f7-table-cell-padding-left) / 2);
        });
        .rtl({
          padding-right: calc(var(--f7-table-cell-padding-right) / 2);
        });
      }
    }
    &.actions-cell {
      .ltr({ text-align: right; });
      .rtl({ text-align: left; });
      white-space: nowrap;
      a.link {
        color: var(--f7-table-actions-cell-link-color, var(--f7-theme-color));
      }
    }
    a.icon-only,
    .card & a.icon-only,
    .card& a.icon-only {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: 0;
      min-width: 0;
      i {
        font-size: var(--f7-table-link-icon-only-icon-size);
        vertical-align: middle;
      }
    }
  }
  // Sortable
  .sortable-cell:not(.input-cell) {
    cursor: pointer;
    position: relative;
  }
  .sortable-cell.input-cell .table-head-label {
    cursor: pointer;
    position: relative;
  }
  .sortable-cell:not(.numeric-cell):not(.input-cell):after,
  .sortable-cell.numeric-cell:not(.input-cell):before,
  .sortable-cell:not(.numeric-cell).input-cell > .table-head-label:after,
  .sortable-cell.numeric-cell.input-cell > .table-head-label:before {
    content: 'arrow_bottom_md';
    .core-icons-font();
    display: inline-block;
    vertical-align: top;
    width: 16px;
    height: 16px;
    color: var(--f7-table-sortable-icon-color);
    font-size: 13px;
    line-height: 16px;
    transition-duration: 300ms;
    transform: rotate(0);
    opacity: 0;
  }
  .device-desktop & {
    .sortable-cell:not(.sortable-cell-active):hover,
    .sortable-cell:not(.sortable-cell-active) .table-head-label:hover {
      &:after,
      &:before {
        opacity: 0.54;
      }
    }
  }
  .sortable-cell.sortable-cell-active,
  .sortable-cell.sortable-cell-active .table-head-label {
    &:after,
    &:before {
      opacity: 0.87 !important;
    }
  }
  .sortable-cell.sortable-asc {
  }
  .sortable-cell.sortable-desc,
  .sortable-cell.sortable-desc,
  .table-head-label {
    &:after,
    &:before {
      transform: rotate(180deg) !important;
    }
  }
  &.card,
  .card & {
    .card-header,
    .card-footer {
      padding-left: var(--f7-table-edge-cell-padding-horizontal);
      padding-right: var(--f7-table-edge-cell-padding-horizontal);
    }
    .card-header {
      min-height: var(--f7-table-card-header-height);
      background-color: var(--f7-table-card-header-bg-color);
    }
    .card-content {
      overflow-x: auto;
    }
    .card-footer {
      min-height: var(--f7-table-footer-height);
    }
  }
  .data-table-title {
    font-size: var(--f7-table-title-font-size);
    font-weight: var(--f7-table-title-font-weight);
  }
  .data-table-links,
  .data-table-actions {
    display: flex;
  }
  .data-table-links {
    .button {
      min-width: 64px;
    }
  }
  .data-table-actions {
    .ltr({ margin-left: auto; });
    .rtl({ margin-right: auto; });
    align-items: center;
    a.link {
      color: var(--f7-table-actions-link-color, var(--f7-theme-color));
      min-width: 0;
    }
    a.link.icon-only {
      line-height: 1;
      justify-content: center;
      padding: 0;
    }
  }
  .data-table-header,
  .data-table-header-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .card-header {
    > .data-table-header,
    > .data-table-header-selected {
      padding-top: var(--f7-card-header-padding-vertical);
      padding-bottom: var(--f7-card-header-padding-vertical);
      height: 100%;
      margin-top: calc(-1 * var(--f7-card-header-padding-vertical));
      margin-bottom: calc(-1 * var(--f7-card-header-padding-vertical));
      min-height: var(--f7-table-card-header-height);
      .ltr({
        padding-left: var(--f7-table-edge-cell-padding-horizontal);
        padding-right: var(--f7-table-edge-cell-padding-horizontal);
        margin-left: calc(-1 * var(--f7-table-edge-cell-padding-horizontal));
        margin-right: calc(-1 * var(--f7-table-edge-cell-padding-horizontal));
      });
      .rtl({
        padding-right: var(--f7-table-edge-cell-padding-horizontal);
        padding-left: var(--f7-table-edge-cell-padding-horizontal);
        margin-right: calc(-1 * var(--f7-table-edge-cell-padding-horizontal));
        margin-left: calc(-1 * var(--f7-table-edge-cell-padding-horizontal));
      });
    }
  }
  .data-table-header-selected {
    background: rgba(var(--f7-theme-color-rgb), 0.1);
    display: none;
  }

  &.data-table-has-checked {
    .data-table-header {
      display: none;
    }
    .data-table-header-selected {
      display: flex;
    }
  }
  .data-table-title-selected {
    font-size: 14px;
    color: var(--f7-theme-color);
  }

  // Footer
  .data-table-footer {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    font-size: var(--f7-table-footer-font-size);
    overflow: hidden;
    min-height: var(--f7-table-footer-height);
    color: var(--f7-table-footer-text-color);
    .hairline(top, var(--f7-table-cell-border-color));
    .ltr({
      justify-content: flex-end;
    });
    .rtl({
      justify-content: flex-start;
    });
  }
  .data-table-rows-select,
  .data-table-pagination {
    display: flex;
    align-items: center;
  }

  // Inputs
  .input-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    vertical-align: top;
    .table-head-label + .input {
      margin-top: 4px;
    }
    .input {
      height: var(--f7-table-input-height);
      input,
      textarea,
      select {
        height: var(--f7-table-input-height);
        color: var(--f7-table-input-text-color);
        font-size: var(--f7-table-input-font-size);
      }
    }
  }

  // Collapsible
  @media (max-width: 480px) and (orientation: portrait) {
    &.data-table-collapsible {
      thead {
        display: none;
      }
      tbody,
      tr,
      td {
        display: block;
      }
      tr {
        position: relative;
        .hairline(top, var(--f7-table-cell-border-color));
      }
      tr:hover {
        background-color: inherit;
      }
      td {
        --f7-table-cell-padding-left: var(--f7-table-collapsible-cell-padding);
        --f7-table-cell-padding-right: var(--f7-table-collapsible-cell-padding);
        .hairline-remove(top);
        height: auto;
        min-height: var(--f7-table-body-cell-height);
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        .ltr({
          text-align: left;
        });
        .rtl({
          text-align: right;
        });
      }
      td:not(.checkbox-cell) {
        &:before {
          width: 40%;
          display: block !important;
          content: attr(data-collapsible-title);
          position: relative;
          height: auto;
          background: none !important;
          transform: none !important;
          font-size: var(--f7-table-head-font-size);
          font-weight: var(--f7-table-head-font-weight);
          color: var(--f7-table-head-text-color);
          .ltr({ margin-right: 16px; });
          .rtl({ margin-left: 16px; });
          flex-shrink: 0;
        }
      }
      td.checkbox-cell {
        position: absolute;
        top: 0;
        .ltr({
          left: 0;
          + td {
            padding-left: 16px;
          }
          ~ td {
            margin-left: 16px + 16px;
          }
        });
        .rtl({
          right: 0;
          + td {
            padding-right: 16px;
          }
          ~ td {
            margin-right: 16px + 16px;
          }
        });
      }
    }
  }
  // Responsive queries
  each(@breakpoints, {
    .@{key}-only,
    .@{key}-landscape-only {
      display: none;
    }
    @media (min-width: @value) {
      .@{key}-only {
        display: table-cell;
      }
    }
    @media (min-width: @value) and (orientation:landscape) {
      .@{key}-landscape-only {
        display: table-cell;
      }
    }
  });
}

.if-ios-theme({
  @import './data-table-ios.less';
});
.if-md-theme({
  @import './data-table-md.less';
});
.if-md-theme({
  @import './data-table-aurora.less';
});
