:root {
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 630px;
  --f7-popup-tablet-height: 630px;
  --f7-popup-transition-duration: 400ms;
  --f7-popup-push-border-radius: 10px;
  --f7-popup-push-offset: var(--f7-safe-area-top);
  /*
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  */
}
.ios-vars({
  --f7-popup-tablet-border-radius: 5px;
  --f7-popup-box-shadow: none;
});
.md-vars({
  --f7-popup-tablet-border-radius: 4px;
  --f7-popup-box-shadow: 0px 20px 44px rgba(0,0,0,0.5);
});
.aurora-vars({
  --f7-popup-tablet-border-radius: 8px;
  --f7-popup-box-shadow: 0px 20px 44px rgba(0,0,0,0.5);
});
