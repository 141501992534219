.md {
  .item-input:not(.item-input-outline) .item-input-wrap,
  .input:not(.input-outline) {
    .hairline(bottom, var(--f7-list-item-border-color));
    &:after {
      transition-duration: 200ms;
    }
  }
  .item-input-wrap {
    min-height: var(--f7-input-height);
  }

  .item-input {
    .item-media {
      align-self: flex-end;
    }
    .item-inner {
      .hairline-remove(bottom);
    }
  }
  .inline-labels,
  .inline-label {
    .item-media {
      align-self: flex-start;
      padding-top: 14px;
    }
  }
  .item-input-with-error-message,
  .item-input-with-info,
  .input-with-error-message,
  .input-with-info {
    padding-bottom: 20px;
  }
  .item-input-error-message,
  .item-input-info,
  .input-error-message,
  .input-info {
    position: absolute;
    top: 100%;
    margin-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    .ltr({
      left: 0;
    });
    .rtl({
      right: 0;
    });
  }

  .item-input-focused {
    .item-label,
    .item-floating-label {
      color: var(--f7-label-focused-text-color, var(--f7-theme-color));
    }
  }
  .item-input-focused:not(.item-input-outline) .item-input-wrap:after,
  .input-focused:not(.input-outline):after {
    background: var(--f7-input-focused-border-color, var(--f7-theme-color));
  }

  .item-input-invalid:not(.item-input-outline) .item-input-wrap:after,
  .item-input-focused:not(.item-input-outline) .item-input-wrap:after,
  .input-invalid:not(.input-outline):after,
  .input-focused:not(.input-outline):after {
    transform: scaleY(2) !important;
  }

  .item-input-invalid:not(.item-input-outline) .item-input-wrap:after,
  .input-invalid:not(.input-outline):after {
    background: var(--f7-input-invalid-border-color, var(--f7-input-error-text-color));
  }
  .item-input-invalid {
    .item-label,
    .item-floating-label {
      color: var(--f7-label-invalid-text-color, var(--f7-input-error-text-color));
    }
  }
  .item-input-invalid,
  .input-invalid {
    input,
    select,
    textarea {
      color: var(--f7-input-invalid-text-color, var(--f7-input-text-color));
    }
  }

  .input-clear-button {
    &:after {
      font-size: calc(var(--f7-input-clear-button-size) / (24 / 20));
      content: 'delete_round_md';
      line-height: 1.2;
    }
    &:before {
      width: 48px;
      height: 48px;
      margin-left: -24px;
      margin-top: -24px;
    }
  }
}
