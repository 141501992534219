:root {
  --f7-calendar-height: 340px;
  --f7-calendar-sheet-landscape-height: 220px;
  --f7-calendar-popover-width: 320px;
  --f7-calendar-popover-height: 320px;
  --f7-calendar-modal-height: 420px;
  --f7-calendar-modal-max-width: 380px;
  --f7-calendar-modal-border-radius: 4px;
  /*
  --f7-calendar-header-bg-color: var(--f7-bars-bg-color);
  --f7-calendar-header-link-color: var(--f7-bars-link-color);
  --f7-calendar-header-text-color: var(--f7-bars-text-color);
  --f7-calendar-footer-bg-color: var(--f7-bars-bg-color);
  --f7-calendar-footer-border-color: var(--f7-bars-border-color);
  --f7-calendar-footer-link-color: var(--f7-bars-link-color);
  --f7-calendar-footer-text-color: var(--f7-bars-text-color);
  --f7-calendar-week-header-bg-color: var(--f7-bars-bg-color);
  --f7-calendar-week-header-text-color: var(--f7-bars-text-color);
  */
  --f7-calendar-footer-padding: 0 8px;
  --f7-calendar-week-header-font-size: 11px;
  --f7-calendar-selected-text-color: #fff;
  /*
  --f7-calendar-selected-bg-color:  var(--f7-theme-color);
  */
  --f7-calendar-prev-next-text-color: #b8b8b8;
  --f7-calendar-disabled-text-color: #d4d4d4;
  --f7-calendar-event-dot-size: 4px;
  /*
  --f7-calendar-event-bg-color: var(--f7-theme-color);
  */
  /*
  --f7-calendar-picker-selected-text-color: var(--f7-theme-color);
  */
  --f7-calendar-time-selector-height: 28px;
  .light-vars({
    --f7-calendar-day-text-color:  #000;
    --f7-calendar-sheet-bg-color: #fff;
    --f7-calendar-modal-bg-color: #fff;
    --f7-calendar-picker-bg-color: #fff;
    --f7-calendar-picker-pressed-bg-color: rgba(0,0,0,0.1);
    --f7-calendar-picker-hover-bg-color: rgba(0,0,0,0.03);
    --f7-calendar-time-selector-bg-color: rgba(0,0,0,0.1);
  });
  .dark-vars({
    --f7-calendar-sheet-border-color: var(--f7-bars-border-color);
    --f7-calendar-modal-bg-color: #121212;
    --f7-calendar-sheet-bg-color: #121212;
    --f7-calendar-picker-bg-color: #1c1c1d;
    --f7-calendar-picker-pressed-bg-color: rgba(255,255,255,0.08);
    --f7-calendar-picker-hover-bg-color: rgba(255,255,255,0.03);
    --f7-calendar-time-selector-bg-color: rgba(255,255,255,0.1);
  });
}
.ios-vars({
  --f7-calendar-sheet-border-color: #929499;
  --f7-calendar-header-height: 44px;
  --f7-calendar-header-font-size: 17px;
  --f7-calendar-header-font-weight: 600;
  --f7-calendar-header-padding: 0 8px;
  --f7-calendar-footer-height: 44px;
  --f7-calendar-footer-font-size: 17px;
  --f7-calendar-week-header-height: 18px;
  --f7-calendar-day-font-size: 15px;
  --f7-calendar-day-size: 30px;
  --f7-calendar-picker-font-size: 17px;
  --f7-calendar-time-selector-font-size: 17px;
  .light-vars({
    --f7-calendar-row-border-color: rgba(0,0,0,0.25);
    --f7-calendar-today-text-color: #000;
    --f7-calendar-today-bg-color: #e3e3e3;
  });
  .dark-vars({
    --f7-calendar-row-border-color: var(--f7-bars-border-color);
    --f7-calendar-day-text-color: #fff;
    --f7-calendar-today-text-color: #fff;
    --f7-calendar-today-bg-color: #333;
  });
});
.md-vars({
  --f7-calendar-sheet-border-color: #ccc;
  --f7-calendar-header-height: 56px;
  --f7-calendar-header-font-size: 20px;
  --f7-calendar-header-font-weight: 400;
  --f7-calendar-header-padding: 0 24px;
  --f7-calendar-footer-height: 48px;
  --f7-calendar-footer-font-size: 14px;
  --f7-calendar-week-header-height: 24px;
  --f7-calendar-row-border-color: transparent;
  --f7-calendar-day-font-size: 14px;
  /*
  --f7-calendar-today-text-color: var(--f7-theme-color);
  */
  --f7-calendar-today-bg-color: none;
  --f7-calendar-day-size: 32px;
  --f7-calendar-picker-font-size: 14px;
  --f7-calendar-time-selector-font-size: 14px;
  .dark-vars({
    --f7-calendar-day-text-color: rgba(255,255,255,0.87);
  });
});
.aurora-vars({
  --f7-calendar-sheet-border-color: #ccc;
  --f7-calendar-header-height: 56px;
  --f7-calendar-header-font-size: 18px;
  --f7-calendar-header-font-weight: 600;
  --f7-calendar-header-padding: 0 16px;
  --f7-calendar-footer-height: 48px;
  --f7-calendar-footer-font-size: 14px;
  --f7-calendar-footer-padding: 0 16px;
  --f7-calendar-week-header-height: 24px;
  --f7-calendar-day-font-size: 14px;
  --f7-calendar-day-size: 32px;
  --f7-calendar-picker-font-size: 16px;
  --f7-calendar-time-selector-font-size: 14px;
  --f7-calendar-row-border-color: transparent;
  .light-vars({
    --f7-calendar-today-text-color: #000;
    --f7-calendar-today-bg-color: #e3e3e3;
  });
  .dark-vars({
    --f7-calendar-day-text-color: #fff;
    --f7-calendar-today-text-color: #fff;
    --f7-calendar-today-bg-color: #333;
  });
});
