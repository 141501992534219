:root {
  --f7-button-font-size: 14px;
  --f7-button-min-width: 32px;
  --f7-button-bg-color: transparent;
  --f7-button-border-width: 0px;
  --f7-button-text-transform: uppercase;
  --f7-button-large-text-transform: uppercase;
  --f7-button-small-text-transform: uppercase;
  --f7-button-small-outline-border-width: 2px;
  /*
  --f7-button-text-color: var(--f7-theme-color);
  --f7-button-pressed-bg-color: rgba(var(--f7-theme-color-rgb), .15);
  --f7-button-pressed-text-color: var(--f7-button-text-color, var(--f7-theme-color));
  --f7-button-border-color: var(--f7-theme-color);
  --f7-button-fill-text-color: #fff;
  --f7-button-fill-bg-color: var(--f7-theme-color);
  --f7-button-outline-border-color: var(--f7-theme-color);
  --f7-button-padding-vertical: 0px;
  */
  --f7-button-outline-border-width: 2px;
  --f7-button-raised-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --f7-button-raised-pressed-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  --f7-segmented-raised-divider-color: rgba(0, 0, 0, 0.1);
  --f7-segmented-strong-padding: 2px;
  --f7-segmented-strong-between-buttons: 4px;
  --f7-segmented-strong-button-font-weight: 500;
  --f7-segmented-strong-button-active-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  .light-vars({
    --f7-segmented-strong-bg-color: rgba(0,0,0,0.07);
    --f7-segmented-strong-button-text-color: #000;
    --f7-segmented-strong-button-pressed-bg-color: rgba(0,0,0,0.07);
    --f7-segmented-strong-button-hover-bg-color: rgba(0,0,0,0.04);
    --f7-segmented-strong-button-active-text-color: #000;
    --f7-segmented-strong-button-active-bg-color: #fff;
  });
  .dark-vars({
    --f7-segmented-strong-bg-color: rgba(255, 255, 255, 0.1);
    --f7-segmented-strong-button-pressed-bg-color: rgba(255,255,255,0.04);
    --f7-segmented-strong-button-hover-bg-color: rgba(255,255,255,0.02);
    --f7-segmented-strong-button-active-bg-color: rgba(255, 255, 255, 0.14);
    --f7-segmented-strong-button-text-color: #fff;
    --f7-segmented-strong-button-active-text-color: #fff;
  });
}
.ios-vars({

  --f7-button-height: 28px;
  --f7-button-padding-horizontal: 10px;
  --f7-button-border-radius: 5px;
  --f7-button-font-weight: 600;
  --f7-button-letter-spacing: 0;
  /*
  --f7-button-fill-pressed-bg-color: var(--f7-theme-color-tint);
  */
  --f7-button-large-height: 44px;
  --f7-button-large-font-size: 17px;
  --f7-button-large-font-weight: 500;
  --f7-button-small-height: 26px;
  --f7-button-small-font-size: 13px;
  --f7-button-small-font-weight: 600;

  --f7-segmented-strong-button-text-transform: none;
  --f7-segmented-strong-button-active-font-weight: 600;
});
.md-vars({
  --f7-button-height: 36px;
  --f7-button-padding-horizontal: 8px;
  --f7-button-border-radius: 4px;
  --f7-button-font-weight: 500;
  --f7-button-letter-spacing: 0.05em;
  /*
  --f7-button-fill-pressed-bg-color: var(--f7-theme-color-shade);
  */
  --f7-button-large-height: 48px;
  --f7-button-large-font-size: 14px;
  --f7-button-large-font-weight: 500;
  --f7-button-small-height: 28px;
  --f7-button-small-font-size: 14px;
  --f7-button-small-font-weight: 500;

  --f7-segmented-strong-button-text-transform: uppercase;
  --f7-segmented-strong-button-active-font-weight: 500;

});
.aurora-vars({
  /*
  --f7-button-hover-bg-color: rgba(var(--f7-theme-color-rgb), .07);
  --f7-button-fill-hover-bg-color: var(--f7-theme-color-tint);
  */
  --f7-button-height: 36px;
  --f7-button-min-width: 24px;
  --f7-button-padding-horizontal: 10px;
  --f7-button-border-radius: 8px;
  --f7-button-font-weight: 600;
  --f7-button-letter-spacing: 0em;
  /*
  --f7-button-fill-pressed-bg-color: var(--f7-theme-color-shade);
  */
  --f7-button-large-height: 48px;
  --f7-button-large-font-size: 16px;
  --f7-button-large-font-weight: 600;
  --f7-button-small-height: 28px;
  --f7-button-small-font-size: 14px;
  --f7-button-small-font-weight: 600;
  --f7-segmented-strong-button-text-transform: uppercase;
  --f7-segmented-strong-button-active-font-weight: 600;
});
