/* === Views === */
.views,
.view {
  position: relative;
  height: 100%;
  z-index: 5000;
  overflow: hidden;
  box-sizing: border-box;
}
.framework7-root {
  > .view,
  > .views {
    height: calc(100% - var(--f7-appbar-app-offset, 0px));
  }
}
.view-resize-handler {
  width: 6px;
  height: 100%;
  position: absolute;
  left: var(--f7-page-master-width);
  margin-left: -3px;
  top: 0;
  cursor: col-resize;
  z-index: 1000;
  display: none;
  .view-master-detail & {
    display: block;
  }
}
.if-ios-theme({
  @import './view-ios.less';
});
.if-md-theme({
  @import './view-md.less';
});
.if-aurora-theme({
  @import './view-aurora.less';
});
