/* === Login Screen === */
@import './login-screen-vars.less';

.login-screen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  box-sizing: border-box;
  transition-property: transform;
  transform: translate3d(0, 100vh, 0);
  background: var(--f7-login-screen-bg-color);
  z-index: 11000;
  &.modal-in,
  &.modal-out {
    transition-duration: 400ms;
  }
  &.not-animated {
    transition-duration: 0ms;
  }
  &.modal-in {
    display: block;
    transform: translate3d(0, 0, 0);
  }
  &.modal-out {
    transform: translate3d(0, 100%, 0);
  }
}
.login-screen-content,
.login-screen-page,
.login-screen .page {
  background: var(--f7-login-screen-content-bg-color);
}
.login-screen-content,
.login-screen-page {
  .list-button {
    text-align: center;
    color: var(--f7-login-screen-list-button-text-color, var(--f7-theme-color));
  }
  .login-screen-title,
  .list,
  .block {
    margin: var(--f7-login-screen-blocks-margin-vertical) auto;
  }
  .login-screen-title,
  .list,
  .block,
  .block-footer,
  .block-header {
    max-width: var(--f7-login-screen-blocks-max-width);
  }
  .list ul {
    .hairline-remove-top-bottom();
    background: none;
  }
  .block-footer,
  .block-header {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
.login-screen-title {
  text-align: var(--f7-login-screen-title-text-align);
  font-size: var(--f7-login-screen-title-font-size);
  font-weight: var(--f7-login-screen-title-font-weight);
  color: var(--f7-login-screen-title-text-color);
  letter-spacing: var(--f7-login-screen-title-letter-spacing);
}
.login-screen-page,
.login-screen > .view > .page,
.login-screen > .page {
  display: flex;
  flex-direction: column;
  .page-content,
  .login-screen-content {
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
    max-height: 100%;
    width: 100%;
  }
}

.theme-dark {
  .login-screen-content .list ul,
  .login-screen-content .block-strong,
  .login-screen-page .list ul,
  .login-screen-page .block-strong,
  &.login-screen-page .list ul,
  &.login-screen-page .block-strong {
    background-color: transparent;
  }
}

.if-ios-theme({
  @import './login-screen-ios.less';
});
.if-md-theme({
  @import './login-screen-md.less';
});
.if-aurora-theme({
  @import './login-screen-aurora.less';
});
