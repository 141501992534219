:root {
  --f7-photobrowser-bg-color: #fff;
  --f7-photobrowser-bars-bg-image: none;
  /*
  --f7-photobrowser-bars-bg-color: rgba(var(--f7-bars-bg-color-rgb), 0.95);
  --f7-photobrowser-bars-text-color: var(--f7-bars-text-color);
  --f7-photobrowser-bars-link-color: var(--f7-bars-link-color);
  */
  --f7-photobrowser-caption-font-size: 14px;
  --f7-photobrowser-caption-light-text-color: #000;
  --f7-photobrowser-caption-light-bg-color: rgba(255, 255, 255, 0.8);
  --f7-photobrowser-caption-dark-text-color: #fff;
  --f7-photobrowser-caption-dark-bg-color: rgba(0, 0, 0, 0.8);
  --f7-photobrowser-exposed-bg-color: #000;

  --f7-photobrowser-dark-bg-color: #000;
  --f7-photobrowser-dark-bars-bg-color: rgba(27, 27, 27, 0.8);
  --f7-photobrowser-dark-bars-text-color: #fff;
  --f7-photobrowser-dark-bars-link-color: #fff;
}
