
// RTL, LTR
.ltr(@ruleset) {
  & when not (@rtl) {
    @ruleset();
  }
}
.rtl(@ruleset) {
  & when (@rtl) {
    @ruleset();
  }
}
.if-ios-theme(@ruleset) {
  & when (@includeIosTheme) {
    @ruleset();
  }
}
.if-md-theme(@ruleset) {
  & when (@includeMdTheme) {
    @ruleset();
  }
}
.if-aurora-theme(@ruleset) {
  & when (@includeAuroraTheme) {
    @ruleset();
  }
}
.if-dark-theme(@ruleset) {
  & when(@includeDarkTheme) {
    @ruleset();
  }
}
.ios-vars(@ruleset) {
  & when (@includeIosTheme) {
    .ios {
      @ruleset();
    }
  }
}
.md-vars(@ruleset) {
  & when (@includeMdTheme) {
    .md {
      @ruleset();
    }
  }
}
.aurora-vars(@ruleset) {
  & when (@includeAuroraTheme) {
    .aurora {
      @ruleset();
    }
  }
}
.dark-vars(@ruleset) {
  & when (@includeDarkTheme) {
    .theme-dark,
    &.theme-dark {
      @ruleset();
    }
  }
}
.light-vars(@ruleset) {
  & when (@includeLightTheme) {
    & {
      @ruleset();
    }
  }
}
// Scrollable
.scrollable() {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.not-scrollable() {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}
// Disabled
.disabled() {
  opacity: 0.55;
  pointer-events: none;
}
// Modal Backdrops
.modal-backdrop() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 13000;
  visibility: hidden;
  opacity: 0;
  transition-duration: 400ms;
  &.not-animated {
    transition-duration: 0ms;
  }
  &.backdrop-in {
    visibility: visible;
    opacity: 1;
  }
}
// No Hairlines
.no-hairlines() {
  &.no-hairlines,
  &.no-hairlines ul,
  .md &.no-hairlines-md,
  .md &.no-hairlines-md ul,
  .ios &.no-hairlines-ios,
  .ios &.no-hairlines-ios ul,
  .aurora &.no-hairlines-aurora,
  .aurora &.no-hairlines-aurora ul {
    .hairline-remove-top-bottom();
  }
  &.no-hairline-top,
  &.no-hairline-top ul,
  .md &.no-hairline-top-md,
  .md &.no-hairline-top-md ul,
  .ios &.no-hairline-top-ios,
  .ios &.no-hairline-top-ios ul,
  .aurora &.no-hairline-top-aurora,
  .aurora &.no-hairline-top-aurora ul {
    .hairline-remove(top);
  }
  &.no-hairline-bottom,
  &.no-hairline-bottom ul,
  .md &.no-hairline-bottom-md,
  .md &.no-hairline-bottom-md ul,
  .ios &.no-hairline-bottom-ios,
  .ios &.no-hairline-bottom-ios ul,
  .aurora &.no-hairline-bottom-aurora,
  .aurora &.no-hairline-bottom-aurora ul {
    .hairline-remove(bottom);
  }
}
.no-hairlines-between() {
  &.no-hairlines-between,
  .md &.no-hairlines-between-md,
  .ios &.no-hairlines-between-ios,
  .aurora &.no-hairlines-between-aurora {
    .item-inner,
    .list-button,
    .item-divider,
    .list-group-title,
    .list-group-title {
      .hairline-remove(bottom);
    }
    &.simple-list li {
      .hairline-remove(bottom);
    }
    &.links-list a {
      .hairline-remove(bottom);
    }
  }
}
// Hairlines
.hairline-pseudo(@color, @top, @right, @bottom, @left) {
  content: '';
  position: absolute;
  background-color: @color;
  display: block;
  z-index: 15;
  top: @top;
  right: @right;
  bottom: @bottom;
  left: @left;
}
.hairline(@position, @color) when (@position = top) {
  &:before {
    .hairline-pseudo(@color, 0, auto, auto, 0);
    height: 1px;
    width: 100%;
    transform-origin: 50% 0%;
    transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
  }
}
.hairline(@position, @color) when (@position = left) {
  &:before {
    .hairline-pseudo(@color, 0, auto, auto, 0);
    width: 1px;
    height: 100%;
    transform-origin: 0% 50%;
    transform: scaleX(calc(1 / var(--f7-device-pixel-ratio)));
  }
}
.hairline(@position, @color) when (@position = bottom) {
  &:after {
    .hairline-pseudo(@color, auto, auto, 0, 0);
    height: 1px;
    width: 100%;
    transform-origin: 50% 100%;
    transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
  }
}
.hairline(@position, @color) when (@position = right) {
  &:after {
    .hairline-pseudo(@color, 0, 0, auto, auto);
    width: 1px;
    height: 100%;
    transform-origin: 100% 50%;
    transform: scaleX(calc(1 / var(--f7-device-pixel-ratio)));
  }
}
.hairline-remove-top-bottom() {
  &:before,
  &:after {
    display: none !important;
  }
}
// For right and bottom
.hairline-remove(@position) when not (@position = left) and not (@position = top) {
  &:after {
    display: none !important;
  }
}
// For left and top
.hairline-remove(@position) when not (@position = right) and not (@position = bottom) {
  &:before {
    display: none !important;
  }
}
// For right and bottom
.hairline-color(@position, @color) when not (@position = left) and not (@position = top) {
  &:after {
    background-color: @color;
  }
}
// For left and top
.hairline-color(@position, @color) when not (@position = right) and not (@position = bottom) {
  &:before {
    background-color: @color;
  }
}
// MD Active Active Links Highlights
.link-highlight() {
  &:before {
    content: '';
    width: 152%;
    height: 152%;
    left: -26%;
    top: -26%;
    position: absolute;
    background-image: radial-gradient(
      circle at center,
      var(--f7-link-highlight-color) 66%,
      rgba(255, 255, 255, 0) 66%
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    opacity: 0;
    pointer-events: none;
    transition-duration: 300ms;
    transition-property: opacity;
  }
  &.icon-only:before,
  &.ripple-inset:before,
  &.searchbar-disable-button:before,
  &.input-clear-button:before,
  &.notification-close-button:before {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: radial-gradient(
      circle at center,
      var(--f7-link-highlight-color) 71%,
      rgba(255, 255, 255, 0) 71%
    );
  }
  &.active-state:before {
    opacity: 1;
    transition-duration: 150ms;
  }
}
// MD Bars Shadow
.bar-shadow-bottom(@image) {
  &:before {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    top: 100%;
    bottom: auto;
    height: 8px;
    pointer-events: none;
    background: @image;
  }
}
.bar-shadow-top(@image) {
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 100%;
    height: 8px;
    top: auto;
    pointer-events: none;
    background: @image;
    transform: translate3d(0, 0, 0);
  }
}
.core-icons-font() {
  font-family: 'framework7-core-icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  text-align: center;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 20px;
}

.ios-translucent-bars(@color) {
  .ios-translucent-bars & {
    @supports (backdrop-filter: blur(20px)) {
      background-color: rgba(@color, var(--f7-bars-translucent-opacity));
      backdrop-filter: saturate(180%) blur(var(--f7-bars-translucent-blur));
    }
  }
}
.ios-translucent-modals(@color) {
  .ios-translucent-modals & {
    @supports (backdrop-filter: blur(20px)) {
      background-color: rgba(@color, 0.8);
      backdrop-filter: saturate(180%) blur(20px);
    }
  }
}
