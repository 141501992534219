/* === Treeview === */
@import './treeview-vars.less';

.treeview-item-root {
  .ltr({
    padding-left: var(--f7-treeview-item-padding-left);
    padding-right: var(--f7-treeview-item-padding-right);
  });
  .rtl({
    padding-right: var(--f7-treeview-item-padding-left);
    padding-left: var(--f7-treeview-item-padding-right);
  });
  min-height: var(--f7-treeview-item-height);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.treeview-item-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > i,
  > .f7-icons,
  > .material-icons {
    font-size: var(--f7-treeview-icon-size);
    color: var(--f7-treeview-icon-color);
  }
  &:first-child {
    .ltr({
      margin-left: calc(var(--f7-treeview-toggle-size) + 5px);
    });
    .rtl({
      margin-right: calc(var(--f7-treeview-toggle-size) + 5px);
    });
  }
  > * + * {
    .ltr({
      margin-left: 5px;
    });
    .rtl({
      margin-right: 5px;
    });
  }
}
.treeview-item-label {
  font-size: var(--f7-treeview-label-font-size);
  font-weight: var(--f7-treeview-label-font-weight);
  color: var(--f7-treeview-label-text-color);
}
.treeview-toggle {
  width: var(--f7-treeview-toggle-size);
  height: var(--f7-treeview-toggle-size);
  cursor: pointer;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0);
  transition-duration: 200ms;
  position: relative;
  .ltr({
    margin-right: 5px;;
  });
  .rtl({
    margin-left: 5px;
  });
  &.active-state {
    background-color: var(--f7-treeview-toggle-pressed-bg-color);
  }
  &:after {
    transition-duration: 200ms;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transform: translate(-50%, -50%);
    .ltr({
      border-left: 6px solid var(--f7-treeview-toggle-color);
    });
    .rtl({
      border-right: 6px solid var(--f7-treeview-toggle-color);
    });
  }
}
.treeview-toggle-hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.treeview-preloader {
  --f7-preloader-size: var(--f7-treeview-toggle-size);
  .ltr({
    margin-right: calc(-1 * var(--f7-treeview-toggle-size));
  });
  .rtl({
    margin-left: calc(-1 * var(--f7-treeview-toggle-size));
  });
}
.treeview-item-children {
  display: none;
}
.treeview-item-opened {
  > .treeview-item-children {
    display: block;
  }
  > .treeview-item-root .treeview-toggle:after {
    .ltr({
      transform: translate(-50%, -50%) rotate(90deg);
    });
    .rtl({
      transform: translate(-50%, -50%) rotate(-90deg);
    });
  }
}
a.treeview-item-root {
  color: var(--f7-treeview-label-text-color);
}
.treeview-item-selectable > .treeview-item-root,
.treeview-item-selectable.treeview-item-root {
  cursor: pointer;
  transition-duration: 150ms;
}
a.treeview-item-root {
  transition-duration: 150ms;
  &.active-state {
    background: var(--f7-treeview-link-pressed-bg-color);
  }
}
.treeview-item-toggle > .treeview-item-root,
.treeview-item-toggle.treeview-item-root {
  cursor: pointer;
}
.treeview-item-selected > .treeview-item-root,
.treeview-item-selected.treeview-item-root {
  background: var(--f7-treeview-selectable-selected-bg-color, rgba(var(--f7-theme-color-rgb), 0.2));
}
.generate-treeview-children(@n, @i: 1) when (@i <= @n) {
  .treeview-item {
    .treeview-item-root {
      .ltr({
        padding-left: calc(var(--f7-treeview-item-padding-left) + var(--f7-treeview-children-offset) * @i);
      });
      .rtl({
        padding-right: calc(var(--f7-treeview-item-padding-left) + var(--f7-treeview-children-offset) * @i);
      });
    }
    .generate-treeview-children(@n, (@i + 1));
  }
}
.treeview-item {
  .generate-treeview-children(10);
}

.if-ios-theme({
  @import './treeview-ios.less';
});
.if-md-theme({
  @import './treeview-md.less';
});
.if-aurora-theme({
  @import './treeview-aurora.less';
});
