/* === Popup === */
@import './popup-vars.less';

.popup-backdrop {
  z-index: 10500;
}
.popup {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  display: none;
  box-sizing: border-box;
  transition-property: transform;
  transform: translate3d(0, 100vh, 0);
  background: #fff;
  z-index: 11000;
  overflow: hidden;
  border-radius: var(--f7-popup-border-radius);
  .theme-dark &,
  &.theme-dark {
    background: #000;
  }
  &.modal-in,
  &.modal-out {
    transition-duration: var(--f7-popup-transition-duration);
  }
  &.not-animated {
    transition-duration: 0ms;
  }
  &.modal-in {
    display: block;
    transform: translate3d(0, 0, 0);
  }
  &.modal-out {
    transform: translate3d(0, 100vh, 0);
  }
}
.popup.swipe-close-to-top {
  &.modal-out {
    transform: translate3d(0, -100vh, 0);
  }
}
@media (min-width: 630px) and (min-height: 630px) {
  .popup:not(.popup-tablet-fullscreen) {
    --f7-safe-area-top: 0px;
    --f7-safe-area-bottom: 0px;
    width: var(--f7-popup-tablet-width);
    height: var(--f7-popup-tablet-height);
    left: 50%;
    top: 50%;
    margin-left: calc(-1 * var(--f7-popup-tablet-width) / 2);
    margin-top: calc(-1 * var(--f7-popup-tablet-height) / 2);
    transform: translate3d(0, 100vh, 0);
    box-shadow: var(--f7-popup-box-shadow);
    border-radius: var(--f7-popup-tablet-border-radius, var(--f7-popup-border-radius));
    &.modal-in {
      transform: translate3d(0, 0, 0);
    }
    &.modal-out {
      transform: translate3d(0, 100vh, 0);
    }
    &.swipe-close-to-top.modal-out {
      transform: translate3d(0, -100vh, 0);
    }
    &.popup-behind {
      transform: translate3d(0, 0, 0) scale(0.9);
      &.modal-out {
        transform: translate3d(0, 100vh, 0) scale(0.9);
      }
    }
  }
}
html.with-modal-popup {
  .framework7-root {
    > .views,
    > .view,
    > .panel {
      .page-content {
        .not-scrollable();
      }
    }
  }
}

html.with-modal-popup-push,
html.with-modal-popup-push-closing {
  .popup-push {
    top: calc(var(--f7-popup-push-offset) + 10px);
    height: calc(100% - var(--f7-popup-push-offset) - 10px);
    border-radius: var(--f7-popup-push-border-radius) var(--f7-popup-push-border-radius) 0 0;
    --f7-safe-area-top: 0px;
    .view,
    .page {
      --f7-safe-area-top: 0px;
    }
  }
  .framework7-root {
    background: #000;
    > .views,
    > .view {
      transition-duration: var(--f7-popup-transition-duration);
    }
  }

  .if-dark-theme({
    &.theme-dark .framework7-root > .views,
    &.theme-dark .framework7-root > .view,
    .theme-dark .framework7-root > .views,
    .theme-dark .framework7-root > .view,
    .theme-dark.framework7-root > .views,
    .theme-dark.framework7-root > .view,
    .framework7-root > .views.theme-dark,
    .framework7-root > .view.theme-dark {
      &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.1);
        z-index: 13000;
        border-radius: var(--f7-popup-push-border-radius) var(--f7-popup-push-border-radius) 0 0;
        opacity: 1;
        animation: popup-dark-push-overlay-fade-in var(--f7-popup-transition-duration) forwards;
      }
    }
  });
}

html.with-modal-popup-push {
  .framework7-root {
    > .views,
    > .view {
      border-radius: var(--f7-popup-push-border-radius) var(--f7-popup-push-border-radius) 0 0;
      transform: translate3d(0, 0, 0px) scale(var(--f7-popup-push-scale, 1)) !important;
    }
  }

  .popup:after {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 13000;
    transition-duration: var(--f7-popup-transition-duration);
    border-radius: var(--f7-popup-push-border-radius) var(--f7-popup-push-border-radius) 0 0;
    opacity: 0;
  }
  .if-dark-theme({
    &.theme-dark .popup:after,
    .theme-dark .popup:after,
    .theme-dark.popup:after {
      background: rgba(255,255,255,0.05);
    }
  });

  .popup-behind {
    transform: translate3d(0, 0px, 0px) scale(var(--f7-popup-push-scale, 1));
    &.modal-out {
      transform: translate3d(0, 100vh, 0) scale(var(--f7-popup-push-scale, 1));
    }
    &:not(.popup-push) {
      border-radius: var(--f7-popup-push-border-radius) var(--f7-popup-push-border-radius) 0 0;
    }
    &.popup-push {
      transform: translate3d(0, calc(0px - var(--f7-popup-push-offset) - 10px), 0px)
        scale(var(--f7-popup-push-scale, 1));
      &.modal-out {
        transform: translate3d(0, calc(0px - var(--f7-popup-push-offset) - 10px + 100vh), 0px)
          scale(var(--f7-popup-push-scale, 1));
      }
    }
    &:after {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.if-dark-theme({
  html.with-modal-popup-push-closing {
    &.theme-dark .framework7-root > .views,
    &.theme-dark .framework7-root > .view,
    .theme-dark .framework7-root > .views,
    .theme-dark .framework7-root > .view,
    .theme-dark.framework7-root > .views,
    .theme-dark.framework7-root > .view,
    .framework7-root > .views.theme-dark,
    .framework7-root > .view.theme-dark {
      &:after {
        animation: popup-dark-push-overlay-fade-out var(--f7-popup-transition-duration) forwards;
      }
    }
  }

  @keyframes popup-dark-push-overlay-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes popup-dark-push-overlay-fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
});

.if-ios-theme({
  @import './popup-ios.less';
});
.if-md-theme({
  @import './popup-md.less';
});
.if-aurora-theme({
  @import './popup-aurora.less';
});
