:root {
  .dark-vars({
    --f7-contacts-list-title-text-color: #fff;
  });
}
.ios-vars({
  --f7-contacts-list-title-font-size: inherit;
  --f7-contacts-list-title-font-weight: 600;
  --f7-contacts-list-title-height: 22px;
  .light-vars({
    --f7-contacts-list-title-text-color: #000;
    --f7-contacts-list-title-bg-color: #f7f7f7;
  });
  .dark-vars({
    --f7-contacts-list-title-bg-color: #323234;
  });
});
.md-vars({
  --f7-contacts-list-title-font-size: 20px;
  --f7-contacts-list-title-font-weight: 500;
  /*
  --f7-contacts-list-title-text-color: var(--f7-theme-color);
  */
  --f7-contacts-list-title-height: 48px;
  --f7-contacts-list-title-bg-color: transparent;
});
.aurora-vars({
  --f7-contacts-list-title-font-weight: 600;
  --f7-contacts-list-title-text-color: #000;
  /*
  --f7-contacts-list-title-bg-color: var(--f7-list-group-title-bg-color);
  --f7-contacts-list-title-font-size: var(--f7-list-group-title-font-size);
  --f7-contacts-list-title-line-height: var(--f7-list-group-title-height);
  --f7-contacts-list-title-height: var(--f7-list-group-title-height);
  */
});
