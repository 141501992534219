/* === Elevation === */
@import 'elevation-vars.less';

@elevations: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  24;

each(@elevations, {
  .elevation-@{value} {
    box-shadow: ~"var(--f7-elevation-@{value}) !important";
  }
});
each(@elevations, {
  .device-desktop .elevation-hover-@{value}:hover {
    box-shadow: ~"var(--f7-elevation-@{value}) !important";
  }
});
each(@elevations, {
  .active-state.elevation-pressed-@{value},
  .device-desktop .active-state.elevation-pressed-@{value} {
    box-shadow: ~"var(--f7-elevation-@{value}) !important";
  }
});

.elevation-transition-100 {
  transition-duration: 100ms;
  transition-property: box-shadow;
}
.elevation-transition,
.elevation-transition-200 {
  transition-duration: 200ms;
  transition-property: box-shadow;
}
.elevation-transition-300 {
  transition-duration: 300ms;
  transition-property: box-shadow;
}
.elevation-transition-400 {
  transition-duration: 400ms;
  transition-property: box-shadow;
}
.elevation-transition-500 {
  transition-duration: 500ms;
  transition-property: box-shadow;
}

.if-ios-theme({
  @import './elevation-ios.less';
});
.if-md-theme({
  @import './elevation-md.less';
});
.if-aurora-theme({
  @import './elevation-aurora.less';
});
